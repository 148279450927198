import { IBrand } from '../../utils/brands';

const ACTIVATE_PIANO_CONSENT: boolean = true;

interface ConsentStatus {
  optin: string;
  optout: string;
  essential: string;
}
/**
 * @description Piano consent init
 */
export const pianoConsentInit = (currentBrand: IBrand) => {
  if (currentBrand.didomi !== undefined && currentBrand.pianoConsent) {
    window.pdl = window.pdl || {};
    window.pdl.requireConsent = 'v2';
  }
};

/**
 * @description Piano consent status
 */
const PIANO_CONSENT_STATUS: ConsentStatus = {
  optin: 'opt-in',
  optout: 'opt-out',
  essential: 'essential',
};

interface PurposeCategory {
  piano: string;
  name: string;
  didomi: { name: string; id: string };
}

/**
 * @description Purposes categories
 */
const purposesCategories: PurposeCategory[] = [
  {
    piano: 'AD',
    name: 'Audience Advertising',
    didomi: { name: 'Cookies publicitaires', id: 'eDQqdfBF' },
  },
  {
    piano: 'AM',
    name: 'Audience measurement',
    didomi: { name: "Cookies d'analyse", id: '3CAfU7er' },
  },
  {
    piano: 'CP',
    name: 'Content personalization and Performance',
    didomi: { name: 'Cookies de performance', id: 'FFEGkpnU' },
  },
];

interface Didomi {
  getCategories: () => any[];
  getUserConsentStatusForPurpose: (purposeId: string) => boolean;
}

interface Tp {
  consent: {
    setByPurpose: (category: string, status: string) => void;
  };
}

/**
 * @description Set piano consent
 */
const setPianoConsent = ({ Didomi, tp }: { Didomi: Didomi; tp: Tp }): void => {
  if (!ACTIVATE_PIANO_CONSENT) return;

  // eslint-disable-next-line consistent-return
  return purposesCategories.forEach((category: PurposeCategory) => {
    const purposes = Didomi.getCategories().filter(
      (e) => e?.id === category.didomi.id
    )?.[0].children;
    const consentList =
      purposes?.map(({ purposeId }: any) =>
        Didomi?.getUserConsentStatusForPurpose(purposeId)
      ) || [];
    // TODO: establish expected behavior
    if (consentList.every((e: any) => e)) {
      tp.consent.setByPurpose(category.piano, PIANO_CONSENT_STATUS.optin);
    } else {
      tp.consent.setByPurpose(category.piano, PIANO_CONSENT_STATUS.optout);
    }
  });
};

export default setPianoConsent;
