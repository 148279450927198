import React, { useEffect, useState } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import 'react-datepicker/dist/react-datepicker.css';
import frDate from 'date-fns/locale/fr';
import frPhone from 'react-phone-number-input/locale/fr.json';
import DatePicker from 'react-datepicker';
import AutoComplete from '../AutoComplete';
import {
  getAddressCountriesSuggestionList,
  getAddressFullSuggestionList,
  IAddressSuggestion,
} from '../../actions/views/delivery';
import CombinedRefTextField from './PhoneNumber';
import Checkbox from '../Checkbox';

import './style.css';
import Row, { RowHalf } from '../Row/Row';
import InputError from '../InputError';
import handleKeyboardEvent from '../../utils/handleKeyboardEvent';
import { inSevenDays, inThreeMonths } from '../../utils/dates';
import { validatePhoneNumber } from '../../utils/validatePhoneNumber';
import { VAT_CHECK } from '../BillingForm/BillingForm';

export const LIBRARY_REGEX = /^(.*)\s-\s(.*)\s-\s(.*)$/;
export const SEARCH_REGEX = /^(\d{1,4})\s-\s(.*)$/;
export const InternationalNameREGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;
export const VAR_10 = /^.{0,10}$/;
export const VAR_50 = /^.{0,50}$/;
export const PHONE_REGEX =
  /^(((\+|00|.*?)([0-9]{2}|[0-9]{3})[ ]?(?:\(0\)[ ]?)?)|0){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/;

interface IAddressFormProps {
  register: any;
  control: any;
  errors: any;
  setValue: Function;
  formIn: string;
  includeBilling?: boolean;
  includeStartDateDelayed?: boolean;
  onlyBelgium?: boolean;
  toggleBill?: CallableFunction;
  color?: string;
}

const Address = ({
  register,
  control,
  setValue,
  errors,
  formIn,
  toggleBill,
  includeBilling = true,
  includeStartDateDelayed = false,
  onlyBelgium = false,
  color = '#000000',
}: IAddressFormProps) => {
  const [countryCode, setCountryCode] = useState<string>('BE');
  const [secAddress, setSecAddress] = useState<number>(1);
  const isBelgium = (): boolean => countryCode === 'BE';

  const [withBill, setWithBill] = useState<boolean>(false);
  const [isStartDateDelayed, setIsStartDateDelayed] = useState<boolean>(false);
  const [dateDelayed, setDateDelayed] = useState<Date>(inSevenDays);
  const [dateSelected, setDateSelected] = useState<Date | null>(null);

  useEffect(() => {
    if (isStartDateDelayed && dateSelected !== null) {
      setValue(`${formIn}.deliveryStartDate`, dateSelected.toISOString());
    }
  }, [dateSelected, formIn, isStartDateDelayed, setValue]);

  const getCountriesSuggestions = async (value: string) =>
    getAddressCountriesSuggestionList(value);

  const getAddressSuggestions = async (
    value: string
  ): Promise<IAddressSuggestion[]> => {
    const suggestions = await getAddressFullSuggestionList(
      value,
      secAddress,
      countryCode
    );

    setSecAddress(secAddress + 1);

    return suggestions;
  };

  return (
    <div className="form_container">
      <section className="field">
        <label htmlFor="firstname" className="label label-required">
          Prénom
        </label>
        <input
          id="firstname"
          className="input"
          name={`${formIn}.firstname`}
          aria-required="true"
          aria-label="Prénom"
          aria-labelledby="firstname"
          type="text"
          ref={register({
            required: 'Prénom requis',
            pattern: {
              value: InternationalNameREGEX,
              message: 'Erreur dans le Prénom',
            },
          })}
        />
        <InputError message={errors?.[formIn]?.firstname?.message} />
      </section>

      <section className="field">
        <label htmlFor="lastname" className="label label-required">
          Nom
        </label>
        <input
          id="lastname"
          name={`${formIn}.lastname`}
          aria-required="true"
          aria-label="Nom"
          aria-labelledby="lastname"
          className="input"
          type="text"
          ref={register({
            required: 'Nom requis',
            pattern: {
              value: InternationalNameREGEX,
              message: 'Erreur dans le nom',
            },
          })}
        />
        <InputError message={errors?.[formIn]?.lastname?.message} />
      </section>

      <section className="field">
        <label htmlFor="phoneNumber" className="label label-required">
          Téléphone / GSM
        </label>
        <Controller
          name={`${formIn}.phoneNumber`}
          rules={{
            validate: (val: string) => validatePhoneNumber(val),
          }}
          defaultValue="443"
          as={
            <PhoneInput
              international
              error={!!errors?.[formIn]?.phoneNumber}
              helperText={errors?.[formIn]?.phoneNumber?.message}
              countryCallingCodeEditable
              defaultCountry="BE"
              onChange={(val: any) => val}
              labels={frPhone}
              inputComponent={CombinedRefTextField}
            />
          }
          control={control}
        />
        <p className="mt-2">Pour tout contact concernant votre commande.</p>
      </section>

      <input
        type="hidden"
        name={`${formIn}.address.country.name`}
        ref={register}
      />
      <input
        type="hidden"
        name={`${formIn}.address.country.code`}
        ref={register({ required: 'Pays requis' })}
      />
      <AutoComplete
        getSuggestions={getCountriesSuggestions}
        setValue={(data: any) => {
          setValue(`${formIn}.address.country.name`, data.name);
          setValue(`${formIn}.address.country.code`, data.alpha2);
          setCountryCode(data.alpha2);
          return data;
        }}
        filterValue={(data: any, value: string) => data.name === value}
        aria-required="true"
        name={`${formIn}.country`}
        label="Pays *"
        placeholder="Entrez votre pays"
        displayResults={(data: any) => data.name}
        className="w-full"
        currentValue="Belgique"
        readonly={onlyBelgium}
        forceClickSuggestion
      />
      <InputError message={errors?.[formIn]?.address?.country?.code?.message} />

      {isBelgium() ? (
        <>
          <section className="field">
            <input
              type="hidden"
              name={`${formIn}.address.street`}
              ref={register({
                required: 'Rue requise',
              })}
            />
            <input
              type="hidden"
              name={`${formIn}.address.houseNumber`}
              ref={register({
                required: 'Numéro requis',
              })}
            />
            <input
              type="hidden"
              name={`${formIn}.address.city`}
              ref={register({
                required: 'Entrez votre adresse et sélectionnez la',
              })}
            />
            <input
              type="hidden"
              name={`${formIn}.address.postalCode`}
              ref={register({
                required: 'Code postal requis',
              })}
            />

            <AutoComplete
              getSuggestions={getAddressSuggestions}
              setValue={(address: IAddressSuggestion) => {
                const {
                  municipalityName: city,
                  postalCode,
                  streetName,
                  houseNumber,
                } = address;

                setValue(`${formIn}.address.street`, streetName || undefined);
                setValue(
                  `${formIn}.address.houseNumber`,
                  houseNumber || undefined
                );
                setValue(
                  `${formIn}.address.postalCode`,
                  postalCode || undefined
                );
                setValue(`${formIn}.address.city`, city || undefined);

                return '';
              }}
              aria-required="true"
              name={`${formIn}.street`}
              label="Adresse complète *"
              placeholder="Entrez votre adresse"
              displayResults={(address: IAddressSuggestion) =>
                address.searchBarString
              }
              className="w-full"
            />
            <InputError message={errors?.[formIn]?.address?.street?.message} />
            <InputError
              message={errors?.[formIn]?.address?.houseNumber?.message}
            />
            <InputError message={errors?.[formIn]?.address?.city?.message} />
            <InputError
              message={errors?.[formIn]?.address?.postalCode?.message}
            />
          </section>
          <Row>
            <section className="field w-full">
              <label htmlFor="postalBox" className="label">
                Boîte
              </label>
              <input
                id="postalBox"
                name={`${formIn}.address.postalBox`}
                aria-required="true"
                aria-label="Boîte"
                aria-labelledby="postalBox"
                className="input"
                type="text"
                ref={register({
                  maxLength: {
                    value: 128,
                    message: 'Numéro de boite trop long',
                  },
                })}
              />
              <InputError
                message={errors?.[formIn]?.address?.postalBox?.message}
              />
            </section>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <RowHalf>
              <section className="field">
                <label htmlFor="postcode" className="label label-required">
                  Code postal
                </label>
                <input
                  id="postcode"
                  name={`${formIn}.address.postalCode`}
                  aria-required="true"
                  aria-label="Code postal"
                  aria-labelledby="postcode"
                  className="input"
                  type="number"
                  ref={register({
                    required: 'Code postal requis',
                  })}
                />
                <InputError
                  message={errors?.[formIn]?.address?.postalCode?.message}
                />
              </section>
            </RowHalf>
            <RowHalf>
              <section className="field">
                <label htmlFor="city" className="label label-required">
                  Ville
                </label>
                <input
                  id="city"
                  name={`${formIn}.address.city`}
                  aria-required="true"
                  aria-label="Ville"
                  aria-labelledby="city"
                  className="input"
                  type="text"
                  ref={register({
                    required: 'Ville requise',
                  })}
                />
                <InputError
                  message={errors?.[formIn]?.address?.city?.message}
                />
              </section>
            </RowHalf>
          </Row>

          <section className="field w-full">
            <label htmlFor="street" className="label label-required">
              Rue
            </label>
            <input
              id="street"
              name={`${formIn}.address.street`}
              aria-required="true"
              aria-label="Rue"
              aria-labelledby="street"
              className="input"
              type="text"
              ref={register({
                required: 'Rue requise',
              })}
            />
            <InputError message={errors?.[formIn]?.address?.street?.message} />
          </section>

          <Row>
            <RowHalf>
              <section className="field">
                <label htmlFor="street_number" className="label label-required">
                  Numéro
                </label>
                <input
                  id="street_number"
                  name={`${formIn}.street.houseNumber`}
                  aria-required="true"
                  aria-label="Numéro"
                  aria-labelledby="street_number"
                  className="input"
                  type="text"
                  ref={register({
                    required: 'Numéro requis',
                  })}
                />
                <InputError
                  message={errors?.[formIn]?.street?.houseNumber?.message}
                />
              </section>
            </RowHalf>
            <RowHalf>
              <section className="field">
                <label htmlFor="street_number_box" className="label">
                  Boîte
                </label>
                <input
                  id="street_number_box"
                  name={`${formIn}.street.postalBox`}
                  aria-required="false"
                  aria-label="Boîte"
                  aria-labelledby="street_number_box"
                  className="input"
                  type="text"
                  ref={register({
                    maxLength: {
                      value: 128,
                      message: 'Numéro de boite trop long',
                    },
                  })}
                />
                <InputError
                  message={errors?.[formIn]?.street?.postalBox?.message}
                />
              </section>
            </RowHalf>
          </Row>
        </>
      )}

      {includeStartDateDelayed && (
        <div className="TextField_Container">
          <FormControlLabel
            className="mx-auto"
            control={
              <Checkbox
                color={color}
                checked={isStartDateDelayed}
                value="conditions"
                onKeyDown={(e: KeyboardEvent) =>
                  handleKeyboardEvent(e, () => {
                    setDateSelected(dateDelayed);
                    setIsStartDateDelayed(!isStartDateDelayed);
                  })
                }
                onChange={() => {
                  setDateSelected(dateDelayed);
                  setIsStartDateDelayed(!isStartDateDelayed);
                }}
              />
            }
            label="Je souhaite démarrer mon abonnement plus tard"
          />
        </div>
      )}

      {isStartDateDelayed && (
        <>
          <input
            type="hidden"
            name={`${formIn}.deliveryStartDate`}
            ref={register}
          />
          <DatePicker
            locale={frDate}
            selected={dateDelayed}
            minDate={inSevenDays()}
            maxDate={inThreeMonths()}
            onChange={(date) => {
              if (date) {
                setDateSelected(date);
                setDateDelayed(date);
              }
            }}
            dateFormat="d MMMM yyyy"
            className="input datePicker"
          />
        </>
      )}

      {includeBilling ? (
        <div className="TextField_Container">
          <FormControlLabel
            className="mx-auto"
            control={
              <Checkbox
                color={color}
                checked={withBill}
                value="conditions"
                onKeyDown={(e: KeyboardEvent) =>
                  handleKeyboardEvent(e, () => setWithBill(!withBill))
                }
                onChange={() => {
                  setWithBill(!withBill);
                  if (toggleBill) {
                    toggleBill();
                  }
                }}
              />
            }
            label="Je souhaite une facture"
          />
        </div>
      ) : null}

      {withBill && (
        <>
          <section className="field">
            <label htmlFor="companyName" className="label label-required">
              Nom d&apos;entreprise
            </label>
            <input
              id="companyName"
              name={`${formIn}.billing.companyName`}
              aria-required="true"
              aria-label="Nom d'entreprise"
              aria-labelledby="companyName"
              className="input"
              type="text"
              ref={register({
                required: "Nom d'enteprise requis",
                pattern: {
                  value: VAR_50,
                  message: 'Nom entrerise invalide',
                },
              })}
            />
            <InputError
              message={errors?.[formIn]?.billing?.companyName?.message}
            />
          </section>

          <section className="field">
            <label htmlFor="vat" className="label">
              Numéro de TVA
            </label>
            <input
              id="vat"
              name={`${formIn}.billing.vat`}
              aria-required="true"
              aria-label="Numéro de TVA"
              aria-labelledby="vat"
              className="input"
              type="text"
              ref={register({
                pattern: {
                  value: VAT_CHECK,
                  message: 'Numéro TVA invalide',
                },
              })}
            />
            <InputError message={errors?.[formIn]?.billing?.vat?.message} />
          </section>
        </>
      )}
    </div>
  );
};

export default Address;
