export const formatPrice = (price: number = 0) => {
  const priceAsString = (Math.round(price * 100) / 100)
    .toFixed(2)
    .toString()
    .replace('.', ',');

  return priceAsString.endsWith(',00')
    ? priceAsString.split(',')[0]
    : priceAsString;
};

export const pricePerWeek = (price: number = 0, duration: number = 0) => {
  const priceAsString = (Math.round((price / duration) * 12) / 52)
    .toFixed(2)
    .toString()
    .replace('.', ',');

  return priceAsString.endsWith(',00')
    ? priceAsString.split(',')[0]
    : priceAsString;
};

export default {
  pricePerWeek,
  formatPrice,
};
