import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Paper } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link } from 'react-router-dom';
import { GeneralState } from '../../reducers/entities/general';
import { getAdvertisings } from '../../actions/views/home';
import './temporaryOffers.css';
import { HOME_PAGE } from '../../utils/routes';
import LoadingScreen from '../../components/LoadingScreen';
import { flyingFishEvents, trackEvent } from '../../utils/FlyingFish';

interface ITemporaryOffersProps {
  general: GeneralState;
}

const TemporaryOffers = ({ general }: ITemporaryOffersProps) => {
  const dispatch = useDispatch();

  const [marketingLinks, setMarketingList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchMarketingLinks = async () => {
    const links = await dispatch(getAdvertisings(general.appState.brand));
    setMarketingList(links);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchMarketingLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const track = (title: string) => {
    trackEvent(flyingFishEvents.temporary_offer_select(title));
  };

  if (isLoading) {
    return (
      <LoadingScreen
        customColor={general.appState.brand.thirdColor}
        size={60}
      />
    );
  }

  return (
    <div className="step_formule_wrapper temporaryOffersPage">
      <main role="list" className="temporaryOffers">
        {marketingLinks.map((mkLinks: any) => (
          <Paper
            elevation={5}
            role="listitem"
            className={`${
              marketingLinks.length === 1 ? 'temporaryOffers-item--alone' : ''
            } temporaryOffers-item`}
            onClick={() => {
              // todo Alysson
            }}
            // onKeyDown={(e:any) => this.onKeyDown(offer.id, offer.name, e)}
            key={`marketing-link-${mkLinks.url}`}
          >
            <div
              className="temporaryOffers-cover"
              style={{
                backgroundImage: `url(${mkLinks.pictureUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <div className="temporaryLinks-content">
              <h3>{mkLinks.title}</h3>
              <p>{mkLinks.validity}</p>
            </div>
            <a
              href={mkLinks.url}
              className="temporaryLinks-button"
              target="_blank"
              rel="noreferrer"
              onClick={() => track(mkLinks.title)}
            >
              <span>{mkLinks.buttonText}</span>
              <ArrowForwardIcon color="inherit" />
            </a>
          </Paper>
        ))}
      </main>

      <section className="flex mx-auto justify-center">
        <Link className="temporaryOffers-backLink" to={HOME_PAGE}>
          Découvrez toutes nos offres
        </Link>
      </section>
    </div>
  );
};

export default TemporaryOffers;
