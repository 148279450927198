export const urlParam = (name: string) => {
  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);
  if (results == null) {
    return null;
  }
  return decodeURI(results[1]) || null;
};

export const getParamInsensitive = (paramName: string): string | null => {
  const params = new URLSearchParams(window.location.search);

  const keyName = Array.from(params.keys()).find(
    (key) => key.toLowerCase() === paramName.toLowerCase()
  );

  return keyName ? params.get(keyName) : null;
};

export const hexToAscii = (name: string | null): string => {
  if (name === null) return '';
  const hex = name.toString();
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
};

export const getUrlParamsFromLs = () => {
  let queryStrings = '';
  const utms = localStorage.getItem('utm');
  if (utms !== null) {
    try {
      const parsedUtms = JSON.parse(utms);
      queryStrings += `?`;
      Object.keys(parsedUtms).forEach((key) => {
        // key: the name of the object key
        // index: the ordinal position of the key within the object
        if (parsedUtms[key] !== '' && parsedUtms[key] !== null) {
          queryStrings += `${key}=${parsedUtms[key]}&`;
        }
      });
    } catch (error) {
      // continue
    }
  }
  return queryStrings;
};
