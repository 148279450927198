import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Base64 from 'crypto-js/enc-base64';
import WordArray from 'crypto-js/lib-typedarrays';
import { AES } from 'crypto-js';
import * as CryptoJS from 'crypto-js';
import { DialogContent } from '@material-ui/core';
import {
  DeliveryOptions,
  GeneralState,
  ProductType,
} from '../../reducers/entities/general';
import browserHistory from '../../utils/browser_history';
import { CONTACT_DATA_PAGE, SHORT_FORM_PAGE } from '../../utils/routes';
import { UserState } from '../../reducers/entities/user';
import { AddUserType } from '../../actions/userActions';
import './Renewal.css';
import { GeneralTypes, UserTypes } from '../../actions/types';
import AuthPiano from '../AuthPiano';
import { hexToAscii, urlParam } from '../../utils/url';
import { CreateOrderType, UpdateOrderType } from '../../actions/views/renewal';
import { RenewalOrderType } from '../../actions/views/pay';

interface ILoginProps {
  general: GeneralState;
  user: UserState;
  actions: {
    createOrder: CreateOrderType;
    addUser: AddUserType;
    updateOrder: UpdateOrderType;
  };
}

const RenewalNew = ({ general, actions }: ILoginProps) => {
  const dispatch = useDispatch();
  const { promocode, editionID, brandName, pianoInitialized } =
    general.appState;
  const { createOrder, addUser } = actions;
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  const [logginToken, setLogginToken] = useState<string>('');
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptions[]>([]);
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<Partial<ProductType>>(
    {}
  );

  const tibcoKey = process.env.REACT_APP_TIBCO_KEY ?? '';

  const byteArrayToWordArray = (ba: Uint8Array) => {
    const wa: number[] = [];
    let i: number = 0;
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < ba.length; i++) {
      // eslint-disable-next-line no-bitwise
      wa[(i / 4) | 0] |= ba[i] << (24 - 8 * i);
    }

    return WordArray.create(wa, ba.length);
  };

  const decrypt = (encryptedKey: string, key: string) => {
    if (!encryptedKey) return '';
    if (!base64regex.test(encryptedKey)) return encryptedKey;
    const rawData = Uint8Array.from(atob(encryptedKey), (c) => c.charCodeAt(0));
    const encryptedCP = CryptoJS.lib.CipherParams.create({
      ciphertext: byteArrayToWordArray(rawData.slice(16, rawData.length)),
      formatter: CryptoJS.format.OpenSSL,
    });
    const decryptData = AES.decrypt(encryptedCP, Base64.parse(key), {
      iv: byteArrayToWordArray(rawData.slice(0, 16)),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding,
      keySize: 256 / 32,
      BlockSize: 128,
    });
    if (decryptData.words.length === 0) {
      return encryptedKey;
    }
    return decryptData.toString(CryptoJS.enc.Utf8);
  };

  const numAbo = hexToAscii(urlParam('numAbo'));
  const price = decrypt(hexToAscii(urlParam('p')) ?? null, tibcoKey);
  const nameOffer = hexToAscii(urlParam('nameOffer')).replace(
    /[^a-z0-9 ,.?!-'`|]/gi,
    ''
  );
  const codeOffer = decrypt(hexToAscii(urlParam('codeOffer')) ?? '', tibcoKey);
  const numClient = hexToAscii(urlParam('numClient'));
  const ip = decrypt(hexToAscii(urlParam('ip')), tibcoKey);
  const dm = decrypt(hexToAscii(urlParam('dm')), tibcoKey);
  // const lastName = hexToAscii(urlParam('lastName'));
  // const firstName = hexToAscii(urlParam('firstName'));
  const ref = hexToAscii(urlParam('ref'));
  const email = hexToAscii(urlParam('email'));
  const paymentMethodsArray = decrypt(hexToAscii(urlParam('m')) ?? '', tibcoKey)
    .toString()
    .split(',')
    .map((t: string) => Number(t));
  const includePaper =
    ip !== '' || ip == null
      ? /true/i.test(ip)
      : !(
          nameOffer.toLowerCase().includes('digital') ||
          nameOffer.toLowerCase().includes('web')
        );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deliveryMethodsArray =
    dm !== ''
      ? dm
          .toString()
          .split(',')
          .map((t: string) => Number(t))
      : [];

  const previousSubscription: string | null =
    hexToAscii(urlParam('ps')) ?? null;

  const duration = Number(
    nameOffer.match(/\d+/) === null ? 0 : nameOffer.match(/\d+/)
  );

  const priceValue = price.includes(',')
    ? parseFloat(price.replace(/\./g, '').replace(',', '.'))
    : parseFloat(price);

  // console.log(
  //   'parmaters',
  //   numAbo,
  //   price,
  //   nameOffer,
  //   codeOffer,
  //   numClient,
  //   ref,
  //   email,
  //   paymentMethodsArray,
  //   includePaper,
  //   duration,
  //   deliveryMethodsArray
  // );

  const redirectToForm = (id: string | undefined) => {
    if (logginToken === '' && id === undefined) {
      return;
    }
    if (includePaper) {
      // Includes a gift or some physical paper
      browserHistory.push(CONTACT_DATA_PAGE);
    } else {
      browserHistory.push(SHORT_FORM_PAGE);
    }
  };

  function isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (isEmpty(deliveryOptions)) {
      setDeliveryOptions([
        {
          id: 1,
          name: 'Livraison à domicile',
          description: 'Recevez votre journal dans votre boîte aux lettres.',
        },
        {
          id: 2,
          name: 'Retrait en point de vente',
          description:
            'Retirez votre journal chez votre libraire ou dans un point de vente partenaire.',
        },
        {
          id: 3,
          name: 'Chèques-libraires',
          description:
            'Recevez votre journal sous forme de chèques à échanger dans le(s) point(s) de vente partenaire(s) de votre choix.',
        },
      ]);
    }
  }, [deliveryOptions]);

  useEffect(() => {
    if (isEmpty(currentProduct) && !isEmpty(deliveryOptions)) {
      const data: ProductType = {
        channelDto: {
          id: 0,
          name: includePaper ? 'INTEGRAL' : 'DIGITAL',
          infos: [],
          includePaper,
          hightlight: false,
        },
        deliveryOptionsDto:
          deliveryMethodsArray.length > 0
            ? deliveryOptions.filter((item: DeliveryOptions) =>
                deliveryMethodsArray.includes(item.id)
              )
            : deliveryOptions.filter((x) => x.id === 1 || x.id === 2),
        duration,
        durationName: '',
        editionDto: [],
        pricePerWeek: false,
        id: 0,
        editionID: 0,
        name: decodeURIComponent(escape(nameOffer)),
        online: false,
        price: priceValue,
        saturdayPaper: false,
        tempAccess: '',
        paymentMethodsDto: paymentMethodsArray,
        typeDto: {
          id: 0,
          name: 'Renewal',
        },
        productInfos: decodeURIComponent(escape(nameOffer)),
        thanksYouMessage: '',
        conditions: '',
        gifts: '',
      };
      setCurrentProduct(data);
    }
  }, [
    currentProduct,
    deliveryMethodsArray,
    deliveryOptions,
    dispatch,
    duration,
    includePaper,
    nameOffer,
    paymentMethodsArray,
    price,
    priceValue,
  ]);

  useEffect(() => {
    if (!isEmpty(currentProduct)) {
      dispatch({ type: GeneralTypes.SET_RENEWAL, data: currentProduct });
    }
  }, [currentProduct, dispatch]);

  // const managingProduct = async () => {
  //   if (!isEmpty(currentProduct)) {
  //   }
  // };

  const managingOrder = async () => {
    if (creatingOrder) {
      return;
    }
    setCreatingOrder(true);
    const order: RenewalOrderType = {
      promoCode: promocode,
      ...(editionID && { editionId: editionID }),
      price: priceValue,
      productId: null,
      gift: null,
      ref,
      externalSubscriptionId: numAbo,
      externalProductId: codeOffer,
      externalProductName: decodeURIComponent(escape(nameOffer)),
      externalPreviousSubscriptionId:
        previousSubscription !== '' ? previousSubscription : null,
      externalClientId: numClient,
      duration,
      brand: brandName,
    };
    // if (intent) {
    //   await updateOrder({
    //     id: Guid.parse(intent).toString(),
    //     ...order,
    //   });
    //   await redirectToForm(intent);
    // } else if (productID) {
    //   const id = await createOrder({
    //     ...order,
    //   });
    //   if (id) {
    //     await redirectToForm(id);
    //   }
    // } else {
    if (price === null || email === null || nameOffer === null) {
      return;
    }
    const id = await createOrder(order);
    if (id) {
      await redirectToForm(id);
    }
    // }
  };

  const managingUser = async (e: any) => {
    await addUser(
      e.user.email,
      e.user.firstName,
      e.user.lastName,
      e.user.valid
    );
  };

  const handleLogin = async (e: any) => {
    if (logginToken !== e.token) {
      setLogginToken(e.token);
      await dispatch({
        type: UserTypes.LOGIN_SUCCESS,
        data: e,
      });
      localStorage.setItem('token', e.token);
      // await managingProduct();
      await managingUser(e);
      await managingOrder();
    }
  };

  return (
    // <DialogContent className="auth_content" style={{ minWidth: '400px' }}>
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {pianoInitialized && (
        <DialogContent className="auth_content" style={{ minWidth: '400px' }}>
          <AuthPiano
            handleloggedIn={handleLogin}
            authType="login"
            asModal={false}
          />
        </DialogContent>
      )}
    </>
    // </DialogContent>
  );
};

export default RenewalNew;
