import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import fetch from 'isomorphic-fetch';
import { GeneralTypes } from '../types';
import { AppAction } from '../../reducers/entities/general';
import { log, getAPIUrl } from '../generalActions';
import {
  flyingFishEvents,
  IDataLayerCommerceItem,
  trackEvent,
} from '../../utils/FlyingFish';
import { getFlowtype, getOffer } from '../../hooks/usePageViews';

export type SelectDurationType = (
  duration: number,
  name: string,
  price: string,
  track?: boolean
) => void;
export const selectDuration: SelectDurationType =
  (duration, name, price, track = true) =>
  (dispatch: any, getState: any) => {
    localStorage.setItem('duration', duration.toString());
    localStorage.setItem('duratioName', name);
    const { appActions, appState } = getState().entities.general;
    const { uuid } = getState().entities.user.currentUser;
    if (appActions !== null && uuid !== '')
      dispatch(
        log({
          actionDto: appActions.find(
            (a: AppAction) => a.definition === 'SelectDuration'
          ),
          uuid,
          result: name,
        })
      );
    dispatch({
      type: GeneralTypes.SELECT_DURATION,
      data: { duration, durationName: name },
    });
    if (track) {
      trackEvent(flyingFishEvents.duration_select(name));
      trackEvent(
        flyingFishEvents.add_to_cart({
          item_name: getOffer(appState.currentProduct),
          item_category: getFlowtype(appState.currentProduct, window.location),
          item_category1: duration.toString(),
          coupon: appState?.promocode || '',
          quantity: '1',
          price,
        } as IDataLayerCommerceItem)
      );
    }
  };

export type SelectProductType = (productId: number) => void;
export const selectProduct: SelectProductType =
  (productId) => (dispatch: any) => {
    localStorage.setItem('productID', productId.toString());
    dispatch({ type: GeneralTypes.SELECT_PRODUCT, data: productId });
  };

//
export type SetCartType = (duration: any) => void;
export const SetCart: SetCartType =
  (duration: any) => async (dispatch: any) => {
    // const resp = await fetch(`${getAPIUrl()}/Product/${productId}`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-type': 'application/json; charset=UTF-8',
    //   },
    // });
    // const data = await resp.json();
    // if (!resp.ok) throw new Error(data.message);
    dispatch({ type: GeneralTypes.SET_PRODUCT_DURATION, data: duration });
    return duration;
  };

// fetch durations call
export type GetDurationsType = (
  campaign: string | null,
  brandId: number
) => any;
export const getDurations: GetDurationsType =
  (campaign, brandId) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any) => {
    try {
      const isCampaign =
        campaign === null || campaign === ''
          ? getState().entities.general.appState.type
          : 2;
      const resp = await fetch(
        `${getAPIUrl()}/Product/${
          getState().entities.general.appState.offer
        }/${isCampaign}/true/durations?campaign=${campaign}&brandId=${brandId}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };
