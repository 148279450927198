import fetch from 'isomorphic-fetch';
import jwt_decode from 'jwt-decode';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  ABORENEWAL_PAGE,
  FREE_CAMPAIGN_PAGE,
  HOME_PAGE,
  PROMOTION_PAGE,
  TEMPORARY_OFFERS_PAGE,
  VOUCHER_PAGE,
} from '../utils/routes';
import { RootState } from '../reducers';
import { AppAction } from '../reducers/entities/general';
import { CurrentUserState } from '../reducers/entities/user';
import browserHistory from '../utils/browser_history';
import {
  fetchWithCredentials,
  getAppActions,
  log,
  getAPIUrl,
} from './generalActions';
import { GeneralTypes, UserTypes } from './types';
import { urlParam } from '../utils/url';
import { getOrderInfo } from './views/delivery';
import { getProduct } from './views/pay';

export type InitAppType = () => any;

export const logout = () => async (dispatch: any) => {
  try {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('coookies');
    localStorage.removeItem('intent');
    localStorage.removeItem('transaction');

    dispatch({ type: GeneralTypes.RESET_APP_STATE });
    dispatch({ type: UserTypes.LOGOUT_SUCCESS });
    browserHistory.push('/');
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const isPageNeedInfo = async (location: any) =>
  ![
    HOME_PAGE.split('/')[1],
    PROMOTION_PAGE.split('/')[1],
    TEMPORARY_OFFERS_PAGE.split('/')[1],
    VOUCHER_PAGE.split('/')[1],
    ABORENEWAL_PAGE.split('/')[1],
    FREE_CAMPAIGN_PAGE.split('/')[1],
  ].includes(location.split('/')[1]);

export const initApp: InitAppType =
  () =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      const state = getState();
      const { intent, currentProduct, redirect_url } =
        state.entities.general.appState;
      const { pathname } = state.router.location;
      const token = localStorage.getItem('token');
      const redirectUrl =
        urlParam('redirect_url') !== null
          ? urlParam('redirect_url')
          : redirect_url;
      if (await isPageNeedInfo(pathname)) await dispatch(getProduct());
      await dispatch(getAppActions());
      if (token !== null) {
        const claims: any = jwt_decode(token);
        const user: CurrentUserState = {
          isAuth: claims.sub != null,
          token,
          uuid: claims.sub,
          email: claims.email,
          firstname: claims.given_name,
          lastname: claims.family_name,
          City: '',
          CountryCode: '',
          Street: '',
          HouseNumber: '',
          isValid: !claims.email_confirmation_required,
          ZipCode: '',
        };
        dispatch({ type: UserTypes.INIT_APP, data: user });
        if (
          currentProduct == null &&
          intent != null &&
          (await isPageNeedInfo(pathname))
        ) {
          try {
            await dispatch(getOrderInfo(String(intent)));
          } catch (error: any) {
            // eslint-disable-next-line no-console
            console.log('error', error);
          }
        }

        if (redirectUrl !== null)
          dispatch({
            type: GeneralTypes.SET_REDIRECT_URL,
            data: redirectUrl,
          });
      }
      return { ready: true };
    } catch (error) {
      const token = localStorage.getItem('token');
      if (token !== null) dispatch(logout());
      return { ready: true };
    }
  };

export type GetIdentityType = (accessToken?: string) => any;
export const getIdentity: GetIdentityType =
  (accessToken) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({ type: UserTypes.GET_IDENTITY_REQUEST });
    try {
      // getidentity condition if local or prod
      const token: string | null =
        accessToken || getState().entities.user.currentUser.token;
      const apiUrl = getAPIUrl();
      if (token && window.location.origin === 'http://localhost:3000') {
        const coookies = localStorage.getItem('coookies');
        const resp = await fetch(`${apiUrl}/Identity/GetIdentityAuthLocal`, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(coookies),
        });
        const data = await resp.json();
        if (!resp.ok) throw new Error(data.message);
        return data;
      }
      const resp = await fetch(`${apiUrl}/Identity/GetIdentityAuth`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await resp.json();

      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

export type GetIdentityApiType = () => any;
export const getIdentityApi: GetIdentityApiType =
  () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({ type: UserTypes.GET_IDENTITY_REQUEST });
    try {
      const resp = await fetch(`${getAPIUrl()}/Identity/GetIdentity`, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      if (data.customer !== null) {
        // Auth
        const claims: any = jwt_decode(data.accessToken.token);
        localStorage.setItem('token', data.accessToken.token);
        localStorage.setItem('refresh', data.accessToken.refreshToken);
        return {
          token: data.accessToken.token,
          refresh: data.accessToken.refreshToken,
          claims,
        };
      }
      throw new Error('No data');
    } catch (error) {
      throw error;
    }
  };

export type CheckEmailType = (email: string) => any;
export const checkEmail: CheckEmailType =
  (email) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({ type: UserTypes.CHECK_EMAIL_REQUEST });
    try {
      const resp = await fetch(
        `${getAPIUrl()}/Customers/CheckAlreadyExistCustomer?email=${email}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

export type RefreshTokenType = (sendResponse?: boolean) => any;
export const refreshToken: RefreshTokenType =
  (sendResponse = false) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({ type: UserTypes.REFRESH_TOKEN_REQUEST });
    try {
      const resp = await fetch(`${getAPIUrl()}/Token/Refresh`, {
        method: 'POST',
        body: JSON.stringify({
          token: getState().entities.user.currentUser.token,
          refreshToken: '',
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const data = await resp.json();
      if (!resp.ok) {
        if (sendResponse) {
          return resp;
        }
        throw new Error(data.message);
      }
      const claims: any = jwt_decode(data.token);
      localStorage.setItem('token', data.token);
      localStorage.setItem('refresh', data.refreshToken);
      // const idResp = await dispatch(getIdentity(data.token));
      // if (idResp.lastname && idResp.firstname) {
      //   claims.name = idResp.lastname + ' ' + idResp.firstname;
      //   claims.firstname = idResp.firstname;
      //   claims.lastname = idResp.lastname;
      // } else {
      //   claims.name = '';
      //   claims.firstname = '';
      //   claims.lastname = '';
      // }
      // if (idResp.subscriptionType ) {
      //   claims.subscriptionType = idResp.subscriptionType;
      //   claims.subscriptionStatus = Number(idResp.subscriptionStatus);
      // }

      dispatch({
        type: UserTypes.REFRESH_TOKEN_SUCCESS,
        data: {
          token: data.token,
          refresh: data.refreshToken,
          claims,
        },
      });
      if (sendResponse) {
        return resp;
      }
      return data;
    } catch (error) {
      throw error;
    }
  };

export type ValidateCodeType = (code: string) => any;
export const validateCode: ValidateCodeType =
  (code) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({ type: UserTypes.VALIDATE_CODE_REQUEST });
    try {
      const { uuid } = getState().entities.user.currentUser;
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Customers/ValidateEmail`, {
          method: 'POST',
          body: JSON.stringify({
            code: Number(code),
            uuid,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) {
        throw new Error(data.message);
      }
      const { appActions } = getState().entities.general;
      if (appActions !== null && uuid !== '')
        dispatch(
          log({
            actionDto: appActions.find(
              (a: AppAction) => a.definition === 'ValidateEmail'
            ),
            uuid,
            result: 'OK',
          })
        );
      await dispatch(refreshToken());
      return data;
    } catch (error) {
      throw error;
    }
  };

export type ResendValidationCodeType = () => any;
export const resendValidationCode: ResendValidationCodeType =
  () =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({ type: UserTypes.RESEND_VALIDATE_CODE_REQUEST, getState });
    try {
      const { uuid } = getState().entities.user.currentUser;
      const { email } = getState().entities.user.currentUser;
      if (email === '') {
        throw new Error('Email manquant, connectez-vous.');
      }
      const resp = await fetch(`${getAPIUrl()}/Auth/ResendMailConfirm`, {
        method: 'POST',
        body: JSON.stringify({
          uuid,
          email,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const data = await resp.json();
      if (!resp.ok) {
        throw new Error(data.message);
      }
      const { appActions } = getState().entities.general;
      if (appActions !== null)
        dispatch(
          log({
            actionDto: appActions.find(
              (a: AppAction) => a.definition === 'ResendEmailConfirm'
            ),
            uuid,
            result: 'OK',
          })
        );
      dispatch({ type: UserTypes.RESEND_VALIDATE_CODE_SUCCESS, data });
      return data;
    } catch (error) {
      throw error;
    }
  };

export type LoginType = (email: string, password: string) => any;
export const login: LoginType =
  (email, password) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({ type: UserTypes.LOGIN_REQUEST, getState });
    try {
      const resp = await fetch(`${getAPIUrl()}/Auth/Authenticate`, {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const data = await resp.json();
      if (!resp.ok) {
        throw new Error(data.message);
      }
      // Set cookies
      if (data.cookieDto.length) {
        if (window.location.origin !== 'http://localhost:3000') {
          data.cookieDto.forEach((cook: any) => {
            document.cookie = `${cook.name}=${cook.value}; expires=${cook.expires}; domain=${cook.domain};`;
          });
        } else {
          let cookies: any = '';
          data.cookieDto.forEach((cook: any) => {
            const cookie = `${cook.name}=${cook.value};`;
            cookies += cookie;
          });
          localStorage.setItem('coookies', cookies);
        }
      }
      const claims: any = jwt_decode(data.accessToken.token);
      localStorage.setItem('token', data.accessToken.token);
      localStorage.setItem('refresh', data.accessToken.refreshToken);
      return {
        token: data.accessToken.token,
        refresh: data.accessToken.refreshToken,
        claims,
      };
    } catch (error) {
      throw error;
    }
  };

export type AddUserType = (
  email: string,
  firstname: string,
  lastname: string,
  isValid: boolean
) => any;
export const addUser: AddUserType =
  (email, firstName, lastName, isValid) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getState: () => RootState
  ) => {
    dispatch({ type: UserTypes.ADDUSER_REQUEST });
    const resp = await dispatch(
      fetchWithCredentials(`${getAPIUrl()}/Customers`, {
        method: 'POST',
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          isValid,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
    );
    const data = await resp.json();

    if (!resp.ok) {
      throw new Error(data.message);
    }

    dispatch({ type: UserTypes.ADDUSER_SUCCESS });
  };

export type RegisterType = (email: string, password: string) => any;
export const register: RegisterType =
  (email, password) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({ type: UserTypes.REGISTER_REQUEST, getState });
    try {
      const resp = await fetch(`${getAPIUrl()}/Auth/Register`, {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      dispatch({ type: UserTypes.REGISTER_SUCCESS });
      const { appActions } = getState().entities.general;
      if (appActions !== null)
        dispatch(
          log({
            actionDto: appActions.find(
              (a: AppAction) => a.definition === 'Register'
            ),
            uuid: data.uuid,
            result: `Registration: ${email}`,
          })
        );
      const loginData = await dispatch(login(email, password));
      return loginData;
    } catch (error) {
      throw error;
    }
  };

export interface PersonalUpdateType {
  uuid?: string;
  CompanyName?: string;
  VATNumber?: string;
  firstname?: string;
  lastname?: string;
  phonenumber?: string;
  delivery: boolean;
  address?: {
    street: string;
    houseNumber: number;
    postalBox: string;
    postalCode: number;
    city: string;
    country: UserUpdateTypeCountry;
  };
}
export interface UserUpdateTypeCountry {
  name: string;
  code: string;
}

export interface UserUpdateTypeShort {
  CompanyName?: string;
  VATNumber?: string;
}

export interface UserUpdateType extends UserUpdateTypeShort {
  uuid?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  phoneNumber?: string;
  delivery: boolean;
  deliveryStartDate?: string;
  address?: {
    street: string;
    houseNumber: number;
    postalBox: string;
    postalCode: number;
    city: string;
    country: UserUpdateTypeCountry;
  };
}

export type UpdateType = (user: UserUpdateType) => any;
export const update: UpdateType =
  (user) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({ type: UserTypes.UPDATE_REQUEST, getState });
    try {
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Customers/UpdatePersonInfos`, {
          method: 'POST',
          body: JSON.stringify({ ...user }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      const { appActions } = getState().entities.general;
      const { uuid } = getState().entities.user.currentUser;
      if (appActions !== null && uuid !== '')
        dispatch(
          log({
            actionDto: appActions.find(
              (a: AppAction) => a.definition === 'UpdatePersonInfos'
            ),
            uuid,
            result: 'Update user ok',
          })
        );
      dispatch({ type: UserTypes.UPDATE_SUCCESS, data });
      return { data };
    } catch (error) {
      throw error;
    }
  };

export type SetUserNameType = (firstname: string, lastname: string) => void;
export const setUserName: SetUserNameType =
  (firstname, lastname) => (dispatch: any) => {
    dispatch({ type: UserTypes.SET_USER_NAME, data: { firstname, lastname } });
  };
