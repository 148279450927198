import React, { PropsWithChildren } from 'react';
import { ReactComponent as Check } from '../../images/check.svg';

interface ICheckIconProps {
  primary?: boolean;
  className?: string;
}
const CheckIcon = ({
  className,
  primary = false,
}: PropsWithChildren<ICheckIconProps>) => (
  <Check
    className={className}
    style={{ fill: primary ? '#FFFFFF' : 'var(--primary)' }}
  />
);

export default CheckIcon;
