import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './loader.css';
import { withStyles } from '@material-ui/core';

export interface LoadingScreenProps {
  size?: number;
}

function LoadingScreen(props: any) {
  const { className, customColor, ...others } = props;
  const CustomCircularProgress = withStyles({
    colorPrimary: {
      color: customColor,
    },
  })(CircularProgress);
  return (
    <div className="loader">
      <CustomCircularProgress
        {...others}
        size={props.size}
        className="CircularProgress"
      />
    </div>
  );
}

export default LoadingScreen;
