import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { UserState } from '../../reducers/entities/user';
import { DeliveryOptions, GeneralState } from '../../reducers/entities/general';
import { CreateOrderType } from '../../actions/views/pay';
import icon from '../../images/ic_chevron_right@2x.png';
import browserHistory from '../../utils/browser_history';
import {
  BILLING_ADDRESS_PAGE,
  DELIVERY_ADDRESS_PAGE,
} from '../../utils/routes';
import Modal from '../../components/Modal/modal';
import PickUp from '../../components/PickUp/PickUp';
import { GeneralTypes } from '../../actions/types';
import { flyingFishEvents, trackEvent } from '../../utils/FlyingFish';

interface IContactDataProps {
  user: UserState;
  general: GeneralState;
  actions: {
    createOrder: CreateOrderType;
  };
}

const ContactData = (props: IContactDataProps) => {
  const dispatch = useDispatch();
  const { currentProduct, gift, intent } = props.general.appState;

  const [choices, setChoices] = useState<DeliveryOptions[][]>([]);
  const [showPickupRelay, setShowPickupRelay] = useState<boolean>(false);
  const [isDeliveryToggelable, setDeliveryTogglable] = useState<boolean>(false);
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false);

  const paymentOptionsActions = [
    () => {
      browserHistory.push(DELIVERY_ADDRESS_PAGE);
    },
    () => {
      setShowPickupRelay(true);
    },
    () => {
      browserHistory.push(DELIVERY_ADDRESS_PAGE);
    },
  ];

  const selectDeliveryWay = (deliveryOption: DeliveryOptions) => {
    const index = choices
      .flat()
      .findIndex((choice) => choice.id === deliveryOption.id);
    if (index === null) {
      return;
    }

    const callback = paymentOptionsActions?.[index];
    if (callback) {
      trackEvent(flyingFishEvents.delivery_select(deliveryOption.name));
      dispatch({
        type: GeneralTypes.SET_DELIVERY_OPTION,
        data: deliveryOption,
      });

      callback();
    }
  };

  useEffect(() => {
    if (
      (choices || []).length === 1 &&
      choices?.[0]?.[0].id === 1 // livraison à domicile
    ) {
      selectDeliveryWay(choices[0][0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices]);

  useEffect(() => {
    const deliveryOptions = currentProduct?.deliveryOptionsDto;
    const [first, ...rest] = deliveryOptions || [];
    if (
      (deliveryOptions || []).length === 1 &&
      deliveryOptions?.[0].id === 1 // livraison à domicile
    ) {
      setChoices([[first], rest]);
    } else if (deliveryOptions !== undefined && deliveryOptions.length > 0) {
      setChoices([[first], rest]);
    } else if (deliveryOptions?.length === 0 && choices.length > 0) {
      setChoices([]);
    }

    const homeDelivery = (deliveryOptions || []).find(
      (deliveryOption) => deliveryOption.id === 1
    );
    if (homeDelivery && (deliveryOptions || []).length > 1) {
      setDeliveryTogglable(true);
      setShowMoreButton(true);
    }

    if (
      !currentProduct?.channelDto?.includePaper &&
      gift !== null &&
      gift !== ''
    ) {
      browserHistory.push(DELIVERY_ADDRESS_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProduct?.deliveryOptionsDto]);

  return (
    <div className="contact_data">
      <p className="pb-2">Choisissez une option de livraison.</p>
      <div className="step_formule_wrapper">
        {choices.map((items: DeliveryOptions[], index: number) =>
          items.map((item: DeliveryOptions) => (
            <Paper
              key={item.id}
              tabIndex={0}
              onClick={() => {
                selectDeliveryWay(item);
              }}
              className={
                index > 0 && isDeliveryToggelable ? 'hidden' : 'step_formule'
              }
            >
              <div className="detail_container_delivery">
                <h3 className="name">{item.name}</h3>
                <p className="description">{item.description}</p>
              </div>
              <img alt="" src={icon} className="choose" />
            </Paper>
          ))
        )}
      </div>

      {showMoreButton && (
        <button
          type="button"
          className="toggle-button"
          onClick={() => setDeliveryTogglable(!isDeliveryToggelable)}
        >
          {isDeliveryToggelable
            ? 'Afficher plus d’options de livraison'
            : 'Afficher moins d’options de livraison'}
        </button>
      )}

      {choices.length === 0 && (
        <>
          <br />
          <h3>
            Il n&apos;y a pas de moyen de livraisons disponible pour cette offre
          </h3>
        </>
      )}
      {showPickupRelay && intent && (
        <Modal
          close={() => {
            setShowPickupRelay(false);
          }}
          title="Retrait en point de vente"
        >
          <PickUp
            done={() => {
              setShowPickupRelay(false);
              browserHistory.push(BILLING_ADDRESS_PAGE);
            }}
            intent={intent}
          />
        </Modal>
      )}
    </div>
  );
};

export default ContactData;
