import React, { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';
import 'react-phone-number-input/style.css';

import PhoneInput from 'react-phone-number-input';
import frPhone from 'react-phone-number-input/locale/fr.json';
import { Controller } from 'react-hook-form';
import CombinedRefTextField from '../Address/PhoneNumber';
import AutoComplete from '../AutoComplete';
import {
  getAddressCountriesSuggestionList,
  getAddressFullSuggestionList,
  IAddressSuggestion,
} from '../../actions/views/delivery';
import Checkbox from '../Checkbox';

import '../Address/style.css';
import Row, { RowHalf } from '../Row/Row';
import InputError from '../InputError';
import { CountryType } from '../../utils/model';
import handleKeyboardEvent from '../../utils/handleKeyboardEvent';
import { validatePhoneNumber } from '../../utils/validatePhoneNumber';

export const InternationalNameREGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;
export const VAR_10 = /^.{0,10}$/;
export const VAR_50 = /^.{0,50}$/;
export const VAT_CHECK = /^(([A-Z]{2})[0-9]{4,20})$|^$/;

export type BillingFormValues = {
  isBelgium: boolean;
  hasCountry: boolean;
  hasCity: boolean;
  isBookSeller: boolean;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  companyName: string;
  vatNumber: string;
  addressText: string;
  address: {
    name: string;
    street: string;
    houseNumber: string;
    postalBox: string;
    postalCode: string;
    city: string;
    country: CountryType;
  };
};

export const defaultValues: BillingFormValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  vatNumber: '',
  phoneNumber: '',
  isBelgium: true,
  hasCountry: true,
  hasCity: false,
  isBookSeller: false,
  addressText: '',
  address: {
    name: '',
    street: '',
    houseNumber: '',
    postalBox: '',
    postalCode: '',
    city: '',
    country: {
      name: 'Belgique',
      code: 'BE',
    },
  },
};

interface IAddressFormProps {
  register: any;
  control: any;
  errors: any;
  setValue: Function;
  formIn: string;
  includeBilling?: boolean;
  askBilling?: boolean;
  toggleBill?: Function;
  askPhoneNumber?: boolean;
}

function BillingForm({
  register,
  setValue,
  control,
  errors,
  formIn,
  includeBilling = true,
  askBilling = true,
  toggleBill,
  askPhoneNumber = false,
}: IAddressFormProps) {
  const [countryCode, setCountryCode] = useState<string>('BE');
  const [secAddress, setSecAddress] = useState<number>(1);

  const isBelgium = (): boolean => countryCode === 'BE';

  const [withBill, setWithBill] = useState<boolean>(false);

  const getCountriesSuggestions = async (value: string) =>
    getAddressCountriesSuggestionList(value);

  const getAddressSuggestions = async (
    value: string
  ): Promise<IAddressSuggestion[]> => {
    const suggestions = await getAddressFullSuggestionList(
      value,
      secAddress,
      countryCode
    );

    setSecAddress(secAddress + 1);

    return suggestions;
  };

  return (
    <div className="form_container">
      <section className="field">
        <label htmlFor="firstName" className="label label-required">
          Prénom
        </label>
        <input
          id="firstName"
          className="input"
          name={`${formIn}.firstName`}
          aria-required="true"
          aria-label="Prénom"
          aria-labelledby="firstName"
          type="text"
          ref={register({
            required: 'Prénom requis',
            pattern: {
              value: InternationalNameREGEX,
              message: 'Erreur dans le Prénom',
            },
          })}
        />
        <InputError message={errors?.[formIn]?.firstName?.message} />
      </section>

      <section className="field">
        <label htmlFor="lastName" className="label label-required">
          Nom
        </label>
        <input
          id="lastName"
          name={`${formIn}.lastName`}
          aria-required="true"
          aria-label="Nom"
          aria-labelledby="Lastname"
          className="input"
          type="text"
          ref={register({
            required: 'Nom requis',
            pattern: {
              value: InternationalNameREGEX,
              message: 'Erreur dans le nom',
            },
          })}
        />
        <InputError message={errors?.[formIn]?.lastName?.message} />
      </section>
      {askPhoneNumber && (
        <section className="field">
          <label htmlFor="phoneNumber" className="label label-required">
            Téléphone / GSM
          </label>
          <Controller
            name={`${formIn}.phoneNumber`}
            rules={{
              validate: (val: string) => validatePhoneNumber(val),
            }}
            defaultValue="443"
            as={
              <PhoneInput
                international
                error={!!errors?.[formIn]?.phoneNumber}
                helperText={errors?.[formIn]?.phoneNumber?.message}
                countryCallingCodeEditable
                defaultCountry="BE"
                onChange={(val: any) => val}
                labels={frPhone}
                inputComponent={CombinedRefTextField}
              />
            }
            control={control}
          />
          <p className="mt-2">Pour tout contact concernant votre commande.</p>
        </section>
      )}

      <input
        type="hidden"
        name={`${formIn}.address.country.name`}
        ref={register}
      />
      <input
        type="hidden"
        name={`${formIn}.address.country.code`}
        ref={register({ required: 'Pays requis' })}
      />
      <AutoComplete
        getSuggestions={getCountriesSuggestions}
        setValue={(data: any) => {
          setValue(`${formIn}.address.country.name`, data.name);
          setValue(`${formIn}.address.country.code`, data.alpha2);
          setCountryCode(data.alpha2);
          return data;
        }}
        filterValue={(data: any, value: string) => data.name === value}
        aria-required="true"
        name={`${formIn}.country`}
        label="Pays *"
        placeholder="Entrez votre pays"
        displayResults={(data: any) => data.name}
        className="w-full"
        currentValue="Belgique"
        forceClickSuggestion
      />
      <InputError message={errors?.[formIn]?.address?.country?.code?.message} />

      {isBelgium() ? (
        <>
          <section className="field">
            <input
              type="hidden"
              name={`${formIn}.address.street`}
              ref={register({
                required: 'Rue requise',
              })}
            />
            <input
              type="hidden"
              name={`${formIn}.address.houseNumber`}
              ref={register({
                required: 'Numéro requis',
              })}
            />
            <input
              type="hidden"
              name={`${formIn}.address.city`}
              ref={register({
                required: 'Entrez votre adresse et sélectionnez la',
              })}
            />
            <input
              type="hidden"
              name={`${formIn}.address.postalCode`}
              ref={register({
                required: 'Code postal requis',
              })}
            />

            <AutoComplete
              getSuggestions={getAddressSuggestions}
              setValue={(address: IAddressSuggestion) => {
                const {
                  municipalityName: city,
                  postalCode,
                  streetName,
                  houseNumber,
                } = address;

                setValue(`${formIn}.address.street`, streetName || undefined);
                setValue(
                  `${formIn}.address.houseNumber`,
                  houseNumber || undefined
                );
                setValue(
                  `${formIn}.address.postalCode`,
                  postalCode || undefined
                );
                setValue(`${formIn}.address.city`, city || undefined);

                return '';
              }}
              aria-required="true"
              name={`${formIn}.street`}
              label="Adresse complète *"
              placeholder="Entrez votre adresse"
              displayResults={(address: IAddressSuggestion) =>
                address.searchBarString
              }
              className="w-full"
            />
            <InputError message={errors?.[formIn]?.address?.street?.message} />
            <InputError
              message={errors?.[formIn]?.address?.houseNumber?.message}
            />
            <InputError message={errors?.[formIn]?.address?.city?.message} />
            <InputError
              message={errors?.[formIn]?.address?.postalCode?.message}
            />
          </section>
          <Row>
            <section className="field w-full">
              <label htmlFor="postalBox" className="label">
                Boîte
              </label>
              <input
                id="postalBox"
                name={`${formIn}.address.postalBox`}
                aria-required="false"
                aria-label="Boîte"
                aria-labelledby="postalBox"
                className="input"
                type="text"
                ref={register({
                  maxLength: {
                    value: 128,
                    message: 'Numéro de boite trop long',
                  },
                })}
              />
              <InputError
                message={errors?.[formIn]?.address?.postalBox?.message}
              />
            </section>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <RowHalf>
              <section className="field">
                <label htmlFor="postcode" className="label label-required">
                  Code postal
                </label>
                <input
                  id="postcode"
                  name={`${formIn}.address.postalCode`}
                  aria-required="true"
                  aria-label="Code postal"
                  aria-labelledby="postcode"
                  className="input"
                  type="number"
                  ref={register({
                    required: 'Code postal requis',
                  })}
                />
              </section>
            </RowHalf>
            <RowHalf>
              <section className="field">
                <label htmlFor="city" className="label label-required">
                  Ville
                </label>
                <input
                  id="city"
                  name={`${formIn}.address.city`}
                  aria-required="true"
                  aria-label="Ville"
                  aria-labelledby="city"
                  className="input"
                  type="text"
                  ref={register({
                    required: 'Ville requise',
                  })}
                />
              </section>
            </RowHalf>
          </Row>

          <section className="field w-full">
            <label htmlFor="street" className="label label-required">
              Rue
            </label>
            <input
              id="street"
              name={`${formIn}.address.street`}
              aria-required="true"
              aria-label="Rue"
              aria-labelledby="street"
              className="input"
              type="text"
              ref={register({
                required: 'Rue requise',
              })}
            />
          </section>

          <Row>
            <RowHalf>
              <section className="field">
                <label htmlFor="street_number" className="label label-required">
                  Numéro
                </label>
                <input
                  id="street_number"
                  name={`${formIn}.address.houseNumber`}
                  aria-required="true"
                  aria-label="Numéro"
                  aria-labelledby="street_number"
                  className="input"
                  type="text"
                  ref={register({
                    required: 'Numéro requis',
                  })}
                />
              </section>
            </RowHalf>
            <RowHalf>
              <section className="field">
                <label htmlFor="street_number_box" className="label">
                  Boîte
                </label>
                <input
                  id="street_number_box"
                  name={`${formIn}.street.postalBox`}
                  aria-required="false"
                  aria-label="Boîte"
                  aria-labelledby="street_number_box"
                  className="input"
                  type="text"
                  ref={register({
                    maxLength: {
                      value: 128,
                      message: 'Numéro de boite trop long',
                    },
                  })}
                />
              </section>
              <InputError
                message={errors?.[formIn]?.street?.postalBox?.message}
              />
            </RowHalf>
          </Row>
        </>
      )}

      {askBilling && includeBilling ? (
        <div className="TextField_Container">
          <FormControlLabel
            className="mx-auto"
            control={
              <Checkbox
                // color={this.props.general.appState.brand.thirdColor}
                checked={withBill}
                value="conditions"
                onKeyDown={(e: KeyboardEvent) =>
                  handleKeyboardEvent(e, () => setWithBill(!withBill))
                }
                onChange={() => {
                  setWithBill(!withBill);
                  if (toggleBill) {
                    toggleBill();
                  }
                }}
              />
            }
            label="Je souhaite une facture"
          />
        </div>
      ) : null}

      {(!askBilling || withBill) && (
        <>
          <section className="field">
            <label htmlFor="companyName" className="label label-required">
              Nom d&apos;entreprise
            </label>
            <input
              id="companyName"
              name={`${formIn}.companyName`}
              aria-required="true"
              aria-label="Nom d'entreprise"
              aria-labelledby="companyName"
              className="input"
              type="text"
              ref={register({
                required: "Nom d'entreprise requis",
                pattern: {
                  value: VAR_50,
                  message: "Nom d'entreprise invalide",
                },
              })}
            />
            <InputError message={errors?.[formIn]?.companyName?.message} />
          </section>

          <section className="field">
            <label htmlFor="vatNumber" className="label">
              Numéro de TVA
            </label>
            <input
              id="vatNumber"
              name={`${formIn}.vatNumber`}
              aria-label="Numéro de TVA"
              aria-labelledby="vatNumber"
              className="input"
              type="text"
              ref={register({
                pattern: {
                  value: VAT_CHECK,
                  message: 'Numéro TVA invalide',
                },
              })}
            />
            <InputError message={errors?.[formIn]?.vatNumber?.message} />
          </section>
        </>
      )}
    </div>
  );
}

export default BillingForm;
