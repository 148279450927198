import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { addUser, logout } from '../../actions/userActions';
import { checkUserAccessList } from '../../actions/views/pay';
import { RootState } from '../../reducers';
import FreeCampaign from './freeCampaign';
import {
  confirmTrialCampaign,
  createOrder,
  createTransaction,
} from '../../actions/views/trialCampaign';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  return { user, general };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      logout,
      addUser,
      createOrder,
      confirmTrialCampaign,
      createTransaction,
      checkUserAccessList,
    },
    dispatch
  ),
});

export type ContactDataProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FreeCampaign);
