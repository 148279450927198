import TagManager from 'react-gtm-module';
import { IBrand } from '../../utils/brands';

const initGTM = (gtm: string | null | undefined): void => {
  if (gtm) {
    TagManager.initialize({ gtmId: gtm });
    window.cache.canTrack = true;
  }
};
const loadGTM = async ({
  brand,
  env,
  setIsGTMLoaded,
}: {
  brand: IBrand;
  env: string;
  setIsGTMLoaded: (isLoaded: boolean) => void;
}) => {
  switch (env) {
    case 'PROD':
    case 'UAT':
      await initGTM(brand.GTM);
      setIsGTMLoaded(true);
      break;
    case 'DEV':
      break;
    default:
      break;
  }
};

export default loadGTM;
