import React from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';
import CombinedRefTextField from '../Address/PhoneNumber';
import '../Address/style.css';
import { validatePhoneNumber } from '../../utils/validatePhoneNumber';

interface IContactDataShortProps {
  register: any;
  control: any;
  errors: any;
}

const ContactDataShort = ({ control, errors }: IContactDataShortProps) => (
  <section className="label">
    <label htmlFor="phoneNumber" className="label">
      Téléphone / GSM *
    </label>
    <Controller
      name="short.phone"
      rules={{
        validate: (val: string) => validatePhoneNumber(val),
      }}
      defaultValue="443"
      as={
        <PhoneInput
          international
          error={!!errors?.short?.phone?.message}
          helperText={errors?.short?.phone?.message}
          countryCallingCodeEditable
          defaultCountry="BE"
          onChange={(val: any) => val}
          labels={fr}
          inputComponent={CombinedRefTextField}
        />
      }
      control={control}
    />
    <p className="mt-2">Pour tout contact concernant votre commande.</p>
  </section>
);

export default ContactDataShort;
