import React, { useState } from 'react';
import { FormControlLabel } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { UserState } from '../../reducers/entities/user';
import { GeneralState } from '../../reducers/entities/general';
import Address from '../../components/Address/index';
import { CountryType } from '../../utils/model';
import Checkbox from '../../components/Checkbox';
import CustomButton from '../../components/Button';
import { PersonalUpdateType, UserUpdateType } from '../../actions/userActions';
import { addAddressInfo } from '../../actions/views/delivery';
import browserHistory from '../../utils/browser_history';
import { PAY_PAGE, PAY_RENEWAL_PAGE } from '../../utils/routes';
import '../../components/Address/style.css';
import handleKeyboardEvent from '../../utils/handleKeyboardEvent';
import useToggle from '../../hooks/useToggle';
import { getUrlParamsFromLs } from '../../utils/url';

interface IDeliveryProps {
  user: UserState;
  general: GeneralState;
}

export const defaultValues: FormValues = {
  firstname: '',
  lastname: '',
  phoneNumber: '',
  isBelgium: true,
  hasCountry: true,
  hasCity: false,
  isBookSeller: false,
  deliveryStartDate: null,
  addressText: '',
  address: {
    name: '',
    street: '',
    houseNumber: '',
    postalBox: '',
    postalCode: '',
    city: '',
    country: {
      name: 'Belgique',
      code: 'BE',
    },
  },
  billing: {
    companyName: '',
    vat: '',
  },
};

export type FormValues = {
  firstname: string;
  lastname: string;
  phoneNumber: string;
  isBelgium: boolean;
  hasCountry: boolean;
  hasCity: boolean;
  isBookSeller: boolean;
  addressText: string;
  deliveryStartDate: null | Date;
  address: {
    name: string;
    street: string;
    houseNumber: string;
    postalBox: string;
    postalCode: string;
    city: string;
    country: CountryType;
  };
  billing: {
    companyName: string;
    vat: string;
  };
};

type DeliveryForm = {
  delivery: FormValues;
  billing: FormValues;
};

enum DeliveryEnum {
  PERSONAL,
  ENTERPRISE,
  SHIPPING,
  LIBRARY_CHECK,
}

const Delivery = ({ general }: IDeliveryProps) => {
  const { currentProduct, deliveryOption } = general.appState;
  const dispatch = useDispatch();

  const [differentAddress, setDifferentAddress] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendBill, toggleBill] = useToggle(false);

  const { register, errors, handleSubmit, setValue, control } =
    useForm<DeliveryForm>({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {
        delivery: { ...defaultValues },
        billing: { ...defaultValues },
      },
    });

  const saveAddressInfo = async (
    type: string,
    user: UserUpdateType | PersonalUpdateType
  ) => {
    try {
      await dispatch(
        await addAddressInfo(type, user, String(general.appState.intent))
      );
    } catch (err: any) {
      setError(err.message);
    }
  };

  const Personal = async (
    form: FormValues,
    billing: FormValues | null = null
  ) => {
    try {
      const data = {
        firstname: billing != null ? billing.firstname : form.firstname,
        lastname: billing != null ? billing.lastname : form.lastname,
        phonenumber: billing != null ? billing.phoneNumber : form.phoneNumber,
        delivery: false,
        ...(form.deliveryStartDate && {
          deliveryStartDate: new Date(form.deliveryStartDate).toISOString(),
        }),
        address: {
          street:
            billing != null ? billing.address.street : form.address.street,
          houseNumber:
            billing != null
              ? parseInt(billing.address.houseNumber)
              : parseInt(form.address.houseNumber),
          postalBox:
            billing != null
              ? billing.address.postalBox
              : form.address.postalBox,
          postalCode:
            billing != null
              ? parseInt(billing.address.postalCode)
              : parseInt(form.address.postalCode),
          city: billing != null ? billing.address.city : form.address.city,
          country:
            billing != null ? billing.address.country : form.address.country,
        },
      };

      await saveAddressInfo('personal', data);

      if (sendBill) {
        const companyData: PersonalUpdateType = {
          CompanyName: form.billing.companyName,
          VATNumber: form.billing.vat,
          firstname: billing != null ? billing.firstname : form.firstname,
          lastname: billing != null ? billing.lastname : form.lastname,
          phonenumber: billing != null ? billing.phoneNumber : form.phoneNumber,
          delivery: false,
          address: {
            street:
              billing != null ? billing.address.street : form.address.street,
            houseNumber:
              billing != null
                ? parseInt(billing.address.houseNumber)
                : parseInt(form.address.houseNumber),
            postalBox:
              billing != null
                ? billing.address.postalBox
                : form.address.postalBox,
            postalCode:
              billing != null
                ? parseInt(billing.address.postalCode)
                : parseInt(form.address.postalCode),
            city: billing != null ? billing.address.city : form.address.city,
            country:
              billing != null ? billing.address.country : form.address.country,
          },
        };

        await saveAddressInfo('billing', companyData);
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const Shipping = async (form: FormValues) => {
    try {
      if (!currentProduct) {
        return;
      }

      if (
        (currentProduct.gifts === undefined ||
          currentProduct.gifts === null ||
          currentProduct.gifts.length === 0) &&
        !currentProduct.channelDto.includePaper
      ) {
        return;
      }
      const data: UserUpdateType = {
        firstname: form.firstname,
        lastname: form.lastname,
        delivery: true,
        ...(form.deliveryStartDate && {
          deliveryStartDate: new Date(form.deliveryStartDate).toISOString(),
        }),
        address: {
          street: form.address.street,
          houseNumber: parseInt(form.address.houseNumber, 10),
          postalBox: form.address.postalBox,
          postalCode: parseInt(form.address.postalCode, 10),
          city: form.address.city,
          country: form.address.country,
        },
      };

      await saveAddressInfo('delivery', data);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const Library = async (form: FormValues) => {
    try {
      const data: UserUpdateType = {
        firstname: form.firstname,
        lastname: form.lastname,
        delivery: true,
        ...(form.deliveryStartDate && {
          deliveryStartDate: new Date(form.deliveryStartDate).toISOString(),
        }),
        address: {
          street: form.address.street,
          houseNumber: parseInt(form.address.houseNumber, 10),
          postalBox: form.address.postalBox,
          postalCode: parseInt(form.address.postalCode, 10),
          city: form.address.city,
          country: form.address.country,
        },
      };

      await saveAddressInfo('bookSeller', data);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const saveUserInfo = async (
    type: number,
    form: FormValues,
    billing: FormValues | null = null
  ) => {
    const { editionDto } = currentProduct || {};
    if (!currentProduct || !editionDto) {
      return;
    }

    switch (type) {
      case DeliveryEnum.PERSONAL:
        await Personal(form, billing);
        break;
      case DeliveryEnum.SHIPPING:
        await Shipping(form);
        break;
      case DeliveryEnum.LIBRARY_CHECK:
        await Library(form);
        break;
      default:
        setError('Problem with sending data');
    }

    // const actions: Record<number, () => void> = {
    //   // + billing included (when the bill checkbox is on)
    //   [DeliveryEnum.PERSONAL]: async () => {
    //     const data = {
    //       firstname: billing != null ? billing.firstname : form.firstname,
    //       lastname: billing != null ? billing.lastname : form.lastname,
    //       phonenumber: billing != null ? billing.phoneNumber : form.phoneNumber,
    //       delivery: false,
    //       ...(form.deliveryStartDate && {
    //         deliveryStartDate: new Date(form.deliveryStartDate).toISOString(),
    //       }),
    //       address: {
    //         street:
    //           billing != null ? billing.address.street : form.address.street,
    //         houseNumber:
    //           billing != null
    //             ? parseInt(billing.address.houseNumber)
    //             : parseInt(form.address.houseNumber),
    //         postalBox:
    //           billing != null
    //             ? billing.address.postalBox
    //             : form.address.postalBox,
    //         postalCode:
    //           billing != null
    //             ? parseInt(billing.address.postalCode)
    //             : parseInt(form.address.postalCode),
    //         city: billing != null ? billing.address.city : form.address.city,
    //         country:
    //           billing != null ? billing.address.country : form.address.country,
    //       },
    //     };

    //     await saveAddressInfo('personal', data);

    //     if (sendBill) {
    //       const companyData: PersonalUpdateType = {
    //         CompanyName: form.billing.companyName,
    //         VATNumber: form.billing.vat,
    //         firstname: billing != null ? billing.firstname : form.firstname,
    //         lastname: billing != null ? billing.lastname : form.lastname,
    //         phonenumber:
    //           billing != null ? billing.phoneNumber : form.phoneNumber,
    //         delivery: false,
    //         address: {
    //           street:
    //             billing != null ? billing.address.street : form.address.street,
    //           houseNumber:
    //             billing != null
    //               ? parseInt(billing.address.houseNumber)
    //               : parseInt(form.address.houseNumber),
    //           postalBox:
    //             billing != null
    //               ? billing.address.postalBox
    //               : form.address.postalBox,
    //           postalCode:
    //             billing != null
    //               ? parseInt(billing.address.postalCode)
    //               : parseInt(form.address.postalCode),
    //           city: billing != null ? billing.address.city : form.address.city,
    //           country:
    //             billing != null
    //               ? billing.address.country
    //               : form.address.country,
    //         },
    //       };

    //       await saveAddressInfo('billing', companyData);
    //     }
    //   },
    //   // delivery or library types
    //   [DeliveryEnum.SHIPPING]: async () => {
    //     if (
    //       currentProduct.gifts.length === 0 &&
    //       !currentProduct.channelDto.includePaper
    //     ) {
    //       return;
    //     }

    //     const data: UserUpdateType = {
    //       firstname: form.firstname,
    //       lastname: form.lastname,
    //       delivery: true,
    //       ...(form.deliveryStartDate && {
    //         deliveryStartDate: new Date(form.deliveryStartDate).toISOString(),
    //       }),
    //       address: {
    //         street: form.address.street,
    //         houseNumber: parseInt(form.address.houseNumber, 10),
    //         postalBox: form.address.postalBox,
    //         postalCode: parseInt(form.address.postalCode, 10),
    //         city: form.address.city,
    //         country: form.address.country,
    //       },
    //     };

    //     await saveAddressInfo('delivery', data);
    //   },
    //   [DeliveryEnum.LIBRARY_CHECK]: async () => {
    //     const data: UserUpdateType = {
    //       firstname: form.firstname,
    //       lastname: form.lastname,
    //       delivery: true,
    //       ...(form.deliveryStartDate && {
    //         deliveryStartDate: new Date(form.deliveryStartDate).toISOString(),
    //       }),
    //       address: {
    //         street: form.address.street,
    //         houseNumber: parseInt(form.address.houseNumber, 10),
    //         postalBox: form.address.postalBox,
    //         postalCode: parseInt(form.address.postalCode, 10),
    //         city: form.address.city,
    //         country: form.address.country,
    //       },
    //     };

    //     await saveAddressInfo('bookSeller', data);
    //   },
    // };
    // actions[type as keyof typeof actions]();
  };

  const onSubmit = handleSubmit(async (data) => {
    const { delivery, billing } = data;
    setIsLoading(true);

    try {
      await saveUserInfo(DeliveryEnum.PERSONAL, delivery, billing);
      await saveUserInfo(
        deliveryOption?.id !== 3
          ? DeliveryEnum.SHIPPING
          : DeliveryEnum.LIBRARY_CHECK,
        delivery
      );
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }

    if (!general.appState.renewal) {
      browserHistory.push(PAY_PAGE + getUrlParamsFromLs());
    } else {
      browserHistory.push(PAY_RENEWAL_PAGE + getUrlParamsFromLs());
    }
  });

  const toggleDifferentAddress = () => {
    setDifferentAddress(!differentAddress);
    if (!differentAddress) {
      setValue('billing', undefined);
    }
  };

  return (
    <div className="step_formule_wrapper">
      <div className="form_overlay">
        {error && <div>{error}</div>}
        {isLoading && <div>loading</div>}
        <h2>Adresse de livraison</h2>
        <form onSubmit={onSubmit}>
          <Address
            register={register}
            control={control}
            setValue={setValue}
            errors={errors}
            formIn="delivery"
            includeStartDateDelayed={currentProduct?.channelDto.includePaper}
            onlyBelgium
            toggleBill={toggleBill}
            color={general.appState.brand.thirdColor}
          />

          <div className="TextField_Container mt-4">
            <FormControlLabel
              className="mx-auto"
              control={
                <Checkbox
                  color={general.appState.brand.thirdColor}
                  checked={differentAddress}
                  value="conditions"
                  onKeyDown={(e: KeyboardEvent) =>
                    handleKeyboardEvent(e, toggleDifferentAddress)
                  }
                  onChange={toggleDifferentAddress}
                />
              }
              label="Les adresses de livraison et de facturation sont différentes"
            />
          </div>

          {differentAddress && (
            <>
              <h2>Adresse de facturation</h2>
              <Address
                color={general.appState.brand.thirdColor}
                register={register}
                control={control}
                setValue={setValue}
                errors={errors}
                formIn="billing"
                includeBilling={false}
              />
            </>
          )}

          <CustomButton
            customColor={general.appState.brand.thirdColor}
            className="button validate"
            aria-label="Valider l’adresse"
            color="primary"
            type="submit"
            fontSize="15px"
            fullWidth
            size="large"
          >
            Valider l’adresse
          </CustomButton>
          <p className="mt-4">* champ obligatoire</p>
        </form>
      </div>
    </div>
  );
};

export default Delivery;
