import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import Message from '../../components/Message';
import LoadingScreen from '../../components/LoadingScreen';
import './Duration.css';
import icon from '../../images/ic_chevron_right@2x.png';
import { EDITION_PAGE, GIFT_PAGE, USER_LOGIN_PAGE } from '../../utils/routes';
import { formatPrice, pricePerWeek } from '../../utils/price';
import { goToPage } from '../../utils/helpers';
import { GeneralState, ProductType } from '../../reducers/entities/general';
import { UserState } from '../../reducers/entities/user';
import {
  GetDurationsType,
  SelectDurationType,
  SelectProductType,
  SetCartType,
} from '../../actions/views/duration';
import { GetGiftType, SelectGiftType } from '../../actions/views/gift';
import { GetProductType } from '../../actions/views/pay';
import usePiano from '../../components/AuthPiano/PianoID';

interface IDurationProps {
  general: GeneralState;
  user: UserState;
  currentPath: any;
  actions: {
    selectDuration: SelectDurationType;
    selectProduct: SelectProductType;
    getDurations: GetDurationsType;
    getGifts: GetGiftType;
    selectGift: SelectGiftType;
    getProduct: GetProductType;
    SetCart: SetCartType;
  };
}

const Duration = (props: IDurationProps) => {
  const {
    brand,
    offer,
    hasGift,
    campaign,
    duration,
    pianoInitialized,
    currentProduct,
  } = props.general.appState;
  const { startComposer } = usePiano({ action: 'composer' }) || {};
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [durations, setDurations] = useState<ProductType[]>([]);
  const isBuyPianoExp =
    currentProduct?.channelDto?.name.toLowerCase() === 'digital+';
  const handleSelectDuration = async (
    duration: number,
    name: string,
    productId: number,
    price: string,
    replace = false
  ) => {
    if (offer === null) {
      return;
    }
    setIsLoading(true);
    await props.actions.selectDuration(duration, name, price);
    await props.actions.selectProduct(productId);
    await props.actions.getProduct();
    if (hasGift === true) {
      goToPage(`${GIFT_PAGE}${props.currentPath.search}`, replace);
    } else if (brand.hasEdition) {
      goToPage(`${EDITION_PAGE}${props.currentPath.search}`, replace);
    } else {
      goToPage(`${USER_LOGIN_PAGE}${props.currentPath.search}`, replace);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    async function fetchDurations() {
      try {
        if (isBuyPianoExp) {
          if (pianoInitialized) {
            await startComposer();
            setIsLoading(false);
          }
        } else {
          const fetchedDurations = await props.actions.getDurations(
            campaign ?? '',
            brand.id
          );

          if (fetchedDurations.length === 1 && duration === null) {
            await handleSelectDuration(
              fetchedDurations[0].duration,
              fetchedDurations[0].name,
              fetchedDurations[0].id,
              fetchedDurations[0].price,
              true
            );
          } else {
            props.actions.SetCart(fetchedDurations[0]);
            const sortedDurations = fetchedDurations.sort(
              (a: any, b: any) => a.price - b.price
            );
            setDurations(sortedDurations);
            setIsLoading(false);
          }
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
        setIsLoading(false);
      }
    }

    if (durations.length === 0) {
      fetchDurations();
    }
  }, [brand, currentProduct?.channelDto?.name]);

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    duration: number,
    name: string,
    id: number,
    price: string
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleSelectDuration(duration, name, id, price);
    }
  };

  if (offer === null) {
    return <Redirect to="/" />;
  }

  if (isLoading) {
    return <LoadingScreen customColor={brand.thirdColor} size={60} />;
  }

  return (
    <div className="step_formule_wrapper">
      {error !== '' && (
        <Message className="intent_message" intent="DANGER" message={error} />
      )}
      {isBuyPianoExp ? (
        <div className="test-template" />
      ) : (
        durations.length > 0 &&
        durations.map((duration) => (
          <Paper
            key={`${duration.id}-${duration.name}`}
            tabIndex={0}
            onKeyDown={(e) =>
              onKeyDown(
                e,
                duration.duration,
                duration.name,
                duration.id,
                formatPrice(duration.price)
              )
            }
            onClick={() =>
              handleSelectDuration(
                duration.duration,
                duration.name,
                duration.id,
                formatPrice(duration.price)
              )
            }
            className={`step_formule ${
              duration.highlight ? 'highlight-bordered' : ''
            }`}
          >
            <div className="detail_container">
              {duration.highlight && (
                <div className="highlight_duration">
                  <span>Recommandé</span>
                </div>
              )}
              <h3 className="name">{duration.name}</h3>
              <h3 className="price">
                {formatPrice(duration.price)}€{' '}
                {duration.pricePerWeek && (
                  <>
                    {' '}
                    soit{' '}
                    <span
                      className="pricePerWeek"
                      style={{ color: 'var(--primary)' }}
                    >
                      {pricePerWeek(duration.price, duration.duration)}€ /
                      semaine
                    </span>
                  </>
                )}
              </h3>
            </div>
            <img alt="" src={icon} className="choose" />
          </Paper>
        ))
      )}
    </div>
  );
};

export default Duration;
