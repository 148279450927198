import React, { PropsWithChildren } from 'react';
import { ReactComponent as User } from '../../images/user.svg';

interface IAccountIconProps {
  className?: string;
  ariaLabel?: string;
  ariaOwn?: string;
  onClick: (e: React.MouseEvent) => void;
}
const AccountIcon = ({
  className,
  ariaLabel,
  ariaOwn,
  onClick,
}: PropsWithChildren<IAccountIconProps>) => (
  <User
    aria-haspopup="true"
    aria-owns={ariaOwn}
    aria-label={ariaLabel}
    className={className}
    onClick={onClick}
    id="button_piano_connect"
  />
);

export default AccountIcon;
