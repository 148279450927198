import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import entities from './entities';
import { GeneralState } from './entities/general';
import { UserState } from './entities/user';

export interface RootState {
  entities: {
    general: GeneralState;
    user: UserState;
  };
  router: RouterState;
}

export default (history: any) =>
  combineReducers({
    entities,
    router: connectRouter(history),
  });
