import React from 'react';
import Button from '@material-ui/core/Button';

import './Button.css';
import { withStyles } from '@material-ui/core';

function CustomButton(props: any) {
  const { className, customColor, ...others } = props;
  const CustomButtonStyle = withStyles({
    root: {
      fontSize: '15px',
      backgroundColor: customColor,
      '&:hover': {
        backgroundColor: customColor,
      },
    },
  })(Button);
  return (
    <CustomButtonStyle
      {...others}
      variant="contained"
      className={`${className || ''} custom_button`}
    >
      {props.children}
    </CustomButtonStyle>
  );
}

export default CustomButton;
