import { combineReducers } from 'redux';
// import ReactGA from 'react-ga';
import { UserTypes } from '../../actions/types';

export interface UserState {
  currentUser: CurrentUserState;
}

export interface CurrentUserState {
  isAuth: boolean;
  token: string | null;
  // refresh: string | null;
  uuid: string;
  email: string;
  // name: string;
  firstname: string;
  lastname: string;
  ZipCode: string;
  City: string;
  CountryCode: string;
  Street: string;
  HouseNumber: string;
  isValid: boolean;
  // subscriptionType: string | null;
  // subscriptionStatus: number;
}

const defaultState = {
  isAuth: false,
  token: null,
  // refresh: null,
  uuid: '',
  email: '',
  // name: '',
  firstname: '',
  lastname: '',
  ZipCode: '',
  City: '',
  CountryCode: '',
  Street: '',
  HouseNumber: '',
  isValid: false,
  // subscriptionType: null,
  // subscriptionStatus: 0
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function currentUser(state: CurrentUserState = defaultState, action: any) {
  if (action.type === UserTypes.SET_USER_NAME) {
    const newState = { ...state };
    newState.firstname = action.data.firstname;
    newState.lastname = action.data.lastname;
    return newState;
  }
  if (action.type === UserTypes.INIT_APP) {
    const newState = { ...state };
    newState.token = action.data.token;
    // newState.refresh = action.data.refresh;
    newState.isAuth = action.data.isAuth;
    newState.uuid = action.data.uuid;
    newState.email = action.data.email;
    newState.ZipCode = action.data.ZipCode;
    newState.lastname = action.data.lastname;
    newState.firstname = action.data.firstname;
    newState.isValid = action.data.isValid;
    // newState.subscriptionType = action.data.subscriptionType;
    // newState.subscriptionStatus = action.data.subscriptionStatus;
    return newState;
  }
  if (action.type === UserTypes.RECEIVED_ME) {
    const newState = { ...state };
    return newState;
  }
  if (action.type === UserTypes.REGISTER_SUCCESS) {
    const newState = { ...state };
    return newState;
  }
  if (action.type === UserTypes.LOGIN_SUCCESS) {
    const newState = {
      ...state,
      token: action.data.token,
      uuid: action.data.user.sub,
      firstname: action.data.user.firstName,
      lastname: action.data.user.lastName,
      email: action.data.user.email,
      isValid: action.data.user.confirmed,
      isAuth: true,
    };

    // if (process.env.REACT_APP_URL === 'https://aboshop.lavenir.net') {
    //   ReactGA.set({ userId: action.data.user.sub });
    // }

    return newState;
  }
  if (action.type === UserTypes.REFRESH_TOKEN_SUCCESS) {
    const newState = { ...state };
    newState.token = action.data.token;
    // newState.refresh = action.data.refresh;
    newState.uuid = action.data.claims.sub;
    // newState.name = action.data.claims.name;
    newState.firstname = action.data.claims.firstname;
    newState.lastname = action.data.claims.lastname;
    newState.email = action.data.claims.email;
    newState.isValid = action.data.claims.isValid === 'True';
    // newState.subscriptionType = action.data.claims.subscriptionType;
    // newState.subscriptionStatus = action.data.claims.subscriptionStatus;
    return newState;
  }
  if (action.type === UserTypes.LOGOUT_SUCCESS) {
    return defaultState;
  }
  if (action.type === UserTypes.VALIDATE_CODE_SUCCESS) {
    const newState = { ...state };
    newState.isValid = true;
    return newState;
  }

  return state;
}

export default combineReducers({
  // the current user
  currentUser,
});
