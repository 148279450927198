import React from 'react';
import './Header.css';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom';
import { BrandType } from '../../reducers/entities/general';
import getStepContent from '../../utils/getStepContent';

class Header extends React.PureComponent<{
  activeStep: number;
  steps: any;
  brand: BrandType;
}> {
  render() {
    const { activeStep, steps, brand } = this.props;
    const titleElement = document.querySelector('#currentTitle');
    const currentTitle = (
      <h1 className="leading-10">{getStepContent(activeStep, steps)}</h1>
    );

    return (
      <div className="banner">
        <Helmet>
          <title>
            {brand.metaTitle} - {getStepContent(activeStep, steps)}
          </title>
        </Helmet>

        {titleElement
          ? ReactDOM.createPortal(currentTitle, titleElement)
          : currentTitle}
      </div>
    );
  }
}

export default Header;
