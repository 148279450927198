import React from 'react';
import { Redirect } from 'react-router-dom';
import { GiftProps } from '.';
import './Gift.css';
import LoadingScreen from '../LoadingScreen';
import browserHistory from '../../utils/browser_history';
import Message from '../Message';
import icon from '../../images/ic_chevron_right@2x.png';
// import { getUrlParamsFromLs } from '../../utils/url';
import { EDITION_PAGE, USER_LOGIN_PAGE } from '../../utils/routes';
import { goToPage } from '../../utils/helpers';

class Gift extends React.Component<
  GiftProps,
  { isLoading: boolean; gifts: any; error: string; intentError: string }
> {
  constructor(props: GiftProps) {
    super(props);
    this.state = {
      isLoading: true,
      gifts: [],
      error: '',
      intentError: '',
    };
  }

  async componentDidMount() {
    const { offer, duration, brand, currentProduct } =
      this.props.general.appState;
    if (offer === null || duration === null) {
      browserHistory.push('/');
    }
    try {
      const gifts =
        currentProduct?.gifts != null
          ? currentProduct?.gifts
          : await this.props.getGifts().gifts;
      if (gifts === undefined || gifts === null || gifts === '') {
        await this.props.setNoGift();
        if (brand.hasEdition) {
          goToPage(`${EDITION_PAGE}${this.props.currentPath.search}`);
        } else {
          goToPage(`${USER_LOGIN_PAGE}${this.props.currentPath.search}`);
        }
      } else {
        const giftsArray = await gifts.split(';');
        if (giftsArray.length === 1) {
          await this.handleSelectGift(giftsArray[0]);
        }

        this.setState({
          isLoading: false,
          gifts: giftsArray,
          error: '',
        });
      }
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
    }
  }

  handleSelectGift = async (gift: string) => {
    const { selectGift } = this.props;
    const { offer, brand } = this.props.general.appState;
    this.setState({ isLoading: true });
    if (offer === null) {
      return;
    }
    try {
      selectGift(gift);
    } catch (error: any) {
      this.setState({
        intentError: error.message,
        isLoading: false,
      });
    }
    if (brand.hasEdition) {
      goToPage(`${EDITION_PAGE}${this.props.currentPath.search}`);
    } else {
      goToPage(`${USER_LOGIN_PAGE}${this.props.currentPath.search}`);
    }
  };

  onKeyDown = (
    gift: any,
    event: React.KeyboardEvent<HTMLButtonElement>
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.handleSelectGift(gift);
    }
  };

  render() {
    const { isLoading, gifts, error, intentError } = this.state;
    const { offer, duration, brand } = this.props.general.appState;

    if (isLoading) {
      return <LoadingScreen customColor={brand.thirdColor} size={60} />;
    }

    if (error !== '') {
      return (
        <Message className="intent_message" intent="DANGER" message={error} />
      );
    }

    if (offer === null || duration === null) {
      return <Redirect to="/" />;
    }

    return (
      <div className="step_formule_wrapper w-full gift">
        {intentError !== '' && (
          <Message
            className="intent_message"
            intent="DANGER"
            message={intentError}
          />
        )}

        {gifts.map((gift: any) => (
          <button
            tabIndex={0}
            onKeyDown={(e) => this.onKeyDown(gift, e)}
            onClick={() => this.handleSelectGift(gift)}
            key={`gift-${gift}`}
            type="button"
            className="step_formule"
          >
            <div className="detail_container">
              <h3 className="name">{gift}</h3>
            </div>
            <img alt="" src={icon} className="choose" />
          </button>
        ))}
      </div>
    );
  }
}

export default Gift;
