import { combineReducers } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withReduxStateSync } from 'redux-state-sync';

import general from './general';
import user from './user';

const rootReducer = combineReducers({
  general,
  user,
});

export default withReduxStateSync(rootReducer);
