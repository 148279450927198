import React, { ReactElement } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { withStyles } from '@material-ui/core/styles';

export interface MessageProps {
  classes?: any;
  className?: string;
  intent: 'DANGER' | 'WARNING' | 'SUCCESS' | 'INFO';
  title?: string;
  message: ReactElement<any> | string | boolean;
}

const intentIcon = {
  SUCCESS: CheckCircleIcon,
  WARNING: WarningIcon,
  DANGER: ErrorIcon,
  INFO: InfoIcon,
};

const styles = (theme: any) => ({
  SUCCESS: {
    backgroundColor: green[600],
  },
  DANGER: {
    backgroundColor: theme.palette.error.dark,
  },
  INFO: {
    backgroundColor: theme.palette.primary.dark,
  },
  WARNING: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  snack: {
    boxShadow: 'none',
  },
});

function Message(props: MessageProps) {
  const { classes, className, message, intent } = props;
  const Icon = intentIcon[intent];
  return (
    <SnackbarContent
      className={`${classes[intent]} ${className} ${classes.snack}`}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          {message}
        </span>
      }
      tabIndex={0}
    />
  );
}

export default withStyles(styles)(Message);
