import React, { PropsWithChildren } from 'react';
import { ReactComponent as Cross } from '../../images/cross.svg';

import './checkedbullet.css';
import CheckIcon from '../CheckIcon/CheckIcon';

interface ICheckedBulletProps {
  checked: boolean;
  insidePrimary?: boolean;
  className?: string;
}

const CheckedBullet = ({
  checked,
  children,
  insidePrimary = false,
  className = '',
}: PropsWithChildren<ICheckedBulletProps>) => (
  <li
    className={`checkedbullet ${insidePrimary ? 'primary' : ''} ${
      checked ? 'checked' : 'unchecked'
    } ${className}`}
  >
    {checked ? (
      <CheckIcon className="w-2/10" primary={insidePrimary} />
    ) : (
      <Cross />
    )}
    <div className="w-10/12">{children}</div>
  </li>
);

export default CheckedBullet;
