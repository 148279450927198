import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import AuthPiano from './AuthPiano';
import {
  login,
  register,
  checkEmail,
  validateCode,
  resendValidationCode,
  addUser,
} from '../../actions/userActions';
import { log } from '../../actions/generalActions';
import { RootState } from '../../reducers';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  const { router } = state;
  return {
    user,
    general,
    currentPath: router.location.pathname,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      log,
      login,
      register,
      checkEmail,
      addUser,
      validateCode,
      resendValidationCode,
    },
    dispatch
  ),
});

export type AuthPianoProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    close: Function;
    className?: string;
  };

export default connect(mapStateToProps, mapDispatchToProps)(AuthPiano);
