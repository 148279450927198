import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { GeneralState } from '../../reducers/entities/general';
import LoadingScreen from '../../components/LoadingScreen';
import { getCurrentBrand } from '../../utils/brands';
import Message from '../../components/Message';
import Button from '../../components/Button';
import Row from '../../components/Row/Row';
import InputError from '../../components/InputError';

interface ICookieProps {
  general: GeneralState;
}

interface CookieFormValues {
  cookie: string;
}

const Cookie = ({ general }: ICookieProps) => {
  const { brand } = general.appState;
  const { hash } = useParams<{ hash: string }>();
  const cookieParam = getCurrentBrand(brand.name)?.cookie;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stateCookie, setStateCookie] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const setCookie = async (hashValue: string, cookie: string) => {
    if (hashValue === cookie) {
      Cookies.set('aboshop-access', cookie, { expires: 365 });
      setStateCookie(true);
    } else {
      setError(
        hashValue === 'nohash'
          ? 'Vous avez besoin du cookie pour accéder aux versions de test et preview.'
          : 'Mauvais code, réessayer'
      );
    }
  };

  useEffect(() => {
    setCookie(hash, cookieParam);
    setIsLoading(false);
  }, [cookieParam, hash]);

  const { handleSubmit, register, errors } = useForm<CookieFormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      cookie: '',
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    setError(null);
    setIsLoading(true);
    try {
      setCookie(data.cookie, cookieParam);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  });

  if (isLoading) {
    return (
      <LoadingScreen
        customColor={general.appState.brand.thirdColor}
        size={60}
      />
    );
  }

  return (
    <main role="list" className="step_formule_wrapper w-full">
      {!stateCookie ? (
        <>
          {error?.length !== undefined && error?.length > 0 && (
            <Message
              className="intent_message"
              intent="WARNING"
              message={error}
            />
          )}
          <form className="voucher_form" autoComplete="off" onSubmit={onSubmit}>
            <div className="flex flex-col">
              <label htmlFor="cookie">Code</label>
              <input
                id="cookie"
                className="input"
                name="cookie"
                aria-required="true"
                aria-label="hash"
                aria-labelledby="cookie"
                type="text"
                placeholder="Entrez votre Code"
                aria-placeholder="Entrez votre Code"
                ref={register({
                  required: 'Le Code est requis',
                })}
              />
            </div>
            {errors && errors.cookie && (
              <InputError message={errors.cookie.message} />
            )}
            <Row>
              <Button
                customColor={general.appState.brand.thirdColor}
                className="button validate"
                color="primary"
                type="submit"
                fullWidth
                size="large"
              >
                Valider
              </Button>
            </Row>
          </form>
        </>
      ) : (
        <Message
          className="intent_message"
          intent="SUCCESS"
          message="Cookie Set!"
        />
      )}
    </main>
  );
};

export default Cookie;
