import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
} from '@material-ui/core';
import { Guid } from 'guid-typescript';
import IBAN from 'iban';
import ReactHtmlParser from 'react-html-parser';
import Message from '../../Message';
import browserHistory from '../../../utils/browser_history';
import { SelectProps } from '..';
import { urlParam } from '../../../utils/url';
import LoadingScreen from '../../LoadingScreen';
import CutomInput from '../../Input';
import Checkbox from '../../Checkbox';
import Button from '../../Button';
import MyWindowPortal from './MyWindowPortal';
import {
  PaymentStatus,
  EnumPaymentMethods,
  EnumPaymentServiceProvider,
} from '../../../utils/model';
import { getAPIUrl } from '../../../actions/generalActions';

import './Select.css';
import { BrandType, PaymentMethod } from '../../../reducers/entities/general';
import ComponentSmartFAQ from '../../SmartFAQ/ComponentSmartFAQ';
import { FINISHED_PAGE } from '../../../utils/routes';
import Row from '../../Row/Row';
import PaymentMethodButton from './paymentMethodButton';
import { GeneralTypes } from '../../../actions/types';

interface SelectState {
  isLoading: boolean;
  reason: string | null;
  error: string | null;
  action: string | null;
  fromGoto: string | null;
  formError: string;
  email: string;
  name: string;
  cardNumber: string;
  redirect: boolean;
  showNameModal: boolean;
  showCardnumberModal: boolean;
  showRecurringModal: boolean;
  showTransfertModal: boolean;
  showPendingPayment: boolean;
  isPaypalRenewal: boolean;
  showCheckbox: boolean;
  isChecked: boolean;
  opened: boolean;
  url: string;
  transactionId: string;
  paymentMethods: string[];
  recurringPayment: boolean;
  currentMethod: PaymentMethod;
  paymentAlreadyFailed: boolean;
  userData: any;
  subscriptionScenario: string | null;
  logginToken: string;
  isUserAdded: boolean;
  mollieUrl: string;
  popout: Window | null;
}

class Select extends React.Component<SelectProps, SelectState> {
  constructor(props: any) {
    super(props);
    const { currentUser } = this.props.user;
    const reason = urlParam('reason');
    const error = urlParam('error');
    const action = urlParam('action');
    const fromGoto = urlParam('redirect');

    // this.checkCurrentTransaction();

    this.state = {
      isLoading: true,
      reason,
      formError: '',
      email: currentUser.email,
      name:
        currentUser.firstname !== ''
          ? `${currentUser.firstname} ${currentUser.lastname}`
          : '',
      cardNumber: '',
      action,
      error,
      fromGoto,
      showNameModal: false,
      showRecurringModal: false,
      showTransfertModal: false,
      showCardnumberModal: false,
      showPendingPayment: false,
      isPaypalRenewal: false,
      redirect: true,
      showCheckbox: false,
      isChecked: false,
      opened: false,
      url: `${getAPIUrl()}/Pay/GetIngenicoPage`,
      transactionId: '',
      paymentMethods: [],
      recurringPayment: false,
      currentMethod: {
        id: 0,
        name: 'None',
        icon: '',
        paymentServiceProvidersDTO: {
          id: 0,
          name: 'None',
        },
      },
      paymentAlreadyFailed: false,
      userData: {},
      subscriptionScenario: null,
      logginToken: '',
      isUserAdded: false,
      mollieUrl: '',
      popout: null,
    };
    this.checkValidState = this.checkValidState.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  handleMethodSelection = (item: PaymentMethod) => {
    const { appState } = this.props.general;
    const { recurrentOption } = appState;
    const { isPaypalRenewal } = this.state;
    if (!this.props.user.currentUser.isAuth) {
      this.setState({ reason: 'notauth' });
    } else if (!this.state.isChecked) {
      this.setState({ showCheckbox: true });
    } else {
      this.props.actions.selectPayment(item.name);
      this.setState({ currentMethod: item });

      if (
        recurrentOption === 1 &&
        (item.id === EnumPaymentMethods.CreditCard ||
          (item.id === EnumPaymentMethods.Paypal && isPaypalRenewal) ||
          item.id === EnumPaymentMethods.Amex)
      ) {
        this.setState({ showRecurringModal: true });
      } else {
        this.setState({ showRecurringModal: false });
        this.goForPayment(item);
      }
    }
  };

  async checkValidState() {
    const { gift, productID, hasGift, brand, currentProduct } =
      this.props.general.appState;
    if (productID === null) {
      this.setState({ redirect: true });
      localStorage.removeItem('campaign');
      browserHistory.push('/');
    } else if (
      hasGift === true &&
      (gift === '' || gift === undefined || gift === null)
    ) {
      this.setState({ redirect: true });
      browserHistory.push('/gift');
    } else {
      this.setState({ redirect: false });
    }
    if (currentProduct?.typeDto.name === 'Promo') {
      const campaignCode = localStorage.getItem('campaign');
      if (campaignCode !== null) {
        const campaign = await this.props.actions.isCampaignAvailable(
          campaignCode,
          brand.id
        );
        if (!campaign.isAvailable) {
          this.setState({ redirect: true });
          localStorage.removeItem('campaign');
          browserHistory.push('/');
        }
      }
    }
  }

  async componentDidMount() {
    try {
      const { currentProduct, transaction, brand } =
        this.props.general.appState;
      const localStorageTransactionId = localStorage.getItem('transaction');

      this.checkValidState();
      this.checkScenarioAbo();
      window.addEventListener('beforeunload', this.newWindowUnloaded);
      window.addEventListener('storage', this.handleChangeProduct);

      // CHECKTRANSACTION
      if (transaction != null) {
        const ct = await this.props.actions.checkTransaction(transaction);
        if (ct.payementStatus === PaymentStatus.Succeded) {
          await browserHistory.push(FINISHED_PAGE);
          return;
        }
      } else if (localStorageTransactionId != null) {
        const ct = await this.props.actions.checkTransaction(
          localStorageTransactionId
        );
        if (ct.payementStatus === PaymentStatus.Succeded) {
          await browserHistory.push(FINISHED_PAGE);
          return;
        }
      }
      await this.handleByBrand(currentProduct, brand);
      this.setState({
        showPendingPayment: false,
        isLoading: false,
        isPaypalRenewal: brand.id === 6 || brand.id === 7,
      });

      const { isAuth } = this.props.user.currentUser;
      const fromGoto = urlParam('redirect') === 'true';

      if (!isAuth && fromGoto) {
        this.props.openLogginModal();
      }
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
    }
  }

  handleMolliePayment = async () => {
    const mollieResponse: any = await this.props.actions.createMollie();
    // eslint-disable-next-line no-underscore-dangle
    const hrefMollie: string = mollieResponse._links.checkout?.href;
    this.setState({ url: hrefMollie });
    this.toggleOpened();
  };

  goForPayment = async (item: PaymentMethod) => {
    const user = this.props.user.currentUser;
    const { url, recurringPayment, email, isPaypalRenewal } = this.state;
    const { transaction, productID } = this.props.general.appState;
    if (transaction == null) {
      return;
    }

    switch (item.id) {
      case 1:
      case 2:
      case 3:
      case 7:
        this.setState({
          url: `${url}?tid=${transaction}&pm=${item.id}&ro=${
            item.id === EnumPaymentMethods.CreditCard ||
            (item.id === EnumPaymentMethods.Paypal && isPaypalRenewal) ||
            item.id === EnumPaymentMethods.Amex
              ? recurringPayment
              : false
          }&email=${email}&lastName=${user.lastname}&pid=${productID}`,
        });
        this.toggleOpened();
        break;
      case 4:
        this.setState({ showTransfertModal: true });
        break;
      case 5:
        if (
          item.paymentServiceProvidersDTO.id ===
          EnumPaymentServiceProvider.Mollie
        ) {
          this.handleMolliePayment();
        } else {
          this.setState({ showCardnumberModal: true });
        }
        break;
      case 6:
        await this.handleVoucher(transaction);
        break;
      default:
        this.setState({
          error: 'Votre paiement a échoué, veuillez réessayer.',
        });
    }
  };

  async componentDidUpdate(prevProps: SelectProps, prevState: SelectState) {
    const { email } = this.props.user.currentUser;
    if (
      this.props.user.currentUser.isAuth &&
      prevState.isUserAdded !== this.state.isUserAdded
    ) {
      this.setState({ isLoading: true });
      // if (name !== this.state.name) this.setState({ name });
      if (email !== this.state.email) this.setState({ email });
      if (this.props.general.appState.productID !== null) {
        if (this.props.general.appState.editionID !== null) {
          try {
            await this.handleIntent(
              this.props.general.appState.productID,
              this.props.general.appState.editionID
            );
          } catch (error: any) {
            this.setState({ error: error.message });
          }
        } else {
          try {
            await this.handleIntent(this.props.general.appState.productID);
          } catch (error: any) {
            this.setState({ error: error.message });
          }
        }
      }
      this.setState({ isLoading: false });
      this.checkScenarioAbo();
    }
  }

  componentWillUnmount() {
    const popup = this.state.popout;
    if (popup != null) {
      popup.close();
    }
    window.removeEventListener('beforeunload', this.newWindowUnloaded);
    window.removeEventListener('storage', this.handleChangeProduct);
  }

  handleDestroyPopup = () => {
    const popup = this.state.popout;
    if (popup != null) {
      popup.close();
    }
    window.removeEventListener('beforeunload', this.newWindowUnloaded);
    window.removeEventListener('storage', this.handleChangeProduct);
  };

  handleChangeProduct = (se: StorageEvent) => {
    const { productID } = this.props.general.appState;
    if (
      se.isTrusted &&
      se.key === 'productID' &&
      se.newValue !== productID?.toString()
    ) {
      this.handleDestroyPopup();
      this.props.dispatch({
        type: GeneralTypes.RESET_APP_STATE,
      });
      browserHistory.push('/');
    }
  };

  handleIntent = async (productID: number, editionId?: number) => {
    const { intent, recurrentOption, promocode, gift, brand, campaign } =
      this.props.general.appState;
    const { isAuth } = this.props.user.currentUser;

    const { createTransaction, updateOrder, createOrder } = this.props.actions;
    if (isAuth) {
      if (intent === null) {
        const resp: any = await createOrder({
          productId: productID,
          resetThirdParty: true,
          editionId:
            editionId !== null && brand.hasEdition ? editionId : undefined,
          promoCode: promocode,
          campaignCode: campaign,
          gift,
        });
        await createTransaction({
          orderId: resp.id,
        });
      } else {
        const resp = await updateOrder({
          id: Guid.parse(intent).toString(),
          resetThirdParty: false,
          productId: productID,
          editionId:
            editionId != null && brand.hasEdition ? editionId : undefined,
          promoCode: promocode,
          campaignCode: campaign,
          gift,
        });
        await createTransaction({
          orderId: resp.id,
        });
      }
      if (recurrentOption === 2) {
        this.setState({ recurringPayment: true });
      }
    }
  };

  handleByBrand = async (currentProduct: any, brand: BrandType) => {
    const productId =
      currentProduct != null
        ? currentProduct.id
        : this.props.general.appState.productID;
    if (!brand.hasEdition) {
      await this.handleIntent(productId);
    } else {
      const tempEditionId = this.props.general.appState.editionID;
      const editionId =
        tempEditionId != null
          ? tempEditionId
          : await this.props.actions.getEdition();
      await this.handleIntent(productId, editionId);
    }
  };

  checkScenarioAbo = async () => {
    const { productID } = this.props.general.appState;
    if (productID != null && this.props.user.currentUser.isAuth) {
      const accessList = await this.props.actions.checkUserAccessList(
        productID
      );
      if (accessList != null) {
        if (
          accessList.productResourceId != null &&
          accessList.userResourceId != null
        ) {
          if (
            accessList.productResourceId === accessList.userResourceId &&
            accessList.userResourceExpireDate != null
          ) {
            if (
              moment(new Date()).add(30, 'days').date <=
              moment(accessList.userResourceExpireDate).date
            ) {
              this.setState({
                subscriptionScenario:
                  "Votre adresse mail est déjà liée à un abonnement actif à une de nos formules. En commandant un nouvel abonnement, vous risquez de supprimer vos accès actuels. Pour plus d'informations, contactez notre service clientèle.",
              });
            }
          }
        }
      } else {
        this.setState({
          subscriptionScenario: null,
        });
      }
    }
  };

  handleRecurrentChange = () => {
    const { recurrentOption } = this.props.general.appState;
    if (recurrentOption === 1 && this.state.currentMethod.id === 1) {
      const { showRecurringModal } = this.state;
      this.setState({ showRecurringModal: !showRecurringModal });
    }
  };

  testPaymentMethodMoneyTransfer = async (transactionId: string) => {
    try {
      const test = await this.props.actions.checkTransaction(transactionId);

      if (test.payementStatus === PaymentStatus.Succeded) {
        await browserHistory.push(FINISHED_PAGE);
      } else if (test.payementStatus === PaymentStatus.Initialized) {
        this.setState({
          error: 'Votre paiement a échoué, veuillez réessayer.',
          isLoading: false,
        });
        return;
      } else if (test.payementStatus === PaymentStatus.Failed) {
        this.setState({
          error: 'Votre paiement a échoué, veuillez réessayer.',
          isLoading: false,
        });
        return;
      } else if (test.payementStatus === PaymentStatus.Refunded) {
        this.setState({
          error:
            'Votre paiement a échoué, nous vous avons remboursé, veuillez réessayer.',
          isLoading: false,
        });
        return;
      }
    } catch (error: any) {
      this.setState({ isLoading: false, error: error.message });
    }
  };

  handleMoneyTransferPayment = async (type: string, transactionId: string) => {
    this.setState({ isLoading: true });
    try {
      const { transaction } = this.props.general.appState;
      if (transaction !== null) {
        if (type === 'transfer') {
          await this.props.actions.confirmVirement(transactionId);
        } else {
          if (!IBAN.isValid(this.state.cardNumber)) {
            this.setState({
              formError: 'Numéro de compte invalide',
              isLoading: false,
            });
            return;
          }
          await this.props.actions.confirmDomiciliation(
            this.state.cardNumber,
            transactionId
          );
        }
        setTimeout(
          () => this.testPaymentMethodMoneyTransfer(transaction),
          1000
        );
      }
    } catch (error: any) {
      this.setState({
        error: 'Votre paiement a échoué, veuillez réessayer.',
        formError: error.message,
        isLoading: false,
      });
    }
  };

  handleVoucher = async (transactionId: string) => {
    try {
      await this.props.actions.confirmVoucher(transactionId);
      await browserHistory.push(FINISHED_PAGE);
    } catch (error: any) {
      this.setState({
        error: 'Votre commande à échoué, veuillez réessayer.',
        formError: error.message,
        isLoading: false,
      });
    }
  };

  setRecurringPayment = (item: any) => {
    this.setState({ showRecurringModal: false });
    this.setState({ recurringPayment: true }, () => this.goForPayment(item));
  };

  setNoRecurringPayment = (item: any) => {
    this.setState({ showRecurringModal: false });
    this.setState({ recurringPayment: false }, () => this.goForPayment(item));
  };

  handleInputChange = (value: string, type: string) => {
    if (type === 'email') this.setState({ email: value });
    if (type === 'cardNumber') this.setState({ cardNumber: value });
  };

  onKeyDown = (
    id: any,
    event: React.KeyboardEvent<HTMLButtonElement>
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.handleMethodSelection(id);
    }
  };

  onCheckboxChange = () => {
    this.setState((prevState) => ({ isChecked: !prevState.isChecked }));
  };

  onCheckboxKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isChecked: true });
    }
  };

  toggleOpened() {
    const { isChecked } = this.state;
    if (!isChecked) {
      this.setState({ showCheckbox: true });
    } else {
      const { transaction } = this.props.general.appState;
      if (transaction !== null)
        this.setState({
          transactionId: transaction,
          opened: true,
          showPendingPayment: true,
        });
    }
  }

  async newWindowUnloaded() {
    this.setState({
      opened: false,
      url: `${getAPIUrl()}/Pay/GetIngenicoPage`,
    });
    const { transaction, currentProduct, brand } = this.props.general.appState;
    this.setState({ showPendingPayment: false });
    // CHECKTRANSACTION
    let ct;
    if (transaction != null) {
      try {
        ct = await this.props.actions.checkTransaction(transaction);
        if (ct.payementStatus === PaymentStatus.Succeded) {
          // localStorage.setItem('transactionID', transaction);
          await browserHistory.push(FINISHED_PAGE);
        } else if (ct.payementStatus === PaymentStatus.Initialized) {
          this.setState({
            error: 'Votre paiement a échoué, veuillez réessayer.',
          });
          await this.handleByBrand(currentProduct, brand);
        } else if (ct.payementStatus === PaymentStatus.Pending) {
          if (this.state.paymentAlreadyFailed) {
            this.setState({ paymentAlreadyFailed: true });
            this.newWindowUnloaded();
          } else {
            this.setState({
              error: 'Votre paiement a échoué, veuillez réessayer.',
            });
            await this.handleByBrand(currentProduct, brand);
          }
        } else if (ct.payementStatus === PaymentStatus.Failed) {
          this.setState({
            error: 'Votre paiement a échoué, veuillez réessayer.',
          });
          await this.handleByBrand(currentProduct, brand);
        } else if (ct.payementStatus === PaymentStatus.Refunded) {
          this.setState({
            error:
              'Votre paiement a échoué, nous vous avons remboursé, veuillez réessayer.',
          });
          await this.handleByBrand(currentProduct, brand);
        } else {
          this.setState({
            error: 'Il y a eu un problème, veuillez réessayer.',
          });
          await this.handleByBrand(currentProduct, brand);
        }
      } catch (error: any) {
        this.setState({
          error: 'Il y a eu un problème, veuillez réessayer.',
        });
        await this.handleByBrand(currentProduct, brand);
      }
    } else {
      this.setState({
        error: 'Il y a eu un problème, veuillez réessayer.',
      });
      await this.handleByBrand(currentProduct, brand);
    }
  }

  render() {
    const {
      cardNumber,
      action,
      reason,
      error,
      redirect,
      fromGoto,
      isLoading,
      showCardnumberModal,
      showRecurringModal,
      showTransfertModal,
      showPendingPayment,
      showCheckbox,
      isChecked,
      opened,
      formError,
      url,
      currentMethod,
      subscriptionScenario,
    } = this.state;
    const {
      productID,
      editionID,
      orderState,
      paymentMethods,
      currentProduct,
      brand,
      transaction,
    } = this.props.general.appState;
    const user = this.props.user.currentUser;
    if (redirect) {
      return null;
    }
    if (isLoading) {
      return <LoadingScreen customColor={brand.thirdColor} size={60} />;
    }
    if (orderState === 'PAID') {
      return <Redirect to="/delivery" />;
    }
    let editionDto;
    if (currentProduct !== null) {
      editionDto = currentProduct.editionDto.find((list: any) => {
        if (editionID !== null) {
          if (list.id === editionID) {
            return list;
          }
        }
        return null;
      });
    }
    if (
      productID === null ||
      (brand.hasEdition &&
        editionID === null &&
        orderState !== 'EDITION_SELECTED' &&
        editionDto === null) ||
      currentProduct === null
    ) {
      return <Redirect to="/" />;
    }

    if (
      (editionDto === null && brand.hasEdition) ||
      (editionDto === undefined && brand.hasEdition)
    ) {
      return <Redirect to="/edition" />;
    }

    return (
      <div className="step_formule_wrapper">
        {action === 'new_intent' && (
          <Message
            className="intent_message"
            intent="WARNING"
            message="new intent error"
          />
        )}
        {reason === 'notauth' && !user.isAuth && (
          <Message
            className="intent_message"
            intent="WARNING"
            message="Veuillez-vous connecter pour finaliser votre commande."
          />
        )}
        {error !== null && (
          <Message
            intent="WARNING"
            message={error}
            className="intent_message justify-center"
          />
        )}
        <h2 className="font-black text-base text-center mx-auto">
          Veuillez sélectionner votre moyen de paiement.
        </h2>
        <FormControlLabel
          className="conditions flex justify-center w-full"
          control={
            <Checkbox
              color={this.props.general.appState.brand.thirdColor}
              required
              checked={isChecked}
              value="conditions"
              onKeyDown={(e: any) => this.onCheckboxKeyDown(e)}
              onChange={() => this.onCheckboxChange()}
            />
          }
          label={
            <span>
              J&apos;accepte les{' '}
              <a
                rel="noopener noreferrer"
                href={brand.cdvLink}
                target="_blank"
                className="cgu"
              >
                conditions de vente
              </a>
            </span>
          }
        />
        {showCheckbox && !isChecked && (
          <Message
            className="intent_message"
            intent="WARNING"
            message="Veuillez accepter les conditions de vente pour continuer."
          />
        )}

        {subscriptionScenario && (
          <Message
            className="intent_message sub"
            intent="WARNING"
            message={subscriptionScenario}
          />
        )}

        <div className="formule_wrapper payment">
          {paymentMethods
            .filter((pm: any) => pm.id === EnumPaymentMethods.Domiciliation)
            .map((pm: any) => (
              <PaymentMethodButton
                paymentMethod={pm}
                withMainBorder
                onKeyDown={this.onKeyDown}
                handleMethodSelection={this.handleMethodSelection}
              />
            ))}
          {paymentMethods
            .filter((pm: any) => pm.id !== EnumPaymentMethods.Domiciliation)
            .map((pm: any) => (
              <PaymentMethodButton
                paymentMethod={pm}
                onKeyDown={this.onKeyDown}
                handleMethodSelection={this.handleMethodSelection}
              />
            ))}
          {currentProduct?.productInfos && (
            <Row className="product_info">
              {ReactHtmlParser(currentProduct.productInfos)}
            </Row>
          )}
          {opened && (
            <MyWindowPortal
              onUnload={() => this.newWindowUnloaded()}
              onCreatePopout={(e: any) => {
                this.setState({ popout: e });
              }}
              url={`${url}`}
              window={window}
            />
          )}
        </div>
        {fromGoto !== null && (
          <div>
            <Link to="/">Voir nos autres offres</Link>
          </div>
        )}
        <Dialog
          open={showTransfertModal}
          id="modal"
          className={`bancontact_infos_modal ${brand.rootClassName}`}
          onClose={this.handleRecurrentChange}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="offer-dialog-title">Confirmation</DialogTitle>
          <DialogContent>
            <p>Veuillez confirmer votre paiement par virement</p>
          </DialogContent>
          <DialogActions>
            <Button
              customColor={this.props.general.appState.brand.thirdColor}
              fullWidth={false}
              size="small"
              onClick={() =>
                transaction != null &&
                this.handleMoneyTransferPayment('transfer', transaction)
              }
              color="primary"
            >
              Confirmer
            </Button>
            <Button
              customColor={this.props.general.appState.brand.thirdColor}
              fullWidth={false}
              size="small"
              onClick={() => this.setState({ showTransfertModal: false })}
              color="primary"
            >
              Annuler
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showRecurringModal}
          id="modal"
          className={`bancontact_infos_modal ${brand.rootClassName}`}
          onClose={this.handleRecurrentChange}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="offer-dialog-title">
            Désirez-vous opter pour un paiement récurrent ?
          </DialogTitle>
          <DialogContent>
            <p>Le montant sera débité automatiquement.</p>
          </DialogContent>
          <DialogActions>
            <Button
              customColor={this.props.general.appState.brand.thirdColor}
              fullWidth={false}
              size="small"
              onClick={() => this.setRecurringPayment(currentMethod)}
              color="primary"
            >
              Oui
            </Button>
            <Button
              customColor={this.props.general.appState.brand.thirdColor}
              fullWidth={false}
              size="small"
              onClick={() => this.setNoRecurringPayment(currentMethod)}
              color="primary"
            >
              Non
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showCardnumberModal}
          id="modal"
          className={`bancontact_infos_modal ${brand.rootClassName}`}
          onClose={() => this.setState({ showCardnumberModal: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="offer-dialog-title">
            Votre numéro de compte:
          </DialogTitle>
          <DialogContent>
            {formError !== '' && (
              <Message
                className="bancontact_infos_message"
                intent="DANGER"
                message={formError}
              />
            )}
            <CutomInput
              onChange={(e) =>
                this.handleInputChange(e.target.value, 'cardNumber')
              }
              type="text"
              label="Numéro de compte"
              fullWidth
              value={cardNumber}
            />
          </DialogContent>
          <DialogActions>
            <Button
              customColor={this.props.general.appState.brand.thirdColor}
              fullWidth
              size="small"
              onClick={() =>
                transaction != null &&
                this.handleMoneyTransferPayment('dom', transaction)
              }
              color="primary"
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showPendingPayment}
          id="modal"
          className={`bancontact_infos_modal ${brand.rootClassName}`}
          aria-labelledby="form-dialog-title"
        >
          <LoadingScreen customColor={brand.thirdColor} size={60} />
          <DialogContent>
            <p className="text-center waiting-payment">
              En attente de votre paiement, ne pas fermer cette page.
              <br />
              Pour finaliser votre commande, veuillez procéder à la transaction
              sécurisée dans la fenêtre de paiement.
              <br /> <br />
              {/* <b style={{ fontWeight: 'bold' }}>
                Si la fenêtre de paiement n&lsquo;apparait pas, vérifiez que
                votre navigateur accepte l&lsquo;ouverture des pop-ups.
              </b> */}
              {this.state.popout != null &&
                this.state.popout.window == null && (
                  <span style={{ fontWeight: 'bold' }}>
                    Si vous n&lsquo;êtes pas redirigé vers la page de paiement,
                    <a href={url} target="_blank" rel="noreferrer">
                      cliquez ici
                    </a>
                  </span>
                )}
            </p>
          </DialogContent>
        </Dialog>
        {brand.smartFAQ && (
          <ComponentSmartFAQ
            type="push"
            kbId={290}
            analyticsUA={process.env.REACT_APP_GA ?? ''}
            cookieOptin={false}
            limitDisplay={5}
            singleQuestionOpen={false}
            categorie={['']}
            tags={[brand.smartFAQTags]}
          />
        )}
      </div>
    );
  }
}

export default Select;
