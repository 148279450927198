import React from 'react';
import { ReactComponent as Secure } from '../../images/secure.svg';

import './secureSpace.css';

interface ISecureSpaceProps {
  className?: string;
  centered?: boolean;
}

const SecureSpace = ({
  className = '',
  centered = false,
}: ISecureSpaceProps) => (
  <div
    className={`secureSpace ${className} ${
      centered ? 'justify-center' : 'justify-end'
    }`}
  >
    <Secure className="secureSpace-icon" />
    <p className="secureSpace-text">Vous êtes dans un espace 100% sécurisé</p>
  </div>
);

export default SecureSpace;
