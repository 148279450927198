import {
  ABORENEWAL_PAGE,
  BILLING_ADDRESS_PAGE,
  CONTACT_DATA_PAGE,
  DELIVERY_ADDRESS_PAGE,
  DURATION_PAGE,
  EDITION_PAGE,
  FINISHED_PAGE,
  GIFT_PAGE,
  HOME_PAGE,
  PAY_PAGE,
  PAY_RENEWAL_PAGE,
  SHORT_FORM_PAGE,
  TEMPORARY_OFFERS_PAGE,
  USER_LOGIN_PAGE,
  VOUCHER_PAGE,
} from './routes';
import { ProductType } from '../reducers/entities/general';

declare global {
  interface Window {
    dataLayer: any[];
    SWG: any;
    cache: Record<string, any>;
  }
}

export const getEdition = (
  product: ProductType | null,
  editionID: number | null
): string => {
  const currentEdition = Object.values(product?.editionDto || {}).find(
    ({ id }) => id === editionID
  );

  return currentEdition?.name || 'UNKNOWN';
};

export interface IDataLayerCommerceItem {
  item_name: string;
  item_category: string;
  item_category1: string;
  item_category2?: string;
  item_category3?: string;
  coupon?: string;
  quantity: string;
  price?: string;
}

export interface IDataLayerItem {
  event: string;
  eventName?: string;
  userId?: string;
  click_type?: string;
  page?: {
    name: string;
  };
  subscription?: {
    selectedProduct?: string;
    flowtype?: string;
    offer?: string;
    duration?: number;
    price?: number;
    temporaryOffer?: string;
    edition?: string;
    deliveryType?: string;
    paymentType?: any;
    transactionId?: any;
    swgType?: string;
  };
  ecommerce?: {
    currency: 'EUR';
    id?: string;
    items: IDataLayerCommerceItem[];
  };
}

export enum FlyingFishEvents {
  PAGE_VIEW = 'page_view',
  INTERACTION = 'abo-interaction',
  ADD_TO_CART = 'add_to_cart',
  CHECKOUT = 'checkout',
  PURCHASE = 'purchase',
}

export enum FlyingFishClick {
  OFFER_SELECT = 'offer_select',
  DURATION_SELECT = 'duration_select',
  ADD_TO_CART = 'add_to_cart',
  EDITION_SELECT = 'edition_select',
  LOGIN = 'login',
  ACCOUNT_CREATE = 'account_create',
  DELIVERY_SELECT = 'delivery_select',
  PAYMENT_TYPE_SELECT = 'payment_type_select',
  CHECKOUT = 'checkout',
  PAYMENT_VALIDATE = 'payment_validate',
  PURCHASE = 'purchase',
  TEMPORARY_OFFER_SELECT = 'temporary_offer_select',
  CLICK_SWG_BUTTON = 'swg_button_click',
  PAYMENT_SWG = 'swg_payment',
}

export enum FlyingFishLevel {
  PAGE,
  RENEWALPAGE,
  VOUCHERPAGE,
  DURATION,
  GIFT,
  EDITION,
  FORM,
  FULL_FORM,
  FINISHED,
}

export const FlyingFishPages: { [key: string]: string } = {
  [HOME_PAGE]: '/abo/homepage',
  [DURATION_PAGE]: '/abo/duration',
  [EDITION_PAGE]: '/abo/edition',
  [VOUCHER_PAGE]: '/abo/voucher',
  [ABORENEWAL_PAGE]: '/abo/aborenewal',
  [USER_LOGIN_PAGE]: '/abo/login',
  [SHORT_FORM_PAGE]: '/abo/phone-number',
  [BILLING_ADDRESS_PAGE]: '/abo/userinfo',
  [CONTACT_DATA_PAGE]: '/abo/delivery',
  [DELIVERY_ADDRESS_PAGE]: '/abo/userinfo',
  [PAY_PAGE]: '/abo/pay',
  [GIFT_PAGE]: '/abo/gift',
  [PAY_RENEWAL_PAGE]: '/abo/payrenewal',
  [FINISHED_PAGE]: '/abo/finished',
  [TEMPORARY_OFFERS_PAGE]: '/abo/temporary-offers',
};

export const FlyingFishBasePage = (url: string, userId: string) => ({
  event: FlyingFishEvents.PAGE_VIEW,
  page: {
    name: FlyingFishPages[url],
  },
  userId,
});

export const flyingFishEvents: Record<FlyingFishClick, Function> = {
  [FlyingFishClick.OFFER_SELECT]: (product: string): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.OFFER_SELECT,
    subscription: {
      selectedProduct: product,
    },
  }),
  [FlyingFishClick.TEMPORARY_OFFER_SELECT]: (
    temporaryOffer: string
  ): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.TEMPORARY_OFFER_SELECT,
    subscription: {
      selectedProduct: temporaryOffer,
    },
  }),
  [FlyingFishClick.DURATION_SELECT]: (duration: string): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.DURATION_SELECT,
    subscription: {
      selectedProduct: duration,
    },
  }),
  [FlyingFishClick.EDITION_SELECT]: (edition: string): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.EDITION_SELECT,
    subscription: {
      selectedProduct: edition,
    },
  }),
  [FlyingFishClick.DELIVERY_SELECT]: (delivery: string): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.DELIVERY_SELECT,
    subscription: {
      selectedProduct: delivery,
    },
  }),
  [FlyingFishClick.PAYMENT_TYPE_SELECT]: (
    paymentType: string
  ): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.PAYMENT_TYPE_SELECT,
    subscription: {
      selectedProduct: paymentType,
    },
  }),
  [FlyingFishClick.ADD_TO_CART]: (
    item: IDataLayerCommerceItem
  ): IDataLayerItem => ({
    event: FlyingFishClick.ADD_TO_CART,
    ecommerce: {
      currency: 'EUR',
      items: [item],
    },
  }),
  [FlyingFishClick.LOGIN]: (userId: string): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.LOGIN,
    userId,
  }),
  [FlyingFishClick.ACCOUNT_CREATE]: (userId: string): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.ACCOUNT_CREATE,
    userId,
  }),
  [FlyingFishClick.PAYMENT_VALIDATE]: (
    selectedProduct: string,
    transactionId: string
  ): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.PAYMENT_VALIDATE,
    subscription: {
      selectedProduct,
      transactionId,
    },
  }),
  [FlyingFishClick.CHECKOUT]: (
    item: IDataLayerCommerceItem
  ): IDataLayerItem => ({
    event: FlyingFishEvents.CHECKOUT,
    ecommerce: {
      currency: 'EUR',
      items: [item],
    },
  }),
  [FlyingFishClick.PURCHASE]: (
    transactionId: string,
    item: IDataLayerCommerceItem
  ): IDataLayerItem => ({
    event: FlyingFishEvents.PURCHASE,
    ecommerce: {
      currency: 'EUR',
      id: transactionId,
      items: [item],
    },
  }),
  [FlyingFishClick.CLICK_SWG_BUTTON]: (): IDataLayerItem => ({
    event: FlyingFishEvents.INTERACTION,
    eventName: FlyingFishClick.CLICK_SWG_BUTTON,
  }),
  [FlyingFishClick.PAYMENT_SWG]: (): IDataLayerItem => ({
    event: FlyingFishEvents.PURCHASE,
    ecommerce: {
      currency: 'EUR',
      items: [
        {
          item_name: 'digital',
          item_category: 'standard',
          item_category1: '1',
          item_category3: 'google',
          quantity: '1',
        },
      ],
    },
  }),
};

export const trackEvent = (event: IDataLayerItem) => {
  if (window.cache.canTrack) {
    window.dataLayer.push(event);
  }
};
