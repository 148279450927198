import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Gift from './Gift';
import { getGifts, selectGift, setNoGift } from '../../actions/views/gift';
import { deleteIntent } from '../../actions/views/pay';
import { log } from '../../actions/generalActions';
import { RootState } from '../../reducers';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  const { router } = state;
  return {
    user,
    general,
    currentPath: router.location,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      selectGift,
      getGifts,
      setNoGift,
      deleteIntent,
      log,
    },
    dispatch
  );

export type GiftProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Gift);
