import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './Footer.css';
import PaymentMethodFooter from '../PaymentMethodFooter';
import helpIcon from '../../images/helpwsmall.jpg';
import { BrandType } from '../../reducers/entities/general';
import { getCurrentBrand, MAIN_BRANDS_NAME } from '../../utils/brands';
import { VOUCHER_PAGE } from '../../utils/routes';

interface IProps {
  brand: BrandType;
  location: string;
}

function Footer({ brand }: IProps) {
  const isHomePage = window.location.pathname === '/';

  return (
    <div className="app_bottom_wrapper">
      <div>
        <div className="contact-us">
          <div className="contact-us-inner">
            <div className="icon-block">
              <img
                src={helpIcon}
                alt="illustration"
                className="rounded-full object-fill"
              />
            </div>
            <div className="text-block">
              <p className="title">Besoin d’aide ?</p>
              {ReactHtmlParser(brand.homeNeedHelp)}
              {MAIN_BRANDS_NAME.includes(brand.name) && (
                <a
                  href={`${getCurrentBrand(brand.name).uri}/page/faq/`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Questions fréquentes
                </a>
              )}
            </div>
          </div>
        </div>
        {isHomePage && (
          <div className="voucher_button">
            <a href={VOUCHER_PAGE}>Je possède un code promotionnel</a>
          </div>
        )}
      </div>
      {isHomePage ? (
        <PaymentMethodFooter brandName={brand.name} />
      ) : (
        <div className="in-common baseline">
          <img
            alt="logo"
            src={`${process.env.REACT_APP_CDN_URL}/baseline/${brand?.baseline}`}
          />
          {/* {ReactHtmlParser(brand?.baseline || 'Baseline')} */}
        </div>
      )}
      <div className="subTitle">
        <p>{brand.homeSubTitle}</p>
      </div>
    </div>
  );
}

export default Footer;
