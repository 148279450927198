import React from 'react';
import ReactDOM from 'react-dom';

export interface MyWindowPortalProps {
  url: string;
  onUnload: Function;
  onCreatePopout: Function;
  window: Window & typeof globalThis;
}

interface MyWindowPortalState {
  popoutWindow: Window | null;
  container: HTMLDivElement | null;
  containerId: string | null;
  windowCheckerInterval: Function | null;
}

const ABOUT_BLANK = 'about:blank';

class MyWindowPortal extends React.Component<
  React.PropsWithChildren<MyWindowPortalProps>,
  MyWindowPortalState
> {
  windowCheckerInterval: any;

  constructor(props: any) {
    super(props);
    this.state = {
      popoutWindow: null,
      container: document.createElement('div'),
      containerId: null,
      windowCheckerInterval: null,
    };

    // STEP 1: create a container <div>
    this.popoutWindowUnloading = this.popoutWindowUnloading.bind(this);
    this.popoutWindowLoaded = this.popoutWindowLoaded.bind(this);
    this.windowCheckerInterval = null;
  }

  componentDidMount() {
    const ownerWindow = this.state.popoutWindow || window;

    // May not exist if server-side rendering
    if (ownerWindow) {
      this.openPopoutWindow(ownerWindow);
    }
  }

  openPopoutWindow(ownerWindow: Window) {
    const w = 1000;
    const h = 700;
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      window.screen.width;

    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const popoutWindow = ownerWindow.open(
      this.props.url,
      'popup',
      `width=${w / systemZoom},height=${
        h / systemZoom
      },top=${top},left=${left},scrollbars=yes,toolbar=no,location=no,menubar=no`
    );
    if (!popoutWindow) {
      return;
    }
    this.setState({ popoutWindow });
    this.props.onCreatePopout(popoutWindow);
    this.windowCheckerInterval = setInterval(() => {
      const { popoutWindow: finalPopoutWindow } = this.state;
      if (finalPopoutWindow == null) return;
      if (!finalPopoutWindow || finalPopoutWindow.closed) {
        this.popoutWindowUnloading();
      }
    }, 50);

    // If they have no specified a URL, then we need to forcefully call popoutWindowLoaded()
    if (this.props.url === ABOUT_BLANK) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      popoutWindow.document.readyState === 'complete' &&
        this.popoutWindowLoaded();
    }
  }

  // todo @Aly is that ready to be deleted ?
  // createOptions(ownerWindow: any) {}

  popoutWindowLoaded() {
    if (!this.state.container) {
      // Popout window is passed from openPopoutWindow if no url is specified.
      // In this case this.state.popoutWindow will not yet be set, so use the argument.
      const popWindow = this.state.popoutWindow;
      if (popWindow == null) return;
      const container = popWindow.document.createElement('div');
      if (this.state.containerId != null) container.id = this.state.containerId;
      popWindow.document.body.appendChild(container);

      this.setState({ container });
      this.renderToContainer(container, popWindow, this.props.children);
    }
  }

  popoutWindowUnloading() {
    clearInterval(this.windowCheckerInterval);
    if (this.props.onUnload) {
      this.props.onUnload();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderToContainer(
    container: HTMLDivElement,
    popoutWindow: Window,
    children: any
  ) {
    // For SSR we might get updated but there will be no container.
    if (container) {
      let renderedComponent = children;
      if (typeof children === 'function') {
        renderedComponent = children(popoutWindow);
      }
      ReactDOM.render(renderedComponent, container);
    }
  }

  render() {
    return this.state.container !== null
      ? ReactDOM.createPortal(this.props.children, this.state.container)
      : null;
  }
}

export default MyWindowPortal;
