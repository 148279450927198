import React, { useEffect } from 'react';
import SMARTFAQ_TYPES from './SmartFAQTypes';
import config from './config';
import AddExternalScript from '../../utils/addExternalScript';
import getFaq from './Params';

const { FAQ_STANDALONE, FAQ_INPUT, FAQ_SELECT, FAQ_CONTEXT } =
  SMARTFAQ_TYPES || {};

/**
 * @description Smart FAQ Content
 */
function SmartFAQ({
  type,
  kbId,
  analyticsUA,
  cookieOptin,
  limitDisplay,
  singleQuestionOpen,
  categories,
  tags,
}: any) {
  const {
    [type]: {
      src = { prod: '', preprod: '' },
      eventListener = '',
      htmlContent = '',
    } = {},
  } = config || {};
  let containerId = '';

  switch (type) {
    case FAQ_STANDALONE:
      containerId = 'st-search-standalone';
      break;
    case FAQ_CONTEXT:
      containerId = 'st-faq-context';
      break;
    case FAQ_SELECT:
      containerId = 'st-contact-form-select-container';
      break;
    case FAQ_INPUT:
      containerId = 'st-contact-form-input-container';
      break;
    default:
      break;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = {
    item: {
      type,
      kbId,
      analyticsUA,
      cookieOptin,
      limitDisplay,
      singleQuestionOpen,
      containerId,
    },
    searchConfig: {
      categories,
      tags,
    },
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const srcEnv =
        process.env.NODE_ENV !== 'development'
          ? src?.prod || src?.preprod || ''
          : src?.preprod || '';
      if (srcEnv && type) {
        AddExternalScript({ src: srcEnv });

        window.addEventListener(eventListener, (e) => {
          getFaq({ e, data });
        });
      }
    }
  }, [data, eventListener, src, type]);

  return <div className="ap-SmartFAQ">{htmlContent}</div>;
}

export default SmartFAQ;
