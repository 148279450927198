import React from 'react';
import Grid from '@material-ui/core/Grid';
import { PromotionProps } from '.';
import LoadingScreen from '../LoadingScreen';
import Message from '../Message';
import './promotion.css';

interface PromotionState {
  isDialogOpen: boolean;
  dialogIndex: number | null;
  isLoading: boolean;
  channels: any;
  error: string;
  warning: string | null;
  isMobile: boolean;
  open: boolean;
  selectedOffer: number | null;
}

class Promotion extends React.Component<PromotionProps, PromotionState> {
  constructor(props: PromotionProps) {
    super(props);
    this.state = {
      isDialogOpen: false,
      dialogIndex: null,
      error: '',
      warning: null,
      isLoading: true,
      channels: [],
      isMobile: window.innerWidth < 1120,
      open: false,
      selectedOffer: null,
    };
  }

  async componentDidMount() {
    try {
      this.setState({
        isLoading: false,
      });
    } catch (error: any) {
      this.setState({ error: error.message, isLoading: false });
    }
  }

  render() {
    const { isLoading, error, warning } = this.state;
    const { brand } = this.props.general.appState;

    if (warning) this.setState({ error, isLoading: false });
    if (error !== '') {
      return (
        <Message className="intent_message" intent="DANGER" message={error} />
      );
    }
    if (!isLoading) {
      return (
        <Grid className="app_sub_wrapper home promotion" container spacing={0}>
          <Grid className="title_wrapper" container spacing={0}>
            <h1 className={`title ${brand.rootClassName}`}>Nos promotions</h1>
          </Grid>
          {/* <Promoboxv2 brand={brand.name} customColor={brand.thirdColor}/> */}
        </Grid>
      );
    }
    return <LoadingScreen customColor={brand.thirdColor} size={60} />;
  }
}

export default Promotion;
