// GeneralTypes
export const GeneralTypes = {
  RESET: 'RESET',
  SELECT_OFFER: 'SELECT_OFFER',
  SELECT_DURATION: 'SELECT_DURATION',
  SELECT_EDITION: 'SELECT_EDITION',
  SET_HAS_GIFT: 'SET_HAS_GIFT',
  SELECT_GIFT: 'SELECT_GIFT',
  REMOVE_GIFT: 'REMOVE_GIFT',
  SET_BRAND: 'SET_BRAND',
  SELECT_PRODUCT: 'SELECT_PRODUCT',
  SELECT_PAYMENT_TYPE: 'SELECT_PAYMENT_TYPE',
  SET_PRODUCT: 'SET_PRODUCT',
  SET_PRODUCT_DURATION: 'SET_PRODUCT_DURATION',
  SET_RENEWAL: 'SET_RENEWAL',
  SET_CLASSIC_FLOW: 'SET_CLASSIC_FLOW',
  SET_VOUCHER: 'SET_VOUCHER',
  RECEIVED_APP_STATE: 'RECEIVED_APP_STATE',
  LOG_ACTION: 'LOG_ACTION',
  PAY_CREDIT: 'PAY_CREDIT',
  GET_CLIENT_SECRET: 'GET_CLIENT_SECRET',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_TRANSACTION_SUCCESS: 'CREATE_TRANSACTION_SUCCESS',
  CREATE_INTENT_FAILURE: 'CREATE_INTENT_FAILURE',
  UPDATE_INTENT_SUCCESS: 'UPDATE_INTENT_SUCCESS',
  UPDATE_INTENT_FAILURE: 'UPDATE_INTENT_FAILURE',
  DELETE_INTENT_SUCCESS: 'DELETE_INTENT_SUCCESS',
  RESET_AFTER_PAYMENT_SUCCESS: 'RESET_AFTER_PAYMENT_SUCCESS',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  DELIVERY_SUCCESS: 'DELIVERY_SUCCESS',
  DELIVERY_FAILURE: 'DELIVERY_FAILURE',
  TRACK_EVENT: 'TRACK_EVENT',
  SEND_SC_EMAIL_REQUEST: 'SEND_SC_EMAIL_REQUEST',
  SEND_SC_EMAIL_SUCCESS: 'SEND_SC_EMAIL_SUCCESS',
  RESET_APP_STATE: 'RESET_APP_STATE',
  GET_APP_ACTIONS: 'GET_APP_ACTIONS',
  SET_UTM_DATA: 'SET_UTM_DATA',
  SET_CAMPAIGN: 'SET_CAMPAIGN',
  SET_REDIRECT_URL: 'SET_REDIRECT_URL',
  SET_DELIVERY_OPTION: 'SET_DELIVERY_OPTION',
  SWG_INITIALIZED: 'SWG_INITIALIZED',
  PIANO_INITIALIZED: 'PIANO_INITIALIZED',
  DIDOMI_INITIALIZED: 'DIDOMI_INITIALIZED',
  GTM_INITIALIZED: 'GTM_INITIALIZED',
  SWG_SUCCESS: 'SWG_SUCCESS',
  SWG_CHANNEL: 'SWG_CHANNEL',
};

// UserTypes
export const UserTypes = {
  SET_USER_NAME: 'SET_USER_NAME',
  INIT_APP: 'INIT_APP',
  GET_ME: 'GET_ME',
  GET_IDENTITY_REQUEST: 'GET_IDENTITY_REQUEST',
  RECEIVED_ME: 'RECEIVED_ME',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  ADDUSER_REQUEST: 'ADDUSER_REQUEST',
  ADDUSER_SUCCESS: 'ADDUSER_SUCCESS',
  ADDUSER_FAILURE: 'ADDUSER_FAILURE',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',
  VALIDATE_CODE_REQUEST: 'VALIDATE_CODE_REQUEST',
  VALIDATE_CODE_SUCCESS: 'VALIDATE_CODE_SUCCESS',
  VALIDATE_CODE_FAILURE: 'VALIDATE_CODE_FAILURE',
  CHECK_EMAIL_REQUEST: 'CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS: 'CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE: 'CHECK_EMAIL_FAILURE',
  GET_UUID_REQUEST: 'GET_UUID_REQUEST',
  GET_UUID_SUCCESS: 'GET_UUID_SUCCESS',
  GET_UUID_FAILURE: 'GET_UUID_FAILURE',
  INIT_PAYMENT_SESSION_REQUEST: 'INIT_PAYMENT_SESSION_REQUEST',
  INIT_PAYMENT_SESSION_SUCCESS: 'INIT_PAYMENT_SESSION_SUCCESS',
  INIT_PAYMENT_SESSION_FAILURE: 'INIT_PAYMENT_SESSION_FAILURE',
  RESEND_VALIDATE_CODE_REQUEST: 'RESEND_VALIDATE_CODE_REQUEST',
  RESEND_VALIDATE_CODE_SUCCESS: 'RESEND_VALIDATE_CODE_SUCCESS',
  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
};
