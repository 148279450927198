import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Voucher from './Voucher';
import { GetBrand } from '../../actions/views/root';
import {
  selectOffer,
  resetAppState,
  getChannels,
  checkVoucher,
} from '../../actions/views/home';
import { selectProduct, selectDuration } from '../../actions/views/duration';
import { checkHasGift, setHasGift } from '../../actions/views/gift';
import { getProduct } from '../../actions/views/pay';
import { log } from '../../actions/generalActions';
import { RootState } from '../../reducers';

const mapStateToProps = (state: RootState) => {
  const { general } = state.entities;
  return {
    general,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      selectOffer,
      resetAppState,
      log,
      GetBrand,
      getChannels,
      checkHasGift,
      setHasGift,
      checkVoucher,
      selectProduct,
      getProduct,
      selectDuration,
    },
    dispatch
  ),
});

export type VoucherProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
