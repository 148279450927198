import React from 'react';
import SmartFAQ from './SmartFAQ';

function ComponentSmartFAQ(props: {
  type: string;
  kbId: number;
  analyticsUA: string;
  cookieOptin: boolean;
  limitDisplay: number;
  singleQuestionOpen: boolean;
  categorie: Array<string>;
  tags: Array<string>;
}) {
  const smartFAQ = SmartFAQ({
    type: props.type,
    kbId: props.kbId,
    analyticsUA: props.analyticsUA,
    cookieOptin: props.cookieOptin,
    limitDisplay: props.limitDisplay,
    singleQuestionOpen: props.singleQuestionOpen,
    categorie: props.categorie,
    tags: props.tags,
  });
  return <div>{smartFAQ}</div>;
}

export default ComponentSmartFAQ;
