/* eslint-disable jsx-a11y/no-static-element-interactions  */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { PropsWithChildren } from 'react';
import './modal.css';
import CrossIcon from '../Icons/CrossIcon';

interface IModalProps {
  close: () => void;
  title: string;
  floating?: boolean;
  className?: string;
}

const Modal = ({
  close,
  children,
  title,
  className,
  floating = false,
}: PropsWithChildren<IModalProps>) => (
  <div className="modal-overlay foo" onClick={close} role="dialog">
    <div
      className={[
        'relative',
        className,
        ...(floating ? ['modal-floating'] : ['modal']),
      ].join(' ')}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <h3 className="font-black text-center mt-8">{title}</h3>
      <button className="absolute top-3 right-4" type="button" onClick={close}>
        <CrossIcon />
      </button>
      {children}
    </div>
  </div>
);

export default Modal;
