import React from 'react';
import LoadingScreen from '../LoadingScreen';
import usePiano from './PianoID';

interface IAuthPianoProps {
  handleloggedIn: any;
  authType: string;
  asModal?: boolean;
}

const AuthPiano = ({
  handleloggedIn,
  authType,
  asModal = true,
}: IAuthPianoProps) => {
  const { loaded } = usePiano({
    containerSelector: '#login',
    action: authType,
    handleLoggedIn: handleloggedIn,
    asModal,
  });

  return !loaded ? (
    <LoadingScreen size={30} />
  ) : (
    <div id="login" className="w-full pr-5" />
  );
};

export default AuthPiano;
