import { useCallback, useEffect, useRef, useState } from 'react';
import {
  IDataLayerItem,
  flyingFishEvents,
  trackEvent,
} from '../../utils/FlyingFish';

declare global {
  interface Window {
    tp: any;
  }
}

export function pushGTMEventUserRegistration({
  uid,
  type = 'account_creation',
}: any) {
  trackEvent(
    flyingFishEvents.account_create({
      event: 'account_creation',
      eventName: 'account_creation_complete',
      userId: uid,
      click_type: type,
    } as IDataLayerItem)
  );
}

export function pushGTMEventUserLogin({ uid, type = 'login' }: any) {
  trackEvent(
    flyingFishEvents.login({
      event: 'login',
      eventName: 'login_complete',
      userId: uid,
      click_type: type,
    } as IDataLayerItem)
  );
}

/**
 * @description User registration success
 * @param {TP} tp - The tp object
 * @param {Object} options - The options object
 * @param {User} options.user - The user object
 */
export function triggerRegistrationSuccess(
  tp: any,
  { user: { ls: registerMethod } = {} }: { user?: any } = {}
): void {
  const { uid } = tp.pianoId.getUser() || {};
  if (uid && registerMethod) {
    const eventType =
      registerMethod === 'ID'
        ? 'account_creation'
        : `account_creation_${registerMethod.toLowerCase()}`;

    pushGTMEventUserRegistration({ uid, type: eventType });
  }
}

/**
 * @description User login success
 * @param {TP} tp - The tp object
 * @param {Object} options - The options object
 * @param {User} options.user - The user object
 */
export function triggerLoginSuccess(
  tp: any,
  { params: { ls: loginMethod } = {} }: { params?: any } = {}
): void {
  const { uid } = tp.pianoId.getUser() || {};
  if (uid && loginMethod) {
    const eventType =
      loginMethod === 'ID' ? 'login' : `login_${loginMethod.toLowerCase()}`;
    pushGTMEventUserLogin({ uid, type: eventType });
  }
}

// const login = ({
//   tp,
//   containerSelector,
//   setLoginSuccess,
//   handleLoggedIn,
//   asModal = true,
// }: any) => {
//   tp.pianoId.show({
//     screen: 'login',
//     displayMode: asModal ? 'modal' : 'inline',
//     containerSelector,
//     stage: 'aboshop',
//   });
//   tp.pianoId.init({
//     loggedIn(data: { user: any; token: any }) {
//       handleLoggedIn(data);
//     },
//     loginSuccess: (e: any) => {
//       setLoginSuccess(true);
//       triggerLoginSuccess(tp, e);
//     },
//     registrationSuccess: (e: any) => {
//       triggerRegistrationSuccess(tp, e);
//     },
//   });
// };

const login = ({ tp, containerSelector, handleLoggedIn, asModal }: any) => {
  tp.pianoId.show({
    screen: 'login',
    displayMode: asModal ? 'modal' : 'inline',
    containerSelector,
    stage: 'aboshop',
  });
  tp.pianoId.init({
    loggedIn(data: { user: any; token: any }) {
      handleLoggedIn(data);
    },
  });
};

const register = ({ tp, containerSelector, handleLoggedIn }: any) => {
  tp.pianoId.show({
    screen: 'register',
    displayMode: 'inline',
    containerSelector,
    stage: 'aboshop',
  });
  tp.pianoId.init({
    loggedIn(data: { user: any; token: any }) {
      handleLoggedIn(data);
    },
  });
};

const logout = ({ tp, setLogoutHandler }: any) => {
  tp.pianoId.logout();
  tp.pianoId.init({
    loggedOut: () => {
      setLogoutHandler(false);
    },
  });
};

const composer = ({ tp, setStartComposer }: any) => {
  tp.experience.execute();
  setStartComposer(false);
};

const usePiano = ({
  action,
  containerSelector,
  handleLoggedIn,
  asModal = true,
}: any) => {
  const [loaded, setIsloaded] = useState(false);
  const [logoutHandler, setLogoutHandler] = useState(false);
  const [startComposer, setStartComposer] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [event, setEvent] = useState({});
  const [token, setToken] = useState<string>('');
  const handleLoggedInRef = useRef(false);
  const myHandleLoggedIn = (e: any) => {
    if (!handleLoggedInRef.current) {
      handleLoggedInRef.current = true;
      setEvent(e);
      setToken(e.token);
    }
  };

  useEffect(() => {
    if (token?.length > 0) {
      handleLoggedIn(event);
    }
  }, [handleLoggedIn, token, event]);

  const pushPianoActions = useCallback(
    ({ tp }) => {
      tp.push([
        'init',
        () => {
          // LOGIN
          if (action === 'login') {
            // tp.experience.execute();
            login({
              tp,
              containerSelector,
              setLoginSuccess,
              handleLoggedIn: myHandleLoggedIn,
              asModal,
            });
          }
          // LOGOUT
          if (action === 'logout' && logoutHandler)
            logout({ tp, setLogoutHandler });
          // REGISTER
          if (action === 'register') {
            register({
              tp,
              containerSelector,
              setLoginSuccess,
              handleLoggedIn: myHandleLoggedIn,
            });
          }
          if (action === 'composer' && startComposer)
            composer({ tp, setStartComposer });
        },
      ]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, logoutHandler, startComposer, containerSelector]
  );

  useEffect(() => {
    const isPianoReady = typeof window !== 'undefined' && window.tp;
    if ((!loaded || logoutHandler || startComposer) && isPianoReady) {
      pushPianoActions({ tp: window.tp });
      setIsloaded(true);
    }
  }, [loaded, pushPianoActions, logoutHandler, startComposer]);

  return {
    loaded,
    handleLogout: () => setLogoutHandler(true),
    startComposer: () => setStartComposer(true),
    myHandleLoggedIn,
  };
};

export default usePiano;
