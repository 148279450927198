import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import fetch from 'isomorphic-fetch';
import moment from 'moment';
import { GeneralTypes } from '../types';
import { getAPIUrl } from '../generalActions';
import { BrandType, ProductType } from '../../reducers/entities/general';
import { selectProduct, selectDuration } from './duration';
import { getProduct } from './pay';
import { EDITION_PAGE, USER_LOGIN_PAGE } from '../../utils/routes';
import { getUrlParamsFromLs } from '../../utils/url';
import { flyingFishEvents, trackEvent } from '../../utils/FlyingFish';

export type SelectOfferType = (
  offerId: number,
  offerName: string,
  track?: boolean
) => void;
export const selectOffer: SelectOfferType =
  (offerId: number, offerName: string, track = true) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    localStorage.setItem('offer', offerId.toString());
    await dispatch({
      type: GeneralTypes.SELECT_OFFER,
      data: { offerId, offerName },
    });
    if (track) {
      trackEvent(flyingFishEvents.offer_select(offerName));
    }
  };

export type ResetAppStateType = () => void;
export const resetAppState: ResetAppStateType = () => (dispatch: any) => {
  localStorage.removeItem('intent');
  localStorage.removeItem('offer');
  localStorage.removeItem('duration');
  localStorage.removeItem('product');
  localStorage.removeItem('productID');
  localStorage.removeItem('editionID');
  localStorage.removeItem('paymentMethods');
  localStorage.removeItem('duratioName');
  localStorage.removeItem('transaction');
  localStorage.removeItem('hasGift');
  localStorage.removeItem('gift');
  localStorage.removeItem('voucher');
  localStorage.removeItem('recurrentOption');
  dispatch({ type: GeneralTypes.RESET_APP_STATE });
};

// Fetch channel call
export type GetChannelsType = (
  campaign: string | null,
  brand: BrandType
) => any;
export const getChannels: GetChannelsType = (campaign, brand) => async () => {
  const resp = await fetch(
    `${getAPIUrl()}/Product/channels?campaign=${campaign}&brandId=${brand.id}`,
    {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }
  );
  const data = await resp.json();
  if (!resp.ok) throw new Error(data.message);
  return data;
};

export type getAdvertisingsType = (brand: BrandType) => any;
export const getAdvertisings: getAdvertisingsType = (brand) => async () => {
  try {
    const resp = await fetch(
      `${getAPIUrl()}/Marketing?brandId=${
        brand.id
      }&online=true&date=${moment().format('YYYY-MM-DD HH:mm:ss')}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }
    );
    const data = await resp.json();
    if (!resp.ok) throw new Error(data.message);
    return data;
  } catch (error) {
    throw error;
  }
};

export type getFreeCampaignType = (
  brand: BrandType,
  freeCampaignName: string
) => any;
export const getFreeCampaign: getFreeCampaignType =
  (brand, freeCampaignName) => async () => {
    try {
      const resp = await fetch(
        `${getAPIUrl()}/TrialCampaign?brandId=${
          brand.id
        }&freeCampaignName=${freeCampaignName}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

// Check voucher
export type CheckVoucherType = (
  voucher: string,
  brandId: number,
  bh: any
) => any;
export const checkVoucher: CheckVoucherType =
  (voucher, brandId, browserHistory) => async (dispatch: any) => {
    try {
      const resp = await fetch(
        `${getAPIUrl()}/Vouchers/CheckVoucher?code=${voucher}&brandId=${brandId}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );

      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      dispatch(selectProduct(data.productId));
      const product = (await dispatch(getProduct())) as ProductType;
      dispatch(selectOffer(product.channelDto.id, product.channelDto.name));
      dispatch(
        selectDuration(
          product.duration,
          product.name,
          product.price.toString(10)
        )
      );
      dispatch({ type: GeneralTypes.SET_VOUCHER, data });
      if (product.editionDto.length > 0) {
        browserHistory.push(`${EDITION_PAGE}${getUrlParamsFromLs()}`);
      } else {
        browserHistory.push(USER_LOGIN_PAGE);
      }
      return data;
    } catch (error) {
      throw error;
    }
  };
