import SMARTFAQ_TYPES from './SmartFAQTypes';

const {
  FAQ_PAGE,
  FAQ_PUSH,
  FAQ_STANDALONE,
  FAQ_INPUT,
  FAQ_SELECT,
  FAQ_CONTEXT,
} = SMARTFAQ_TYPES || {};

const buildNames = {
  lavenir: 'avenir',
};

const getParams = ({ item, searchConfig, link, labels }: any) => {
  const {
    kbId,
    containerId,
    analyticsUA,
    cookieOptin,
    limitDisplay,
    type,
    singleQuestionOpen,
  } = item || {};
  const { categories, tags } = searchConfig || {};
  const { faqUrl, openInNewTab } = link || {};
  const {
    title,
    emptyMessage,
    placeholder,
    examplesTitle,
    submit,
    openOnFaq,
    goToFaqLink,
  } = labels || {};

  const commonParams = {
    kbId: Number(kbId),
    locale: 'fr',
    cookieOptin,
    buildName: buildNames.lavenir || '',
    filters: {
      thematics: categories || [],
      tags: tags || [],
    },
  };

  const filteredCommon = {
    limit: limitDisplay,
    faqUrl,
    containerId,
    openInNewTab,
  };

  const labelParams = {
    common: {
      title: {
        fr: title,
      },
      emptyMessage: {
        fr: emptyMessage,
      },
    },
    link: {
      openOnFaq: {
        fr: openOnFaq,
      },
      goToFaqLink: {
        fr: goToFaqLink,
      },
    },
    standalone: {
      placeholder: {
        fr: placeholder,
      },
      examplesTitle: {
        fr: examplesTitle,
      },
      submit: {
        fr: submit,
      },
    },
  };

  const params = {
    [FAQ_PAGE]: {
      ...commonParams,
      analyticsUA,
    },
    [FAQ_PUSH]: {
      ...commonParams,
      analyticsUA,
    },
    [FAQ_INPUT]: {
      ...commonParams,
      ...filteredCommon,
      labels: { ...labelParams?.common, ...labelParams?.link },
      singleQuestionOpen,
      inputId: 'st-input',
    },
    [FAQ_SELECT]: {
      ...commonParams,
      ...filteredCommon,
      labels: { ...labelParams?.common, ...labelParams?.link },
      singleQuestionOpen,
      selectId: 'st-select',
      mapping: {}, // TODO
    },
    [FAQ_CONTEXT]: {
      ...commonParams,
      ...filteredCommon,
      labels: { ...labelParams?.common, ...labelParams?.link },
      singleQuestionOpen,
    },
    [FAQ_STANDALONE]: {
      ...commonParams,
      ...filteredCommon,
      labels: { ...labelParams?.common, ...labelParams?.standalone },
    },
  };

  return params[type];
};

const getFaq = ({ e, data }: any) => {
  const stEvent = e?.detail || {};
  stEvent?.init(getParams({ ...data }));
};

export default getFaq;
