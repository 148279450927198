import React from 'react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  // componentDidCatch(error: any, errorInfo: any) {
  //   logErrorToMyService(error, errorInfo);
  // }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Une erreur s&apos;est produite.</h1>
          <Link to="/">Recommencer l&apos;achat</Link>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
