import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import './Input.css';

function CutomInput(props: TextFieldProps) {
  const { className, ...others } = props;
  return (
    <TextField
      {...others}
      className={(className || '') + (others.error ? '' : ' custom_input')}
    >
      {props.children}
    </TextField>
  );
}

export default CutomInput;
