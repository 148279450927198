import React from 'react';
import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getUrlParamsFromLs } from '../../utils/url';
import browserHistory from '../../utils/browser_history';
import { selectOffer } from '../../actions/views/home';
import { selectDuration, selectProduct } from '../../actions/views/duration';
import { selectEdition } from '../../actions/views/edition';
import { GeneralTypes } from '../../actions/types';
import { RootState } from '../../reducers';
import {
  DURATION_PAGE,
  EDITION_PAGE,
  GIFT_PAGE,
  HOME_PAGE,
  USER_LOGIN_PAGE,
} from '../../utils/routes';
import LoadingScreen from '../../components/LoadingScreen';
import { getProduct } from '../../actions/views/pay';
import { goToPage } from '../../utils/helpers';

// TODO: get rid of chennels here
// const channels = ['INTÉGRAL', 'DIGITAL+', 'DIGITAL'];

type GotoState = {
  offer: string | null;
  product: string | null;
  edition: string | null;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_source: string | null;
  utm_medium: string | null;
  pool_token: string | null;
  promocode: string | null;
};

class Goto extends React.Component<GotoProps, GotoState> {
  constructor(props: GotoProps) {
    super(props);

    const paramString = document.location.search;
    const urlParams = new URLSearchParams(paramString);

    this.state = {
      offer: urlParams.get('offer'),
      product: urlParams.get('product'),
      edition: urlParams.get('edition'),
      utm_campaign: urlParams.get('utm_campaign'),
      utm_content: urlParams.get('utm_content'),
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      pool_token: urlParams.get('pool_token'),
      promocode: urlParams.get('promocode'),
    };
  }

  async componentDidMount() {
    const {
      offer,
      product,
      edition,
      utm_campaign,
      utm_content,
      utm_source,
      utm_medium,
      pool_token,
      promocode,
    } = this.state;

    const { currentProduct, brand } = this.props.general.appState;

    this.props.dispatch({
      type: GeneralTypes.SET_UTM_DATA,
      data: {
        utm_campaign: utm_campaign || '',
        utm_content: utm_content || '',
        utm_source: utm_source || '',
        utm_medium: utm_medium || '',
        pool_token: pool_token || '',
        promocode: promocode || '',
      },
    });

    if (product !== null) {
      if (brand.hasEdition && edition === null) {
        await this.props.actions.selectProduct(parseInt(product, 10));
        await this.handleGoTo();
        return;
      }
      if (brand.hasEdition && edition !== null) {
        await this.props.actions.selectProduct(parseInt(product, 10));
        await this.props.actions.selectEdition(parseInt(edition, 10));
        await this.props.actions.getProduct();
        await this.handleGoTo();
        return;
      }
      await this.props.actions.selectProduct(parseInt(product, 10));
      await this.props.actions.getProduct();
      await this.handleGoTo();
      return;
    }
    if (offer !== null) {
      await this.props.actions.selectOffer(
        parseInt(offer, 10),
        currentProduct?.channelDto.name || ''
      );
      browserHistory.push(
        `${DURATION_PAGE}?redirect=true${getUrlParamsFromLs()}`
      );
      return;
    }
    browserHistory.push(HOME_PAGE);
  }

  handleGoTo = async (replace = false) => {
    const { hasGift, brand } = this.props.general.appState;

    if (hasGift === true) {
      goToPage(`${GIFT_PAGE}${getUrlParamsFromLs()}`, replace);
    } else if (brand.hasEdition) {
      goToPage(`${EDITION_PAGE}${getUrlParamsFromLs()}`, replace);
    } else {
      goToPage(`${USER_LOGIN_PAGE}${getUrlParamsFromLs()}`, replace);
    }
  };

  render() {
    const { brand } = this.props.general.appState;
    return <LoadingScreen customColor={brand.thirdColor} size={60} />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      selectOffer,
      selectDuration,
      selectProduct,
      getProduct,
      selectEdition,
    },
    dispatch
  ),
});

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  return {
    user,
    general,
  };
};

type GotoProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Goto);
