import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import fetch from 'isomorphic-fetch';
import { GeneralTypes } from '../types';
import { fetchWithCredentials, log, getAPIUrl } from '../generalActions';
import { UserUpdateType } from '../userActions';
import { AppAction } from '../../reducers/entities/general';
import { RootState } from '../../reducers';
import { EnumPaymentMethods } from '../../utils/model';
import {
  flyingFishEvents,
  getEdition as getEditionFromEditions,
  IDataLayerCommerceItem,
  trackEvent,
} from '../../utils/FlyingFish';
import { getFlowtype, getOffer } from '../../hooks/usePageViews';

export type SelectPaymentType = (type: string) => void;

export const selectPayment: SelectPaymentType =
  (type: string) => (dispatch: any, getState: () => RootState) => {
    dispatch({ type: GeneralTypes.SELECT_PAYMENT_TYPE, data: type });
    const { appActions, appState } = getState().entities.general;
    const { uuid } = getState().entities.user.currentUser;
    if (appActions !== null && uuid !== '')
      dispatch(
        log({
          actionDto: appActions.find(
            (a: AppAction) => a.definition === 'SelectPayment'
          ),
          uuid,
          result: type,
        })
      );
    trackEvent(flyingFishEvents.payment_type_select(type));
    trackEvent(
      flyingFishEvents.checkout({
        item_name: getOffer(appState?.currentProduct),
        item_category: getFlowtype(appState?.currentProduct, window.location),
        item_category1: appState?.currentProduct?.duration.toString(),
        ...(appState?.editionID
          ? {
              item_category2: getEditionFromEditions(
                appState?.currentProduct,
                appState?.editionID
              ),
            }
          : {}),
        coupon: appState?.promocode || '',
        quantity: '1',
        price: appState?.currentProduct?.price?.toString(10) || '',
      } as IDataLayerCommerceItem)
    );
  };

interface ConfirmPaymentParamsType {
  method_id: string | null;
  intent_id: string;
  productID: number;
  source?: string | null;
  username: {
    firstname: string;
    lastname: string;
  };
}

export type SendEmailServiceClientType = (
  transactionId: string,
  user: UserUpdateType
) => any;
export const sendEmailServiceClient: SendEmailServiceClientType =
  (transactionId, user) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    dispatch({ type: GeneralTypes.SEND_SC_EMAIL_REQUEST, getState });
    const resp = await dispatch(
      fetchWithCredentials(`${getAPIUrl()}/Customers/MailServiceClient`, {
        method: 'POST',
        body: JSON.stringify({
          customer: user,
          transaction: { transactionId },
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
    );
    const data = await resp.json();
    if (!resp.ok) throw new Error(data.message);
    dispatch({ type: GeneralTypes.SEND_SC_EMAIL_SUCCESS, data });
    const { appActions } = getState().entities.general;
    const { uuid } = getState().entities.user.currentUser;
    if (appActions !== null && uuid !== '')
      dispatch(
        log({
          actionDto: appActions.find(
            (a: AppAction) => a.definition === 'CompleteOrder'
          ),
          uuid,
          result: transactionId,
        })
      );
    return { data };
  };

export type ConfirmPaymentType = (params: ConfirmPaymentParamsType) => any;
export const confirmPayment: ConfirmPaymentType =
  (params) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      type bodyType = {
        id: string;
        productId: number;
        paymentMethodId?: string;
        source?: string;
      };
      const body: bodyType = {
        id: params.intent_id,
        productId: params.productID,
      };
      if (params.method_id !== null) {
        body.paymentMethodId = params.method_id;
      }
      if (params.source !== null) {
        body.source = params.source;
      }
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Pay/ConfirmPaymentIntent`, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      if (data.status === 'requires_action') {
        return data;
      }
      dispatch({ type: GeneralTypes.PAYMENT_SUCCESS, data });
      const { currentProduct, editionID, brand } =
        getState().entities.general.appState;
      let label: string = '';
      if (currentProduct !== null && editionID !== null) {
        label = `${brand} | ${currentProduct.channelDto.name} | ${currentProduct.name} | ${currentProduct.editionDto[editionID].name} | ${currentProduct.price}€`;
      }
      const { appActions } = getState().entities.general;
      const { uuid } = getState().entities.user.currentUser;
      if (appActions !== null && uuid !== '')
        dispatch(
          log({
            actionDto: appActions.find(
              (a: AppAction) => a.definition === 'ConfirmPayment'
            ),
            uuid,
            result: label,
          })
        );
      // If no delivery needed send email call
      // Now we need to ask info even if no delivery
      if (
        currentProduct !== null &&
        currentProduct.channelDto.name !== 'INTÉGRAL' &&
        !currentProduct.saturdayPaper
      ) {
        // Get firstname and lastname
        await dispatch(
          sendEmailServiceClient(data.id, {
            lastname: params.username.lastname,
            firstname: params.username.firstname,
            delivery: false,
          })
        );
      }
      return data;
    } catch (error) {
      throw error;
    }
  };

// Fetch product call
export type GetProductType = () => any;
export const getProduct: GetProductType =
  () =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      const productId = getState().entities.general.appState.productID;
      const resp = await fetch(`${getAPIUrl()}/Product/${productId}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      dispatch({ type: GeneralTypes.SET_PRODUCT, data });
      return data;
    } catch (error) {
      throw error;
    }
  };

// Fetch gifts call
export type isCampaignAvailableType = (
  campaignCode: string,
  brandId: number
) => any;
export const isCampaignAvailable: isCampaignAvailableType =
  (campaignCode, brandId) => async () => {
    try {
      const resp = await fetch(
        `${getAPIUrl()}/Campaign/${campaignCode}?brandId=${brandId}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

export type GetEditionType = () => any;
export const getEdition: GetEditionType =
  () =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      const resp = await fetch(
        `${getAPIUrl()}/Edition/${
          getState().entities.general.appState.editionID
        }`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

export interface OrderType {
  id?: string;
  customerUuid?: string;
  resetThirdParty: boolean;
  productId?: number | null;
  editionName?: string;
  editionId?: number;
  campaignCode?: string | null;
  promoCode?: string | null;
  gift?: string | null;
}

export interface RenewalOrderType {
  id?: string;
  customerUuid?: string;
  price: number;
  editionName?: string;
  editionId?: number;
  promoCode?: string | null;
  productId?: number | null;
  gift?: string | null;
  //
  ref?: string | null;
  externalSubscriptionId?: string | null;
  externalProductId?: string | null;
  externalProductName?: string | null;
  externalPreviousSubscriptionId?: string | null;
  externalClientId?: string | null;
  duration?: number | null;
  brand?: string;
}

export interface TransactionType {
  id?: string;
  orderId?: string;
  paymentStatus?: number;
  PayId?: string;
}

export type createTransactionType = (order: TransactionType) => void;
export const createTransaction: createTransactionType =
  (transaction) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const resp = await dispatch(
        fetchWithCredentials(
          `${getAPIUrl()}/Pay/${transaction.orderId}/CreateTransaction`,
          {
            method: 'POST',
            body: JSON.stringify({ ...transaction }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          }
        )
      );
      if (!resp.ok) throw new Error(resp.statusText);
      // or check for response.status
      const data = await resp.json();
      dispatch({
        type: GeneralTypes.CREATE_TRANSACTION_SUCCESS,
        data: data.id,
      });
      // dispatch({
      //   type: GeneralTypes.GET_CLIENT_SECRET,
      //   data: data.clientSecret
      // });
      return data;
    } catch (error) {
      throw error;
    }
  };

export type checkTransactionType = (transactionId: string) => any;
export const checkTransaction: checkTransactionType =
  (transactionId: string) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const resp = await dispatch(
        fetchWithCredentials(
          `${getAPIUrl()}/Pay/IngenicoCheckTransaction?transactionId=${transactionId}`,
          {
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          }
        )
      );
      const data = await resp.json();
      return data;
    } catch (error) {
      throw new Error("Une erreur s'est produite, veuillez réessayer");
    }
  };

export type checkUserAccessListType = (productID: number) => any;
export const checkUserAccessList: checkUserAccessListType =
  (productID: number) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const resp = await dispatch(
        fetchWithCredentials(
          `${getAPIUrl()}/Pay/CheckUserAccessList?productID=${productID}`,
          {
            method: 'GET',
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          }
        )
      );
      if (resp === undefined) return null;
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw new Error("Une erreur s'est produite, veuillez réessayer");
    }
  };

export type CreateOrderType = (order: OrderType) => any;
export const createOrder: CreateOrderType =
  (order) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const resp = await dispatch(
      fetchWithCredentials(`${getAPIUrl()}/Pay/CreateOrder`, {
        method: 'POST',
        body: JSON.stringify(order),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
    );

    if (!resp?.ok) {
      throw new Error('Une erreur est survenue, veuillez recharger la page');
    }

    const data = await resp.json();

    dispatch({
      type: GeneralTypes.CREATE_ORDER_SUCCESS,
      data: data.id,
    });
    // dispatch({
    //   type: GeneralTypes.GET_CLIENT_SECRET,
    //   data: data.clientSecret
    // });
    return data;
  };

export type UpdateOrderType = (order: OrderType) => any;
export const updateOrder: UpdateOrderType =
  (order) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      const intentId = getState().entities.general.appState.intent;
      if (intentId === null) {
        throw new Error('Missing intent');
      }
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Pay/UpdateOrder`, {
          method: 'POST',
          body: JSON.stringify({ ...order }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) {
        dispatch({
          type: GeneralTypes.DELETE_INTENT_SUCCESS,
        });
        throw new Error(data.message);
      }
      dispatch({
        type: GeneralTypes.UPDATE_INTENT_SUCCESS,
        data: data.id,
      });
      return data;
    } catch (error) {
      throw error;
    }
  };

export type ConfirmVirementType = (transactionId: string) => void;
export const confirmVirement: ConfirmVirementType =
  (transactionId) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      if (transactionId === null) {
        throw new Error('Missing transactionId');
      }
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Pay/ConfirmVirement`, {
          method: 'POST',
          body: JSON.stringify({
            id: transactionId,
            paymentMethodId: EnumPaymentMethods.Virement,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

export type ConfirmVoucherType = (transactionId: string) => void;
export const confirmVoucher: ConfirmVoucherType =
  (transactionId) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      const { voucher } = getState().entities.general.appState;
      if (transactionId === null) {
        throw new Error('Missing transactionId');
      }
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Pay/ConfirmVoucher`, {
          method: 'POST',
          body: JSON.stringify({
            id: transactionId,
            paymentMethodId: EnumPaymentMethods.Voucher,
            VoucherCode: voucher,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

export type CreateMollieType = () => void;
export const createMollie: CreateMollieType =
  () =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      const { transaction, productID } = getState().entities.general.appState;
      if (transaction === null) {
        throw new Error('Missing transactionId');
      }
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Pay/CreateMollie`, {
          method: 'POST',
          body: JSON.stringify({
            tid: transaction,
            pid: productID,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

export type ConfirmDomiciliationType = (
  cardNumber: string,
  transactionId: string
) => void;
export const confirmDomiciliation: ConfirmDomiciliationType =
  (cardNumber: string, transactionId: string) =>
  async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      if (transactionId === null) {
        throw new Error('Missing transactionId');
      }
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Pay/ConfirmDomiciliation`, {
          method: 'POST',
          body: JSON.stringify({
            id: transactionId,
            paymentMethodId: EnumPaymentMethods.Domiciliation,
            cardNumber,
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };

export type CreateIntentType = (productId: number) => void;
export const createIntent: CreateIntentType =
  (productId) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const resp = await fetch(`${getAPIUrl()}/Pay/CreatePaymentIntent`, {
        method: 'POST',
        body: JSON.stringify({
          productId,
          confirmationMethod: 'manual',
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (!resp.ok)
        // or check for response.status
        throw new Error(resp.statusText);
      const data = await resp.json();
      dispatch({
        type: GeneralTypes.CREATE_ORDER_SUCCESS,
        data: data.paymentIntentId,
      });
      // dispatch({
      //   type: GeneralTypes.GET_CLIENT_SECRET,
      //   data: data.clientSecret
      // });
      return data;
    } catch (error) {
      throw error;
    }
  };

export type DeleteIntentType = (intent: string) => void;
export const deleteIntent: DeleteIntentType =
  () =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    const intentId = getState().entities.general.appState.intent;
    if (intentId === null) {
      throw new Error('Missing intent');
    }
    const resp = await dispatch(
      fetchWithCredentials(`${getAPIUrl()}/Pay/CancelPaymentIntent`, {
        method: 'POST',
        body: JSON.stringify({
          id: intentId,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
    );
    const data = await resp.json();
    if (!resp.ok) throw new Error(data.message);
    dispatch({
      type: GeneralTypes.DELETE_INTENT_SUCCESS,
    });
    return data;
  };

export const SetNoSaturdayPaper: UpdateOrderType =
  (order) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Customers/NoSaturdayPaper`, {
          method: 'POST',
          body: JSON.stringify({ ...order }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      throw error;
    }
  };
