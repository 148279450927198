import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import Cookie from './Cookie';

const mapStateToProps = (state: RootState) => {
  const { general } = state.entities;
  return { general };
};

export default connect(mapStateToProps, null)(Cookie);
