import { bindActionCreators, AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Home from './Home';
import { GetBrand } from '../../actions/views/root';
import {
  selectOffer,
  resetAppState,
  getChannels,
  getAdvertisings,
} from '../../actions/views/home';
import { checkHasGift, setHasGift } from '../../actions/views/gift';
import { log } from '../../actions/generalActions';
import { RootState } from '../../reducers';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  const { router } = state;
  return {
    user,
    general,
    currentPath: router.location,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      selectOffer,
      resetAppState,
      log,
      GetBrand,
      getChannels,
      getAdvertisings,
      checkHasGift,
      setHasGift,
    },
    dispatch
  ),
});

export type HomeProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
