import paypalImg from '../images/PayPal.svg';
import creditImg from '../images/master-visa-logo_2.svg';
import debitImg from '../images/bancontact-mister-cash.svg';
import TransferImg from '../images/payment/virement.svg';
import VoucherImg from '../images/voucher.png';
import DomImg from '../images/payment/Combined Shape.svg';

export enum EnumPaymentMethods {
  Aucun = 0,
  CreditCard = 1,
  Bancontact = 2,
  Paypal = 3,
  Virement = 4,
  Domiciliation = 5,
  Voucher = 6,
  Amex = 7,
}

export enum EnumPaymentServiceProvider {
  Ingenico = 1,
  Mollie = 2,
  Custom = 3,
  Aucun = 4,
}

const ArrayAucun: paymentMethod = { title: 'Aucun mode de paiement', img: '' };
const ArrayCreditCard: paymentMethod = {
  title: 'Carte de crédit',
  img: creditImg,
};
const ArrayBancontact: paymentMethod = { title: 'Bancontact', img: debitImg };
const ArrayPaypal: paymentMethod = { title: 'Paypal', img: paypalImg };

const ArrayVirement: paymentMethod = {
  title: 'Virement',
  img: TransferImg,
  desc: 'Virement',
};
const ArrayDomiciliation: paymentMethod = {
  title: 'Domiciliation',
  img: DomImg,
  desc: 'Domiciliation',
};
const ArrayVoucher: paymentMethod = {
  title: 'Voucher',
  img: VoucherImg,
  desc: 'Valider mon coupon',
};

export const MapPaymentMethods = new Map<number, paymentMethod>([
  [EnumPaymentMethods.Aucun, ArrayAucun],
  [EnumPaymentMethods.CreditCard, ArrayCreditCard],
  [EnumPaymentMethods.Bancontact, ArrayBancontact],
  [EnumPaymentMethods.Paypal, ArrayPaypal],
  [EnumPaymentMethods.Virement, ArrayVirement],
  [EnumPaymentMethods.Domiciliation, ArrayDomiciliation],
  [EnumPaymentMethods.Voucher, ArrayVoucher],
]);

export interface paymentMethod {
  title: string;
  img: string;
  desc?: string;
}

export enum PaymentStatus {
  Initialized = 0,
  Pending = 1,
  Failed = 2,
  Refunded = 3,
  Succeded = 4,
}

export type AddressType = {
  name: string;
  street: string;
  houseNumber: string;
  postalBox: string;
  postalCode: string;
  city: string;
  country: CountryType;
};

export type CountryType = {
  name: string;
  code: string;
};

export type ShippingAddressType = {
  firstname: string;
  lastname: string;
  phonenumber: string;
  isBelgium: boolean;
  hasCountry: boolean;
  hasCity: boolean;
  isBookSeller: boolean;
  address: AddressType;
  CompanyName?: string;
  VATNumber?: string;
};

export type BillingAddressType = {
  CompanyName: string;
  VATNumber: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  isBelgium: boolean;
  hasCountry: boolean;
  hasCity: boolean;
  isBookSeller: boolean;
  address: AddressType;
};
