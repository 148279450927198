import React from 'react';

interface IInputErrorProps {
  message: undefined | string;
}
const InputError = ({ message }: IInputErrorProps) =>
  message ? (
    <p className="text-sm text-red-600 mt-1 mb-2 font-medium">{message}</p>
  ) : null;

export default InputError;
