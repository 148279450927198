import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { log } from '../../actions/generalActions';
import {
  addUser,
  checkEmail,
  login,
  register,
} from '../../actions/userActions';
import {
  createTransaction,
  checkTransaction,
  checkUserAccessList,
  confirmVirement,
  confirmVoucher,
  confirmDomiciliation,
  getProduct,
  getEdition,
  selectPayment,
  createOrder,
  updateOrder,
  createMollie,
  isCampaignAvailable,
} from '../../actions/views/pay';
import { RootState } from '../../reducers';
import SelectComp from './Select';

const mapStateToProps = (state: RootState) => {
  const { user, general } = state.entities;
  return {
    user,
    general,
  };
};

const mapDispatchToPropsForSelec = (dispatch: Dispatch<AnyAction>) => ({
  dispatch,
  actions: bindActionCreators(
    {
      log,
      login,
      register,
      getProduct,
      getEdition,
      checkEmail,
      addUser,
      selectPayment,
      createOrder,
      updateOrder,
      createTransaction,
      checkTransaction,
      checkUserAccessList,
      confirmVirement,
      confirmVoucher,
      confirmDomiciliation,
      createMollie,
      isCampaignAvailable,
    },
    dispatch
  ),
});

export type SelectProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToPropsForSelec> & { openLogginModal: Function };

export const Select = connect(
  mapStateToProps,
  mapDispatchToPropsForSelec
)(SelectComp);
