import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import './Checkbox.css';
import { withStyles } from '@material-ui/core';

function CustomCheckbox(props: any) {
  const { className, color, ...other } = props;
  const CheckboxStyled = withStyles({
    root: {
      color,
      '&$checked': {
        color,
      },
    },
    checked: {
      color,
    },
  })(Checkbox);

  return (
    <CheckboxStyled {...other} className={`${className || ''} custom_checkbox`}>
      {props.children}
    </CheckboxStyled>
  );
}

export default CustomCheckbox;
