import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { useLocation } from 'react-router';
import Header from '../Header';
import Footer from '../Footer';
import './index.css';
import { UserState } from '../../reducers/entities/user';
import { GeneralState } from '../../reducers/entities/general';
import useFontImport from '../../hooks/useFontImport';
import StepConnector from './stepConnector';
import {
  CONTACT_DATA_PAGE,
  DELIVERY_PAGE,
  DURATION_PAGE,
  EDITION_PAGE,
  FINISHED_PAGE,
  GIFT_PAGE,
  PAY_PAGE,
  DELIVERY_ADDRESS_PAGE,
  BILLING_ADDRESS_PAGE,
  SHORT_FORM_PAGE,
  USER_LOGIN_PAGE,
  TEMPORARY_OFFERS_PAGE,
  VOUCHER_PAGE,
  PROMOTION_PAGE,
  HOME_PAGE,
  ABORENEWAL_PAGE,
  PAY_RENEWAL_PAGE,
  FREE_CAMPAIGN_PAGE,
  COOKIE_PAGE,
} from '../../utils/routes';
import brands, { IBrand, IBrands } from '../../utils/brands';
import SelectedProduct from '../SelectedProduct';
import Modal from '../Modal/modal';
import Button from '../Button';

enum StepsEnum {
  CHOICE = 0,
  CONTACT_DATA,
  PAYMENT,
  CONFIRMATION,
}

const SHOW_ADBLOCKER_MODAL_ON = [
  HOME_PAGE,
  DURATION_PAGE,
  EDITION_PAGE,
  GIFT_PAGE,
  USER_LOGIN_PAGE,
];

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      boxShadow:
        '2px 5px 5px 0 rgba(168,178,200,.10),1px 5px 10px 0 rgba(168,178,200,.5)',
      transition: 'all 250ms cubic-bezier(.02, .01, .47, 1) 0ms',
    },
  },
  step_label_root: {
    marginTop: '5px!important',
    fontFamily: "'FontHeavy', 'Times New Roman', Times, serif",
    fontSize: window.innerWidth < 1120 ? '12px' : '14px',
  },
});

function StepIconComp(x: StepIconProps, color: string) {
  const { active, completed } = x;
  return (
    <div className={`circle ${active ? 'active' : 'root'}`}>
      {completed ? (
        <div
          className="completed"
          style={{ backgroundColor: color, borderColor: color }}
        />
      ) : (
        <div style={active ? { borderColor: color } : {}} />
      )}
    </div>
  );
}

export interface LayoutProps {
  loggedIn: boolean;
  openLogginModal: Function;
  isModalOpen: boolean;
  logout: Function;
  location: string;
  children: any;
  user: UserState;
  general: GeneralState;
  dispatch: any;
  hasAdblocker: boolean;
  hideAdblocker: boolean;
  toggleHideAdblocker: Function;
}

function Layout(props: LayoutProps) {
  const { brand, gift, currentProduct } = props.general.appState;
  const { isAuth } = props.user.currentUser;
  const { hasAdblocker, hideAdblocker, toggleHideAdblocker } = props;
  const { pathname } = useLocation();
  const renderBrand = brands[brand.name as keyof IBrands] as IBrand;
  const classes = useStyles();
  useFontImport(props.general.appState.brand);

  function getActiveStep() {
    const activeSteps: Record<string, number> = {
      [TEMPORARY_OFFERS_PAGE]: 0,
      [DURATION_PAGE]: 1,
      [GIFT_PAGE]: 2,
      [EDITION_PAGE]: 3,
      [USER_LOGIN_PAGE]: 4,
      [DELIVERY_ADDRESS_PAGE]: 4,
      [CONTACT_DATA_PAGE]: 4,
      [BILLING_ADDRESS_PAGE]: 4,
      [SHORT_FORM_PAGE]: 4,
      [PAY_PAGE]: 5,
      [PAY_RENEWAL_PAGE]: 5,
      [DELIVERY_PAGE]: 5,
      [VOUCHER_PAGE]: 8,
      [ABORENEWAL_PAGE]: 9,
      [FINISHED_PAGE]: 10,
    };

    return activeSteps[props.location] || 0;
  }

  // HEADER ITEMS DECLARATION
  function getActiveStepStepper() {
    const activeStepper: Record<string, number> = {
      [DURATION_PAGE]: StepsEnum.CHOICE,
      [GIFT_PAGE]: StepsEnum.CHOICE,
      [EDITION_PAGE]: StepsEnum.CHOICE,
      [DELIVERY_ADDRESS_PAGE]: StepsEnum.CONTACT_DATA,
      [USER_LOGIN_PAGE]: StepsEnum.CONTACT_DATA,
      [CONTACT_DATA_PAGE]: StepsEnum.CONTACT_DATA,
      [BILLING_ADDRESS_PAGE]: StepsEnum.CONTACT_DATA,
      [SHORT_FORM_PAGE]: StepsEnum.CONTACT_DATA,
      [DELIVERY_PAGE]: StepsEnum.PAYMENT,
      [PAY_RENEWAL_PAGE]: StepsEnum.PAYMENT,
      [PAY_PAGE]: StepsEnum.PAYMENT,
      [FINISHED_PAGE]: StepsEnum.CONFIRMATION,
    };

    return activeStepper[props.location] || StepsEnum.CHOICE;
  }

  function getSteps() {
    // const { currentProduct } = props.general.appState;
    // const isDigital = currentProduct?.channelDto.name === 'DIGITAL';

    return [
      'Type',
      'Choisissez votre durée',
      'Choisissez votre cadeau',
      'Choisissez votre édition',
      'Vos coordonnées',
      'Paiement',
      'Vos coordonnées',
      'Nos offres temporaires',
      'Activez votre abonnement gratuit',
      'Je me réabonne !',
      'Votre abonnement a été enregistré avec succès',
    ];
  }

  function getStepsStepper(): string[] {
    return ['Choix', 'Coordonnées', 'Paiement', 'Confirmation'];
  }

  const stepsStepper = getStepsStepper();
  const activeStepStepper = getActiveStepStepper();
  const steps = getSteps();
  const activeStep = getActiveStep();
  const customBackground = [FREE_CAMPAIGN_PAGE.split('/')[1]].includes(
    props.location.split('/')[1]
  );
  const isBuyPianoExp =
    currentProduct?.channelDto?.name.toLowerCase() === 'digital+';
  const showStepper = ![
    HOME_PAGE.split('/')[1],
    PROMOTION_PAGE.split('/')[1],
    TEMPORARY_OFFERS_PAGE.split('/')[1],
    VOUCHER_PAGE.split('/')[1],
    ABORENEWAL_PAGE.split('/')[1],
    FREE_CAMPAIGN_PAGE.split('/')[1],
    COOKIE_PAGE.split('/')[1],
  ].includes(props.location.split('/')[1]);

  const renderCurrentProduct = () => {
    const showCurrentProduct = ![
      HOME_PAGE.split('/')[1],
      TEMPORARY_OFFERS_PAGE.split('/')[1],
      VOUCHER_PAGE.split('/')[1],
      FREE_CAMPAIGN_PAGE.split('/')[1],
      COOKIE_PAGE.split('/')[1],
    ].includes(props.location.split('/')[1]);

    if (currentProduct && showCurrentProduct && !isBuyPianoExp) {
      // if (window.location.pathname === ABORENEWAL_PAGE) {
      //   return <div id="currentProductPortal" />;
      // }
      return (
        <SelectedProduct
          brand={brand}
          currentProduct={currentProduct}
          gift={gift}
          currentPath={window.location.pathname}
        />
      );
    }

    return null;
  };

  const refreshApp = () => {
    window.location.reload();
  };

  const showAdblockerModal = () =>
    hasAdblocker &&
    SHOW_ADBLOCKER_MODAL_ON.includes(pathname) &&
    !isAuth &&
    !hideAdblocker;

  return (
    <div className={`aboshop ${brand.rootClassName} relative ${classes.root}`}>
      {showAdblockerModal() && (
        <Modal
          close={() => toggleHideAdblocker()}
          title="Veuillez désactiver votre bloqueur de publicités, puis rafraîchir la page."
          floating
          className="modal-warning"
        >
          <p>
            L&apos;utilisation d&apos;un bloqueur de publicités pourrait
            empêcher le bon déroulement de la commande de votre abonnement. Pour
            poursuivre sur cette page, veuillez désactiver votre bloqueur de
            publicités.
          </p>
          <Button
            customColor={props.general.appState.brand.thirdColor}
            fullWidth={false}
            size="small"
            onClick={refreshApp}
            color="primary"
          >
            Rafraîchir la page
          </Button>
        </Modal>
      )}

      <Grid container className="layout-header">
        {React.createElement(renderBrand.navbar, {
          general: props.general,
          user: props.user,
          logout: props.logout,
          openLogginModal: props.openLogginModal,
          isModalOpen: props.isModalOpen,
          uri: renderBrand.profilePage,
        })}
      </Grid>
      <div className="layout-page flex flex-col w-full justify-center">
        {showStepper && !isBuyPianoExp && (
          <Grid container className="layout-top flex justify-center">
            <Grid item lg={8} xs={12} className="layout-breadcrumb-parent">
              <div className="layout-breadcrumb">
                <Stepper
                  alternativeLabel
                  activeStep={activeStepStepper}
                  className="layout-breadcrumb-stepper"
                  connector={
                    <StepConnector
                      color={props.general.appState.brand.thirdColor}
                    />
                  }
                >
                  {stepsStepper.map((label) => (
                    <Step
                      key={`layout-breadcrumb-${label}`}
                      className="layout-breadcrumb-stepper-step mx-auto"
                    >
                      <StepLabel
                        classes={{ label: classes.step_label_root }}
                        StepIconComponent={(x) =>
                          StepIconComp(x, brand.thirdColor)
                        }
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </Grid>
          </Grid>
        )}
        <div
          className={`layout-container ${
            customBackground ? 'custom-background' : ''
          }`}
        >
          <main className="layout-middle" aria-live="polite">
            <div
              className={
                ![HOME_PAGE].includes(window.location.pathname)
                  ? 'app_sub_wrapper'
                  : ''
              }
            >
              <div
                className={`step_formule_wrapper ${
                  ![
                    HOME_PAGE.split('/')[1],
                    TEMPORARY_OFFERS_PAGE.split('/')[1],
                    FREE_CAMPAIGN_PAGE.split('/')[1],
                  ].includes(window.location.pathname.split('/')[1])
                    ? 'step_formule_wrapper-width'
                    : ''
                }`}
              >
                {activeStep !== 0 && !isBuyPianoExp && (
                  <Grid container>
                    <Header
                      activeStep={activeStep}
                      steps={steps}
                      brand={brand}
                    />
                  </Grid>
                )}

                {props.children}
              </div>
              {renderCurrentProduct()}
            </div>
          </main>
        </div>

        <div id="footer" className="layout-bottom">
          <Footer brand={brand} location={props.location} />
        </div>
      </div>
    </div>
  );
}

export default Layout;
