import React, { useEffect, useState } from 'react';

import ReactHtmlParser from 'react-html-parser';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getParamInsensitive } from '../../utils/url';
import { GeneralTypes } from '../../actions/types';
import LoadingScreen from '../../components/LoadingScreen';
import Message from '../../components/Message';
import './home.css';
import CustomPaper from '../../components/Paper';
import CheckedBullet from '../../components/CheckedBullet';
import CheckIcon from '../../components/CheckIcon/CheckIcon';
import brands, { IBrand, IBrands } from '../../utils/brands';
import { TEMPORARY_OFFERS_PAGE } from '../../utils/routes';
import TextSlider from '../../components/TextSlider';
import { UserState } from '../../reducers/entities/user';
import { GeneralState } from '../../reducers/entities/general';
import {
  getAdvertisings,
  getAdvertisingsType,
  getChannels,
  GetChannelsType,
  ResetAppStateType,
  SelectOfferType,
} from '../../actions/views/home';
import {
  checkHasGift,
  CheckHasGiftType,
  setHasGift,
  SetHasGiftType,
} from '../../actions/views/gift';
import browserHistory from '../../utils/browser_history';

interface Info {
  content: string;
  checked: boolean;
}

export interface IChannel {
  id: number;
  name: string;
  minPrice: number;
  minPriceDuration: string;
  withCommitment: boolean;
  includePaper: boolean;
  ordering: number;
  highlight: boolean;
  description: string;
  infos: Info[];
  online: boolean;
  campaign: ICampaign;
}

interface ICampaign {
  id: number;
  name: string;
  activeFrom: Date;
  activeTo: Date;
}

interface IUtm {
  utm_campaign: null | string;
  utm_content: null | string;
  utm_source: null | string;
  utm_medium: null | string;
  campaign: null | string;
  promocode: null | string;
}

interface IHomeProps {
  openLogginModal: Function;
  user: UserState;
  general: GeneralState;
  currentPath: any;
  actions: {
    resetAppState: ResetAppStateType;
    selectOffer: SelectOfferType;
    getChannels: GetChannelsType;
    checkHasGift: CheckHasGiftType;
    setHasGift: SetHasGiftType;
    getAdvertisings: getAdvertisingsType;
  };
}

const Home = ({
  general: { appState },
  actions: { selectOffer },
  currentPath,
}: IHomeProps) => {
  const dispatch = useDispatch();
  const { campaign, brand, gift } = appState;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPromoEmpty, setIsPromoEmpty] = useState<boolean>(true);
  const [isCampaignOutdated, setIsCampaignOutdated] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1120);

  const [error, setError] = useState<string>('');
  const [warning, setWarning] = useState<null | string>(null);
  const [channels, setChannels] = useState<IChannel[]>([]);

  const [utm, setUtm] = useState<IUtm | null>(null);

  const resize = () => {
    setIsMobile(window.innerWidth < 1120);
  };

  const selectOfferAndPush = ({ id, name }: IChannel) => {
    selectOffer(id, name);
    browserHistory.push(`/duration${currentPath.search}`);
  };

  const onKeyDown = (
    offer: IChannel,
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      selectOfferAndPush(offer);
    }
  };

  const setUtmData = (): Promise<void> => {
    const utms = {
      utm_campaign: getParamInsensitive('utm_campaign'),
      utm_content: getParamInsensitive('utm_content'),
      utm_source: getParamInsensitive('utm_source'),
      utm_medium: getParamInsensitive('utm_medium'),
      campaign: getParamInsensitive('campaign'),
      promocode: getParamInsensitive('promocode'),
    };
    setUtm(utms);
    dispatch({
      type: GeneralTypes.SET_UTM_DATA,
      data: { ...utms },
    });
    if (campaign != null && utms.campaign == null) {
      dispatch({
        type: GeneralTypes.SET_CAMPAIGN,
        data: {
          campaign: null,
        },
      });
    }
    if (gift !== null && utms.campaign == null) {
      dispatch({
        type: GeneralTypes.REMOVE_GIFT,
      });
    }
    return Promise.resolve();
  };

  const upResizeListener = (): Promise<void> => {
    window.addEventListener('resize', resize);
    resize();
    return Promise.resolve();
  };

  const fetchChannels = async () => {
    await setChannels(
      await dispatch(getChannels(getParamInsensitive('campaign') || '', brand))
    );
    setIsLoading(false);
  };

  const fetchMarketingLinks = async () => {
    const mkt = await dispatch(getAdvertisings(brand));
    setIsPromoEmpty(!(mkt == null || mkt.length <= 0));
  };

  const fetchAndSetGift = async () => {
    const giftCampaign = await dispatch(checkHasGift());
    if (giftCampaign) {
      if (utm?.campaign !== campaign) {
        await dispatch({
          type: GeneralTypes.REMOVE_GIFT,
        });
      }
      await dispatch(setHasGift(giftCampaign));
    } else {
      await dispatch({
        type: GeneralTypes.REMOVE_GIFT,
      });
    }
  };

  // useEffect(() => {
  //   if (channels.length === 0 && utm !== null) fetchChannels();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [utm, brand]);

  useEffect(() => {
    try {
      if (utm !== null && channels.length > 0) {
        if (utm?.campaign !== null) {
          const campaignFail =
            ((channels == null || channels.length <= 0) &&
              typeof utm?.campaign === 'string') ||
            channels[0]?.campaign === undefined;
          if (campaignFail) {
            setIsCampaignOutdated(true);
            setWarning(
              "La promotion que vous tentez d'afficher est incorrecte ou terminée"
            );
          }

          const campaignOutDated =
            channels.length > 0 &&
            typeof utm?.campaign === 'string' &&
            typeof channels[0]?.campaign?.name === 'string' &&
            moment().isAfter(channels[0]?.campaign?.activeTo);
          if (campaignOutDated) {
            setIsCampaignOutdated(true);
            setWarning(
              "La promotion que vous tentez d'afficher est incorrecte ou terminée"
            );
          }

          if (
            typeof utm?.campaign === 'string' &&
            !campaignOutDated &&
            !campaignFail
          ) {
            dispatch({
              type: GeneralTypes.SET_CAMPAIGN,
              data: {
                campaign: utm?.campaign || '',
              },
            });

            (async () => {
              await fetchAndSetGift();
              if (
                channels.length === 1 &&
                typeof utm?.campaign === 'string' &&
                channels[0].campaign !== null &&
                moment().isBefore(channels[0].campaign.activeTo)
              ) {
                selectOfferAndPush(channels[0]);
              }
            })();
          }
        }
      }
    } catch (exception: any) {
      setError(exception.message);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, channels, utm]);

  useEffect(() => {
    dispatch({
      type: GeneralTypes.SET_CLASSIC_FLOW,
    });
    fetchChannels()
      .then(setUtmData)
      .then(fetchMarketingLinks)
      .then(upResizeListener);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  if (error.length > 0) {
    return (
      <Message className="intent_message" intent="DANGER" message={error} />
    );
  }

  if (isLoading) {
    return <LoadingScreen customColor={brand.thirdColor} size={60} />;
  }

  return (
    <Grid className="app_sub_wrapper home" container spacing={0}>
      <div className="flex lg:hidden">
        <TextSlider items={brand.microHeader} />
      </div>
      <ul className="highlight">
        {brand.microHeader.map((head) => (
          <li
            className="highlight-element flex"
            key={`microHeader-${brand.name}-${head}`}
          >
            <CheckIcon className="highlight-icon" />
            &nbsp;{head}
          </li>
        ))}
      </ul>
      <Grid className="title_wrapper" container spacing={0}>
        <h1 className={`title ${brand.rootClassName}`}>{brand.homeTitle}</h1>
      </Grid>

      {warning !== null && (
        <Message
          className="intent_message"
          intent="WARNING"
          message={warning}
        />
      )}
      <main role="list" className="formule_wrapper">
        {(channels || [])
          .filter((offer: IChannel) => offer.online)
          .sort((a: IChannel, b: IChannel) => a.ordering - b.ordering)
          .map((offer: IChannel) => (
            <CustomPaper
              elevation={15}
              role="listitem"
              onClick={() => selectOfferAndPush(offer)}
              onKeyDown={(e: any) => onKeyDown(offer, e)}
              key={`channels-${offer.id}`}
              aria-labelledby={`Choisir offre ${offer.name}`}
              className={`${offer.highlight && 'offer-primary'}`}
              tabIndex={0}
            >
              {offer.highlight && (
                <div className="offer_ribbon">
                  <span className="btn-yellow">Le plus choisi</span>
                </div>
              )}
              <div className="offer_content">
                <h2 className="offer_title">{offer.name}</h2>
                <div className="offer_desc">
                  <ul>
                    {offer.infos.map((d: any) => (
                      <CheckedBullet
                        insidePrimary={offer.highlight}
                        checked={d.checked}
                        key={`${offer.name}-option-${d.content}`}
                      >
                        {ReactHtmlParser(d.content)}
                      </CheckedBullet>
                    ))}
                  </ul>
                </div>
                <div className="offer_price">
                  {utm?.campaign === null || isCampaignOutdated ? (
                    <p className="parent_pricing">
                      dès{' '}
                      <span className="pricing">
                        {(Math.round(offer.minPrice * 100) / 100)
                          .toFixed(2)
                          .toString()
                          .replace('.', ',')}
                        €
                      </span>{' '}
                      /{offer.minPriceDuration}
                    </p>
                  ) : null}
                  <button type="button" className="offer-button">
                    Je choisis
                  </button>
                  {offer.highlight && brand.hasSWG && (
                    <div className="gpay-button" />
                  )}
                  {offer.withCommitment && (
                    <p className="parent_underpricing">
                      <span className="underpricing">sans engagement</span>
                    </p>
                  )}
                </div>
              </div>
            </CustomPaper>
          ))}
      </main>
      {(brand?.specialLink || '').length &&
      (brand?.specialText || '').length &&
      !isMobile &&
      utm != null &&
      utm?.campaign === null ? (
        <div className="digital_plus_link_bottom">
          <a href={brand.specialLink} target="_blank" rel="noopener noreferrer">
            {ReactHtmlParser(brand.specialText)}
          </a>
        </div>
      ) : null}

      <section className="discover">
        {isPromoEmpty && (
          <div className="discover-item">
            <section className="discover-item--body bg-ipm-yellow-l">
              <h3 className="discover-item--title">
                Découvrez nos offres temporaires
              </h3>
              <Link to={TEMPORARY_OFFERS_PAGE} className="discover-item--link">
                <span>Je découvre</span>
                <ArrowForwardIcon />
              </Link>
            </section>
          </div>
        )}
        {brand.ratioLoyalty > 0 && (
          <div className="discover-item">
            <section className="discover-item--body bg-gray-200">
              <h3 className="discover-item--title">
                Découvrez notre programme de fidélité
              </h3>
              <a
                href={`https://${
                  (brands[brand.name as keyof IBrands] as IBrand).tld
                }/profil/points-de-fidelite/`}
                className="discover-item--link"
                target="_blank"
                rel="noreferrer"
              >
                <span>Je découvre</span>
                <ArrowForwardIcon />
              </a>
            </section>
          </div>
        )}
      </section>
    </Grid>
  );
};

export default Home;
