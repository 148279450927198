/* eslint-disable no-console */
/* eslint-disable no-bitwise */

import fetch from 'isomorphic-fetch';
import { useState, useEffect } from 'react';
import { Path } from 'history';
import browserHistory from './browser_history';

export interface EdaEventType {
  accountId: string;
  visitorId: string;
  action: string;
  value: string;
  label: string;
  utm_campaign: string;
  utm_content: string;
  utm_source: string;
  utm_medium: string;
  pool_token: string;
  promocode: string;
}

// Send analytics event to eda (Send a post request and nothing more)
export const sendEdaEvent = async (event: EdaEventType) => {
  try {
    const resp = await fetch('https://data-api.lavenir.net/aboshop/event', {
      method: 'POST',
      body: JSON.stringify(event),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
    if (!resp.ok) throw new Error(resp.statusText);
  } catch (error) {
    // TODO: log error
    console.log(error);
  }
};

export const goToPage = (location: Path, replace = false): void => {
  if (replace) {
    browserHistory.replace(location);
  } else {
    browserHistory.push(location);
  }
};

export const getCookie = (name: string): string | null => {
  const dc = document.cookie;
  const prefix = `${name}=`;
  let begin;
  let end;

  begin = dc.indexOf(`; ${prefix}`);
  end = dc.length; // default to end of the string

  // found, and not in first position
  if (begin !== -1) {
    // exclude the "; "
    begin += 2;
  } else {
    // see if cookie is in first position
    begin = dc.indexOf(prefix);
    // not found at all or found as a portion of another cookie name
    if (begin === -1 || begin !== 0) return null;
  }

  // if we find a ";" somewhere after the prefix position then "end" is that position,
  // otherwise it defaults to the end of the string
  if (dc.indexOf(';', begin) !== -1) {
    end = dc.indexOf(';', begin);
  }
  // eslint-disable-next-line
  return decodeURI(dc.substring(begin + prefix.length, end)).replace(/\"/g, '');
};

// Create or return current visitorID
export const createVisitorID = (): string => {
  const cookieVal = getCookie('aveVisitorID');
  if (cookieVal === null) {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 10 * 24 * 60 * 60 * 1000);
    const expires = `; expires=${date.toUTCString()}`;

    const visitorID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    const aveCookie = `aveVisitorID=${visitorID}; expires=${expires}; domain=.lavenir.net; path=/`;
    document.cookie = aveCookie;
    return visitorID;
  }
  return cookieVal;
};

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const makeTextEllipsis = (value: string, length: number): string =>
  value.length > length ? `${value.substring(0, length)}...` : value;
