export const inSevenDays = (): Date => {
  const date = new Date();
  date.setDate(date.getDate() + 7);
  return date;
};

export const inThreeMonths = (): Date => {
  const date = new Date();
  date.setMonth(date.getMonth() + 3);
  return date;
};

const dateUtils = {
  inSevenDays,
  inThreeMonths,
};

export default dateUtils;
