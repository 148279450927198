import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { fetchWithCredentials, getAPIUrl } from '../generalActions';
import { GeneralTypes } from '../types';
import { RenewalOrderType } from './pay';
import { RootState } from '../../reducers';

// export const createOrder = async (dispatch: any, order: any) => {
//   try {
//     const res = await dispatch(
//       fetchWithCredentials(`${getAPIUrl()}/Renewal/CreateOrder`, {
//         method: 'POST',
//         body: JSON.stringify(order),
//         headers: {
//           'Content-type': 'application/json; charset=UTF-8',
//         },
//       })
//     );
//     const data = await res.json();
//     if (!res.ok) throw new Error(data.message);
//     return data;
//   } catch (error: any) {
//     throw new Error(error.message);
//   }
// };

export type CreateOrderType = (order: RenewalOrderType) => any;
export const createOrder: CreateOrderType =
  (order) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const res = await dispatch(
      fetchWithCredentials(`${getAPIUrl()}/Renewal/CreateOrder`, {
        method: 'POST',
        body: JSON.stringify(order),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
    );

    if (!res?.ok) {
      throw new Error('Une erreur est survenue, veuillez recharger la page');
    }

    const data = await res.json();

    dispatch({
      type: GeneralTypes.CREATE_ORDER_SUCCESS,
      data: data.id,
    });
    // dispatch({
    //   type: GeneralTypes.GET_CLIENT_SECRET,
    //   data: data.clientSecret
    // });
    return data;
  };

export type UpdateOrderType = (order: RenewalOrderType) => void;
export const updateOrder: UpdateOrderType =
  (order) =>
  async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => RootState
  ) => {
    try {
      const intentId = getState().entities.general.appState.intent;
      if (intentId === null) {
        throw new Error('Missing intent');
      }
      const resp = await dispatch(
        fetchWithCredentials(`${getAPIUrl()}/Renewal/UpdateOrder`, {
          method: 'POST',
          body: JSON.stringify({ ...order }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        })
      );
      const data = await resp.json();
      if (!resp.ok) throw new Error(data.message);
      dispatch({
        type: GeneralTypes.UPDATE_INTENT_SUCCESS,
        data: data.id,
      });
      // dispatch({
      //   type: GeneralTypes.GET_CLIENT_SECRET,
      //   data: data.client_secret
      // });
      return data;
    } catch (error) {
      throw error;
    }
  };

export const createTransaction = async (dispatch: any, transaction: any) => {
  try {
    const res = await dispatch(
      fetchWithCredentials(
        `${getAPIUrl()}/Renewal/${transaction.orderId}/CreateTransaction`,
        {
          method: 'POST',
          body: JSON.stringify(transaction),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      )
    );
    const data = await res.json();
    if (!res.ok) throw new Error(data.message);
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createMollie = async (dispatch: any, transaction: any) => {
  try {
    const res = await dispatch(
      fetchWithCredentials(`${getAPIUrl()}/Pay/CreateMollie`, {
        method: 'POST',
        body: JSON.stringify({ id: transaction.id }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
    );
    const data = await res.json();
    if (!res.ok) throw new Error(data.message);
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const findPaymentMethods = async (dispatch: any, brandId: number) => {
  try {
    const res = await dispatch(
      fetchWithCredentials(
        `${getAPIUrl()}/product/PaymentMethods?brandId=${brandId}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      )
    );
    const data = await res.json();
    if (!res.ok) throw new Error(data.message);
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const checkTransaction = async (
  dispatch: any,
  transactionId: string
) => {
  try {
    const res = await dispatch(
      fetchWithCredentials(
        `${getAPIUrl()}/Renewal/CheckTransaction?transactionId=${transactionId}`,
        {
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      )
    );
    const data = await res.json();
    if (!res.ok) throw new Error(data.message);
    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
