import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useDispatch } from 'react-redux';
import usePiano from '../../AuthPiano/PianoID';
import { logout } from '../../../actions/userActions';

interface ILogoutProps {
  onClick: () => void;
}
const Logout = ({ onClick }: ILogoutProps) => {
  const dispatch = useDispatch();
  const { handleLogout } = usePiano({ action: 'logout' }) || {};

  return (
    <MenuItem
      className="MI-Disconnect"
      onClick={async () => {
        await handleLogout();
        await dispatch(logout());
        onClick();
      }}
    >
      Déconnexion
    </MenuItem>
  );
};

export default Logout;
